require("core-js");

const $ = require("jquery");
window.$ = window.jQuery = $;
require("jquery-ui/ui/widgets/datepicker");
require("./polyfills/is-integer");

const bowser = require("bowser");
const http = require("axios/dist/browser/axios.cjs");
const io = require("socket.io/client-dist/socket.io.min.js");
const moment = require("moment");
window.moment = moment; // Thanks, Tabulator.
const Tabulator = require("tabulator-tables");

const CONSTANTS = require("../../lib/index");
const dialog = require("./dialog");

const utils = require("./utils");
const initGoogleAnalytics = require("./GoogleAnalytics.js");

const AccountClient = require("./clients/account-client.js");
const BatchClient = require("./clients/batch-client.js");
const ConfigurationSettingsClient = require("./clients/configuration-settings-client.js");
const GeocodeClient = require("./clients/geocode-client.js");
const ReportsClient = require("./clients/reports-client.js");
const TransactionClient = require("./clients/transaction-client.js");

const MapriskController = require("./controllers/maprisk-controller.js");
const ResultMapController = require("./controllers/result-map-controller.js");
const ModelInputFactory = require("@MapRisk/ModelInput");

const ReplacementCost = require("./components/reports/replacement-cost");
const HeritageCLUnderwritingGuidelines = require("./components/reports/heritage-cl-underwriting-guidelines");

const APIKeyManager = require("./components/account-tools/api-key-manager");
const BatchHistoryModel = require("./components/batch-history/batch-history");
const DataView = require("./components/data-view");
const EventBus = utils.EventBus;
const eventTracker = utils.eventTracker;
const LayerViewModelFactory = require("./components/map/layer/view-model");
const LoadingOverlay = require("./components/loading-overlay");
const MapClickDetection = require("./utils/map-click-detection");
const Multiselect = require("./components/multiselect.js");
const Tooltips = require("./components/tooltips");
const WFSClient = require("./components/wfs");
const OverlayFactory = require("./components/overlays");
const UserManager = require("./components/user-manager");

// CONTROLLERS
window.mapRiskController;
window.resultMapController;

// CALLBACKS
window.dialogCloseCallback;

// LISTS
window.reportRequestList = {};

// MODELS
window.geocodeModel = {};
window.transactionModel = {};
window.reportsModel = {};

window.accountConfigurationModel = null;
window.reportConfigurationModel = {};

// VIEWMODELS
window.addressSearch = {};
window.reportsMenu;
window.resultsMenu;

// FLAGS
window.accountHasCredits;
window.displayUrlLink = false;

// PROPERTIES
window.currentPoiInfo;
window.transactionId = null;
window.policyInfo;

// global definitions required by inline dialog eventss
window.dialog = dialog;
window.CONSTANTS = CONSTANTS;

window.mrUserNotifications = {
	accountUpdateError:
		'<p>Account contact information update failed.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for support.</p>',
	accountUpdateSuccess:
		"<p>Account contact information updated successfully.</p>",
	creditsEmpty:
		"<p onclick=\"dialog.openDialog(window.CONSTANTS.DIALOG.ACCOUNT_SETTINGS);\">Oops, you've run out of credits!<br>Click here if you'd like to buy more.</p>",
	creditsLow:
		'<p onclick="dialog.openDialog(window.CONSTANTS.DIALOG.ACCOUNT_SETTINGS);">We noticed that your credits are getting low!<br>Click here if you\'d like to buy more.</p>',
	glossaryNotFound:
		"<p>Sorry, a glossary is not available for this report.</p>",
	highesolutionImageryAvailable:
		'<p>High resolution Imagery is available for this address.</p><p>Please contact <a href="mailto:sales@maprisk.com">sales@maprisk.com</a> to enable high-res imagery on your account.</p>',
	highResolutionImageryUnavailable:
		"<p>High resolution Imagery is not available for this address.</p>",
	legendNotFound:
		"<p>Sorry, a legend is not available for this visual layer.</p>",
	noReportsSelected: "<p>Please select a report to run first.</p>",
	urlLinkCopiedToClipboard: "<p>URL Link Copied to Clipboard.</p>"
};

window.mrState = {
	batchEnabled: false,
	browser: bowser.getParser(window.navigator.userAgent).getBrowser(),
	dataLayers: {},
	drawingTools: {
		isDrawingToolReportFilteringEnabled: false
	},
	imagery: {
		zoomLevels: [],
		coverage: null,
		overlays: {
			image: [],
			tile: []
		}
	},
	lastPoi: {},
	layerMultiselects: {},
	layers: {},
	layerSelects: {},
	loadingOverlays: {},
	mrClickableOverrides: {},
	mrFeatureClick: {
		reports: {}
	},
	portfolioLink: {
		viewOnly: {
			enabled: false,
			modifyAddressBar: true,
			reports: {
				add: true,
				remove: true,
				enableReRunButton: true
			}
		}
	},
	reports: {},
	reportResults: {},
	tooltips: {
		currentTooltips: {}
	},
	mrMultiselect: {},
	mrUserNotificationCenter: {
		history: [],
		currentMessages: []
	},
	replacementCost: {
		otherAreas: {},
		otherAreasMMH: {},
		additionalStructures: {}
	},
	transactionId: null,
	viewOnly: {
		enabled: false,
		modifyAddressBar: true,
		reports: {
			add: true,
			remove: true,
			enableReRunButton: true
		}
	},
	wfs: {}
};

window.dialogInfo = null;
window.eventBus = new EventBus();

function onCreditsUpdated(creditsRemaining) {
	var hasCreditPool = false;

	if (
		typeof window.accountConfigurationModel.creditPool !== "undefined" &&
		window.accountConfigurationModel.creditPool !== null &&
		creditsRemaining !== null
	) {
		creditsRemaining = Number(creditsRemaining);
		hasCreditPool = true;
	}

	if (hasCreditPool === false || creditsRemaining > 0) {
		window.accountHasCredits = true;

		if (!window.mapRiskController.getReportFilterOverlay()) {
			google.maps.event.addListenerOnce(
				window.mapRiskController.map,
				"tilesloaded",
				function () {
					window.mapRiskController.mapDrawingToolsControl.enable();
				}
			);
		}

		window.addressSearch.enableSearchButton();
	}
}

function initOpenDialog() {
	// Check to see if the dialog should be opened
	if (window.dialogInfo.isOpen) {
		dialog.openDialog(window.dialogInfo.type);
	}
}

function initFromGoogleMapsCallback() {
	//Check to see if the dialog should be opened
	if (window.dialogInfo?.isOpen) {
		dialog.openDialog(window.dialogInfo.type);
	}
	// else we will continue fetching report and account configurations
	else {
		var configClient = new ConfigurationSettingsClient();

		$(".tblSelectReportGroups .chkExecuteReport").prop("checked", false);

		const fetchReportConfigs = function fetchReportConfigs() {
			configClient.getReportConfigurationSettings(handleReportConfigs);
		};

		const handleReportConfigs = function handleReportConfigs(
			err,
			reportConfigs
		) {
			window.reportConfigurationModel = reportConfigs;
			$(document).trigger(
				"customEvent.replacementCost.configureReportDataInputs",
				reportConfigs
			);
			fetchAccountConfigs();
		};

		const fetchAccountConfigs = function fetchAccountConfigs() {
			configClient.getAccountConfigurationSettings(handleAccountConfigs);
		};

		const handleAccountConfigs = function handleAccountConfigs(
			err,
			accountConfigs
		) {
			window.accountConfigurationModel = accountConfigs;
			init();
		};

		fetchReportConfigs();
	}
}

function setViewOnly() {
	let viewOnly;

	if (isPortfolioLink()) {
		if (
			window.accountConfigurationModel?.runtimeConfiguration?.portfolioLink
				?.viewOnly
		) {
			viewOnly =
				window.accountConfigurationModel.runtimeConfiguration.portfolioLink
					.viewOnly;
		} else {
			viewOnly = window.mrState.portfolioLink.viewOnly;
		}
	} else {
		if (
			window.accountConfigurationModel?.runtimeConfiguration?.urlLink?.viewOnly
		) {
			viewOnly =
				window.accountConfigurationModel.runtimeConfiguration.urlLink.viewOnly;
		} else {
			viewOnly = window.mrState.viewOnly;
		}
	}

	return viewOnly;
}

function setDisplayUrlLink(accountConfigurationModel) {
	var displayUrlLinkSetting = false;

	if (
		accountConfigurationModel &&
		accountConfigurationModel.runtimeConfiguration &&
		accountConfigurationModel.runtimeConfiguration.website
	) {
		var displayUrlLinkSetting =
			accountConfigurationModel.runtimeConfiguration.website.displayUrlLink;

		displayUrlLinkSetting = displayUrlLinkSetting === true ? true : false;
	}

	return displayUrlLinkSetting;
}
function hideModelInputContainer() {
	var reports = window.reportConfigurationModel;

	for (var report in window.reportConfigurationModel) {
		var reportId = reports[report].reportId;
		if (reports[report].display === true) {
			var count = $(`#div${reportId}_index > .additional-info-form`).children(
				"div"
			).length;
			if (count === 0) {
				$(`#div${reportId}_index`).closest(".divAdditionalInfo").hide();
			}
		}
	}
}

function isPortfolioLink() {
	return window.batchId && window.portfolioName && window.portfolioReport;
}

function init() {
	if (
		window.mrState?.browser?.name === "Internet Explorer" &&
		window.mrState?.browser?.version === "11.0" &&
		!window.localStorage.getItem("ieDeprecationMessageSeen")
	) {
		dialog.openDialog(CONSTANTS.DIALOG.IE_GOOGLE_MAPS_DEPRECATION);
		window.localStorage.setItem("ieDeprecationMessageSeen", true);
	}

	window.mrState.imagery.zoomLevels = window.imageryZoomLevels;

	var mainMapLoaded = false;
	hideModelInputContainer();

	// set viewOnly mode if urlLink
	if (window.transactionId || isPortfolioLink()) {
		window.mrState.viewOnly = setViewOnly(window.accountConfigurationModel);
	}

	window.displayUrlLink = setDisplayUrlLink(window.accountConfigurationModel);

	if (
		!window.accountConfigurationModel.runtimeConfiguration.website
			.showCreditInfo &&
		typeof window.accountConfigurationModel.runtimeConfiguration.website
			.showCreditInfo !== "undefined"
	) {
		$("#accountInfo").hide();
		$("#purchase-credits-form").hide();
	}

	var options = {
		mapping: {
			mapObject: $("#GoogleMapDiv"),
			centerPoi: { lat: 37.967294, lng: -96.152343 },
			initalZoom: 4,
			streetViewControl: false,
			gestureHandling: "greedy",
			styles:
				window?.accountConfigurationModel?.runtimeConfiguration?.website?.map
					?.styles ?? {}
		},
		enableDrawing: true,
		accountConfigurationModel: window.accountConfigurationModel
	};

	window.mapRiskController = new MapriskController(options);
	window.mapRiskController.initMap(window.reportConfigurationModel);
	window.mainDataView = new DataView(window.mapRiskController);
	window.wfsClient = new WFSClient(http).getWFSClient();
	window.overlayFactory = new OverlayFactory(
		window.mapRiskController,
		window.wfsClient,
		window.mainDataView
	);
	initializeResultMap();

	$("body").on("click", "i.icon-lock", function () {
		dialog.openDialog(CONSTANTS.DIALOG.LOCKED_UNTIL_PURCHASE);
	});

	var getNumCreditsCallback = function (err, response) {
		if (err) {
			if (err.status === 401) {
				$("#errors-login-form").html("Invalid Session");

				return;
			} else {
				$("#errors-login-form").html(err.error);
			}
		}

		if (
			!response ||
			response.result === null ||
			typeof response.result === "undefined"
		) {
			return onCreditsUpdated(null);
		}

		if (typeof response.result === "number") {
			return onCreditsUpdated(response.result);
		} else {
			return null;
		}
	};

	var accountClient = new AccountClient();

	accountClient.getNumCredits(getNumCreditsCallback);

	window.mrState.batchEnabled = Boolean($("#batch-button").prop("disabled"))
		? false
		: window.accountConfigurationModel.privileges.hasBatchAccess;

	if (window.mrState.batchEnabled) {
		$(document).trigger("customEvent.batch.getBatches");
	}

	if (window?.reportConfigurationModel?.replacementcost) {
		remapReplacementCostKeys(
			window.reportConfigurationModel,
			"ReplacementCost",
			"keyDisplayMapWebsite"
		);

		window.mrState.replacementCost.otherAreas[
			"replacementCost"
		] = new ReplacementCost.OtherAreas("replacementCost");

		window.mrState.replacementCost.otherAreas["replacementCost"].init();

		let residentialParam = utils.getReportParameterByName(
			window.reportConfigurationModel,
			"replacementCost",
			"Residential"
		);

		let residentialIsProWithAdjustment =
			residentialParam?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
				"residentialproadjustment" || false;

		if (!residentialIsProWithAdjustment) {
			window?.mrState?.replacementCost?.otherAreas?.[
				"replacementCost"
			]?.removeAll();
			window?.mrState?.replacementCost?.otherAreas?.[
				"replacementCost"
			]?.hideAddTrigger();
		}
	}

	if (window?.reportConfigurationModel?.replacementcostadditionalstructures) {
		window.mrState.replacementCost.additionalStructures[
			"replacementCostAdditionalStructures"
		] = new ReplacementCost.AdditionalStructures(
			"replacementCostAdditionalStructures"
		);

		window.mrState.replacementCost.additionalStructures[
			"replacementCostAdditionalStructures"
		].init();
	}

	if (
		window?.reportConfigurationModel?.replacementcostmobilemanufacturedhomes
	) {
		window.mrState.replacementCost.otherAreasMMH[
			"replacementCostMobileManufacturedHomes"
		] = new ReplacementCost.OtherAreasMMH(
			"replacementCostMobileManufacturedHomes"
		);

		window.mrState.replacementCost.otherAreasMMH[
			"replacementCostMobileManufacturedHomes"
		].init();
	}

	if (
		window?.reportConfigurationModel
			?.heritagecommerciallinesunderwritingguidelines
	) {
		window.mrState.reports.heritageCommercialLinesUnderwritingGuidelines = new HeritageCLUnderwritingGuidelines(
			"heritageCommercialLinesUnderwritingGuidelines"
		);
	}

	collapseReportGroups();

	$(".divAdditionalInfo").each(function () {
		var reportId = $(this).attr("data-report-id");
		var view = $(this).attr("data-view");
		var report = reportId + "_" + view;

		if ($(this).find(".mr-multi-select").length > 0) {
			var defaults = utils.getReportParameterByName(
				window.reportConfigurationModel,
				reportId,
				"filterDefaults"
			);
			var multiSelectParentContainer = "#div" + report;

			if (!window?.mrState?.mrMultiselect?.[report]) {
				window.mrState.mrMultiselect[report] = new Multiselect(
					reportId,
					view,
					multiSelectParentContainer
				);
			}

			if (defaults) {
				for (var column in defaults) {
					defaults[column].forEach(function (val) {
						window.mrState.mrMultiselect[report].triggerLabelClick(column, val);
					});
				}
			}
		}

		if ($(this).find(".additional-info-form").length > 0) {
			var displayPrefillButton = utils.getReportParameterByName(
				window.reportConfigurationModel,
				reportId,
				"displayPrefillButton"
			);
			displayPrefillButton =
				displayPrefillButton == null ? true : displayPrefillButton;

			$(this)
				.find(".additional-info-form")
				.each(function () {
					let $reRunButton = $("<button></button>");
					$reRunButton.attr("type", "button");
					$reRunButton.addClass("button-rerun button button-notice");
					$reRunButton.attr("id", "button-rerun-" + reportId + "_index");
					$reRunButton.html("Rerun Report");
					$reRunButton.on("click", function (e) {
						e.preventDefault();

						if (window?.currentPoiInfo?.geoResult) {
							if (window?.mainDataView?.state) {
								window.mainDataView.state.firstRender = true;

								window.mainDataView.setFilterParams(
									reportId,
									window.getReportFilterByFields(reportId, "index"),
									false
								);
							}

							getReportById(
								reportId,
								window.reportRequestList,
								window.currentPoiInfo
							);
						} else {
							$(
								"#divAdditionalReportInformation_" +
									reportId +
									"_reportTableRow .get-report-btn"
							).trigger("click");
						}
					});

					if (disableReportReRunIfViewOnly() === true) {
						$reRunButton.prop("disabled", true);
						$reRunButton.addClass("disabled");
					}

					if (
						reportId.toLowerCase() === "replacementcost" &&
						displayPrefillButton
					) {
						let $preFillButton = $("<button></button>");
						$preFillButton.attr("type", "button");
						$preFillButton.attr("data-viewid", "index");
						$preFillButton.attr("id", "button-prefill-replacementCost_index");
						$preFillButton.addClass("button-prefill button button-cta");
						$preFillButton.html("Pre-Fill");
						$preFillButton.val("PreFill");
						$(this).append($preFillButton);
					}

					if (
						reportId.toLowerCase() ===
							"replacementcostmobilemanufacturedhomes" &&
						displayPrefillButton
					) {
						let $preFillButton = $("<button></button>");
						$preFillButton.attr("type", "button");
						$preFillButton.attr("data-viewid", "index");
						$preFillButton.attr(
							"id",
							"button-prefill-replacementCostMobileManufacturedHomes_index"
						);
						$preFillButton.addClass("button-prefill button button-cta");
						$preFillButton.html("Pre-Fill");
						$preFillButton.val("PreFill");
						$(this).append($preFillButton);
					}

					$(this).append($reRunButton);
				});
		}
	});

	google.maps.event.addListenerOnce(
		window.mapRiskController.map,
		"tilesloaded",
		function () {
			var mainMapLoaded;

			if (typeof Event === "function") {
				mainMapLoaded = new Event("main_map_loaded");
			} else {
				mainMapLoaded = document.createEvent("Event");
				mainMapLoaded.initEvent("main_map_loaded", true, true);
			}

			document.dispatchEvent(mainMapLoaded);

			mainMapLoaded = true;
			$(".app-overlay").removeClass("active");
			window.mapRiskController.mapDrawingToolsControl.activateDrawingToolByType(
				"pan"
			);

			window.addressSearch.enableSearchButton();

			$("#txtInputReportAddress").focus();

			if (window.transactionId) {
				handleTransactionRequestIfNeeded();
			}

			if (window.batchId && window.portfolioName && window.portfolioReport) {
				handlePortfolioRequestIfNeeded(
					window.portfolioName,
					window.portfolioReport
				);
				console.log("Portfolio Link loaded successfully");
			}

			window.mapRiskController.zoomChanged();
		}
	);

	document.addEventListener("transaction_request_completed", function () {
		if (window.subUrlLink === true) {
			handleSubUrlLinkIfNeeded();
		}
	});
}

function handleSubUrlLinkIfNeeded() {
	for (let report in window?.reportConfigurationModel) {
		let reportId = window.reportConfigurationModel[report].reportId;
		let reRunReport = utils?.getReportParameterByName(
			window?.reportConfigurationModel,
			reportId,
			"reRunOnSubUrlLinkIfNoData"
		);

		if (
			reRunReport === true &&
			window?.currentPoiInfo &&
			!window?.transactionModel?.response?.reportResults?.[reportId]
		) {
			window.reportRequestList[reportId] = buildReportRequestObject(reportId);
			getReportById(reportId);
		}
	}
}

function buildReportRequestObject(reportId) {
	let additionInfoRowId = "divAdditionalReportInformation_" + reportId;
	let newRow = $("<tr class='reportTableRow'>");
	let td = $("<td>");

	newRow.append(td);
	newRow.on("click", function (e) {
		e.stopPropagation();
	});

	if ($(":checkbox[value=" + reportId + "]:checked").length === 0) {
		$(":checkbox[value=" + reportId + "]").trigger("click");
	}

	return {
		reportGroupId: reportId,
		resultRow: newRow,
		additionalInfoRowId: additionInfoRowId,
		layers: []
	};
}

function handlePortfolioRequestIfNeeded(portfolioName, portfolioReport) {
	$("#batch-button").hide();
	let $reportCheckbox = $(
		`#chkExecuteReport_${portfolioReport.reportId}_index`
	);
	let $reportWrapper = $reportCheckbox.parents("tr");
	let $reportSibling = $reportWrapper.next("tr");

	$reportWrapper.hide();
	$reportSibling.hide();

	if (!$reportCheckbox.checked) {
		$reportCheckbox.trigger("click");
	}

	disableBatchIfViewOnly();
	disableSearchIfViewOnly();
	disableReportsIfViewOnly(
		Object.keys(window.reportConfigurationModel).map(function (el) {
			if (window.reportConfigurationModel[el].display === true) {
				return window.reportConfigurationModel[el].reportId;
			}
		}),
		[window.portfolioReport.reportId]
	);
}

function handleTransactionRequestIfNeeded() {
	var getTransactionCallback = function (err, getTransactionResponse) {
		if (err) {
			console.log(err);
		}

		window.transactionModel = getTransactionResponse;

		var layerObject;

		var geoResult;

		geoResult = buildGeoResultFromRequest(window.transactionModel);

		var poi = JSON.parse(JSON.stringify(geoResult));
		var inputAddress;

		poi.lat = poi.latitude;
		poi.lng = poi.longitude;

		window.currentPoiInfo = {
			geoResult: geoResult,
			poi: poi,
			layerObj: layerObject,
			request: window.transactionModel.request
		};

		if (!inputAddress) {
			inputAddress = "";

			if (geoResult.street) {
				inputAddress += geoResult.street;
			}
			if (geoResult.unit) {
				inputAddress += " " + geoResult.unit;
			}
			if (geoResult.city) {
				inputAddress += ", " + geoResult.city;
			}
			if (geoResult.state) {
				inputAddress += ", " + geoResult.state;
			}
			if (geoResult.zip) {
				inputAddress += " " + geoResult.zip;
			}

			if (
				!geoResult.street &&
				!geoResult.city &&
				geoResult.latitude &&
				geoResult.longitude
			) {
				inputAddress = geoResult.latitude + ", " + geoResult.longitude;
			}

			if (
				!geoResult.street &&
				!geoResult.city &&
				!geoResult.state &&
				window.accountConfigurationModel.runtimeConfiguration.website &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun.preserveOriginalAddress !== true
			) {
				inputAddress = geoResult.latitude + ", " + geoResult.longitude;
			}

			window.addressSearch.setSearchText(inputAddress, true);
		}

		window.resultsMenu.open(true, true);

		addAddressMarker(window.currentPoiInfo.poi);
		onCreditsUpdated(null);

		if (window.transactionModel.response.date) {
			var date = moment(window.transactionModel.response.date).format(
				"YYYY-MM-DD"
			);
			var $transactionDateWrapper = $(
				'<span class="transactionDateWrapper"></span>'
			);

			$transactionDateWrapper.append(
				'<p class="transactionDateCaption">Report Date</p><p class="transactionDate">' +
					date +
					"</p>"
			);
			$transactionDateWrapper.appendTo($("#reportResults > caption"));
		}

		for (var r in window.reportRequestList) {
			for (var index in window.reportRequestList[r].layers) {
				if (!window.reportRequestList[r].layers[index]) {
					continue;
				}

				window.mapRiskController.removeOverlay(
					window.reportRequestList[r].layers[index]
				);
			}
		}

		for (
			var i = 0;
			i < window.transactionModel.request.reportList.length;
			i++
		) {
			var report = window.transactionModel.request.reportList[i];
			var reportConfigData = { report: report.toLowerCase() };

			window.reportRequestList[report] = buildReportRequestObject(report);

			var reportData = $.extend(true, {}, window.transactionModel);

			for (var reportCandidate in reportData.response.reportResults) {
				if (!window.reportsModel[reportCandidate]) {
					if (reportCandidate.toLowerCase() === "geocode") {
						window.geocodeModel.success = true;
						window.geocodeModel.error = window.transactionModel.error;
						window.geocodeModel.request = {
							poi: window.transactionModel.request.poi,
							policy: window.transactionModel.request.policy,
							reportId: window.transactionModel.request.reportList.find(
								function (obj) {
									return obj.toLowerCase() === "geocode";
								}
							),
							transactionId: window.transactionModel.request.transactionId
						};

						window.geocodeModel.response = {};
						window.geocodeModel.response.reportResults = {};
						window.geocodeModel.response.reportResults[reportCandidate] =
							window.transactionModel.response.reportResults[
								reportCandidate
							][0];

						if (reportData.response.urlLink) {
							window.geocodeModel.response.urlLink =
								reportData.response.urlLink;
						}

						window.reportsModel[reportCandidate] = {
							report:
								window.geocodeModel.response.reportResults[reportCandidate],
							transactionId: window.geocodeModel.request.transactionId
						};

						appendReportResult(
							window.currentPoiInfo.geoResult,
							window.geocodeModel,
							report,
							window.reportRequestList[report],
							window.currentPoiInfo.poi,
							layerObject
						);

						$('.map-control[data-type="street-view"]').removeClass("disabled");

						continue;
					} else if (
						reportCandidate.toLowerCase().indexOf("aggregateexposure") !== -1
					) {
						reportData.filterByFields = utils.extend.mergeObject(
							window.mainDataView.getFilterParams(reportCandidate),
							window.getReportFilterByFields(reportCandidate, "index")
						);

						for (var filter in reportData.filterByFields) {
							if (!reportData.filterByFields[filter]) {
								delete reportData.filterByFields[filter];
							}
						}

						window.reportsModel[reportCandidate] = {
							report: reportData.response.reportResults[reportCandidate],
							transactionId: reportData.request.transactionId
						};
					} else {
						window.reportsModel[reportCandidate] = {
							report: reportData.response.reportResults[reportCandidate],
							transactionId: reportData.request.transactionId
						};
					}
				}

				if (reportCandidate !== report) {
					delete reportData.response.reportResults[reportCandidate];
				}
			}

			if (report.toLowerCase() === "geocode") {
				$('.map-control[data-type="street-view"]').removeClass("disabled");
				continue;
			}

			appendReportResult(
				window.currentPoiInfo.geoResult,
				reportData,
				report,
				window.reportRequestList[report],
				window.currentPoiInfo.poi,
				layerObject
			);

			var reportParameters =
				reportData?.request?.params?.[report]?.modelInput ?? null;

			var reportResults = reportData?.response?.reportResults?.[report];

			var reportConfig =
				window?.reportConfigurationModel?.[report.toLowerCase()] ?? null;

			if (reportParameters) {
				checkInputFormFieldsForReport(
					reportConfig,
					reportParameters,
					reportResults
				);
			}

			$('.map-control[data-type="street-view"]').removeClass("disabled");

			if (
				report.toLowerCase() === "hailcanopystormfootprints" ||
				report.toLowerCase() === "hailcanopyverification" ||
				report.toLowerCase() === "hailstormfootprints" ||
				report.toLowerCase() === "hailverificationc"
			) {
				window.generateCanopyLayersFromModelInput(report);
			}

			if (report.toLowerCase() === "replacementcostadditionalstructures") {
				if (
					window?.transactionModel?.request?.params?.[report]?.modelInput
						?.additionalStructures &&
					window?.mrState?.replacementCost?.additionalStructures?.[report]
				) {
					if (
						window?.mrState?.replacementCost?.additionalStructures?.[report]
					) {
						window.mrState.replacementCost.additionalStructures[
							report
						].removeAll();
					}

					let additionalStructures =
						window?.transactionModel?.request?.params?.[report]?.modelInput
							?.additionalStructures;

					additionalStructures.forEach((structure) => {
						let $el = window.mrState.replacementCost.additionalStructures[
							report
						].add();

						window.mrState.replacementCost.additionalStructures[
							report
						].populateValues($el, {
							structureType: structure.structureType,
							squareFootage: structure.squareFootage,
							yearBuilt: structure.yearBuilt,
							constructionType: structure.constructionType,
							primaryRoofCovering: structure.primaryRoofCovering,
							primaryExterior: structure.primaryExterior,
							constructionQuality: structure.constructionQuality,
							structureCondition: structure.structureCondition,
							replacementCostType: structure.replacementCostType,
							returnACV: structure.returnACV,
							returnACVGeneralCondition: structure.returnACVGeneralCondition,
							returnACVRoofCondition: structure.returnACVRoofCondition,
							returnACVWallCondition: structure.returnACVWallCondition,
							returnACVFoundationCondition:
								structure.returnACVFoundationCondition,
							returnACVStructureInUse: structure.returnACVStructureInUse
						});
					});
				}
			}

			if (
				report.toLowerCase() === "heritagecommerciallinesunderwritingguidelines"
			) {
				if (
					window?.transactionModel?.request?.params?.[report]?.modelInput
						.buildings.length > 0
				) {
					window.mrState.reports.heritageCommercialLinesUnderwritingGuidelines.populateModelInputs(
						window?.transactionModel?.request?.params?.[report]?.modelInput
							.buildings
					);
				}
			}
		}

		disableBatchIfViewOnly();
		disableSearchIfViewOnly();
		disableReportsIfViewOnly(
			Object.keys(window.reportConfigurationModel).map(function (el) {
				if (window.reportConfigurationModel[el].display === true) {
					return window.reportConfigurationModel[el].reportId;
				}
			}),
			window.transactionModel.request.reportList
		);

		let transactionRequestCompleted;
		if (typeof Event === "function") {
			transactionRequestCompleted = new Event("transaction_request_completed");
		} else {
			transactionRequestCompleted = document.createEvent("Event");
			transactionRequestCompleted.initEvent(
				"transaction_request_completed",
				true,
				true
			);
		}
		document.dispatchEvent(transactionRequestCompleted);
	};

	var transactionClient = new TransactionClient();

	transactionClient.getTransactionById(
		window.transactionId,
		getTransactionCallback
	);
}

function disableBatchIfViewOnly() {
	if (window.mrState.viewOnly && window.mrState.viewOnly.enabled === true) {
		window.mrState.batchEnabled = false;
	}
}

function disableSearchIfViewOnly() {
	if (
		window.mrState.viewOnly &&
		window.mrState.viewOnly.enabled === true &&
		window.mrState.viewOnly.modifyAddressBar !== true
	) {
		window.addressSearch.disableSearchButton();
		window.addressSearch.disableTextBox();
	}
}

function disableReportsIfViewOnly(reportIds, transactionReportIds) {
	if (
		transactionReportIds &&
		window.mrState.viewOnly &&
		window.mrState.viewOnly.enabled === true
	) {
		var nonTransactionReportIds = reportIds.filter(function (id) {
			return transactionReportIds.indexOf(id) > -1 ? false : true;
		});

		if (
			window.mrState.viewOnly.reports &&
			window.mrState.viewOnly.reports.add !== true
		) {
			nonTransactionReportIds.forEach(function (reportId) {
				$(
					".rightSidebarContentWrapper #chkExecuteReport_" + reportId + "_index"
				).prop("disabled", true);
			});
		}

		if (
			window.mrState.viewOnly.reports &&
			window.mrState.viewOnly.reports.remove !== true
		) {
			transactionReportIds.forEach(function (reportId) {
				$(
					".rightSidebarContentWrapper #chkExecuteReport_" + reportId + "_index"
				).prop("disabled", true);
			});
		}
	}
}

function disableReportReRunIfViewOnly() {
	if (
		window?.mrState?.viewOnly?.enabled === true &&
		window?.mrState?.viewOnly?.reports?.enableReRunButton !== true
	) {
		return true;
	} else {
		return false;
	}
}

function buildGeoResultFromRequest(transaction) {
	var poi = convertPoiCasing(transaction.request.poi);
	var street = "";

	if (
		poi.streetNumber &&
		poi.street &&
		poi.street.indexOf(poi.streetNumber) == -1
	) {
		street += poi.streetNumber + " ";
	}

	if (poi.street) {
		street += poi.street;
	}

	var latLng = getLatLngFromTransactionModel(transaction);

	return {
		latitude: latLng.latitude,
		longitude: latLng.longitude,
		street: street,
		unit: poi.unit,
		city: poi.city,
		state: poi.state,
		zip: poi.zip,
		zipPlus4: "",
		source: "",
		geocodeAccuracyResult: {}
	};
}

function getLatLngFromTransactionModel(transaction) {
	var geocodeReportId;
	var latLng = {};

	for (var report in transaction.response.reportResults) {
		if (report.toLowerCase() === "geocode") geocodeReportId = report;
	}

	if (transaction.request.poi.latitude && transaction.request.poi.longitude) {
		latLng.latitude = transaction.request.poi.latitude;
		latLng.longitude = transaction.request.poi.longitude;

		return latLng;
	}

	return {
		latitude: transaction.response.reportResults[geocodeReportId][0].latitude,
		longitude: transaction.response.reportResults[geocodeReportId][0].longitude
	};
}

function convertPoiCasing(poi) {
	var caseSetting = checkGeocodeCaseConversion();

	for (var key in poi) {
		var field = poi[key];

		if (!isNaN(field) || !(typeof field == "string")) {
			continue;
		}

		var parts = field.split(" ");

		for (var partKey in parts) {
			switch (caseSetting) {
				case "lower":
					parts[partKey] = parts[partKey].toLowerCase();
					break;
				case "upper":
					parts[partKey] = parts[partKey].toUpperCase();
					break;
				case "pascal":
				default:
					parts[partKey] =
						parts[partKey][0].toUpperCase() +
						parts[partKey].slice(1, parts[partKey].length);
					break;
			}
		}

		poi[key] = parts.join(" ");
	}

	return poi;
}

function checkGeocodeCaseConversion() {
	var geocodeConfig = window.reportConfigurationModel.geocode || null;

	if (
		!geocodeConfig ||
		!geocodeConfig.parameters ||
		geocodeConfig.parameters.length == 0
	) {
		return "pascal";
	}

	var caseConversionIndex = geocodeConfig.parameters.indexOf(function (param) {
		return param.key == "CaseConversion";
	});

	if (caseConversionIndex < 0) {
		return "pascal";
	}

	var caseConversion = geocodeConfig.parameters[caseConversionIndex];

	switch (caseConversion.value.Type) {
		case "PascalCase":
			return "pascal";
		case "UpperCase":
			return "upper";
		case "LowerCase":
			return "lower";
	}

	return "pascal";
}
function isRoofCoveringTile(listOfTiles, value) {
	value = String(value).toLowerCase();

	if (
		listOfTiles &&
		value &&
		listOfTiles[value] !== null &&
		listOfTiles[value] !== undefined &&
		listOfTiles[value] >= 0
	) {
		return listOfTiles[value];
	}
}
function setDataLayer(mapController, reportId, layerName) {
	for (var layer in window.mrState.dataLayers[reportId]) {
		window.mrState.dataLayers[reportId][layer].deactivate();
	}

	window.mrState.dataLayers[reportId][layerName].activate();
	$("#mr-layer-slider-" + reportId).removeClass("disabled");
}

function updateTips(t) {
	var tips = $(".validateTips");

	tips.text(t).addClass("ui-state-highlight");

	setTimeout(function () {
		tips.removeClass("ui-state-highlight", 1500);
	}, 500);
}

function checkLength(o, n, min, max) {
	if (o.val().length > max || o.val().length < min) {
		o.addClass("ui-state-error");
		updateTips(
			"Length of " + n + " must be between " + min + " and " + max + "."
		);

		return false;
	} else {
		return true;
	}
}

function checkRegexp(o, regexp, n) {
	if (!regexp.test(o.val())) {
		o.addClass("ui-state-error");
		updateTips(n);

		return false;
	} else {
		return true;
	}
}

function checkRegexpStr(str, regexp) {
	if (!regexp.test(str)) {
		return false;
	} else {
		return true;
	}
}

function searchForReportGroupRow(reportGroup) {
	var lastEl;

	var searchResult = { previous: null, found: null };

	$(".reportResultGroupHeader").each(function (index) {
		if ($(this).attr("reportGroupId") < reportGroup) {
			lastEl = searchResult.previous = $(this);
		}

		if ($(this).attr("reportGroupId") == reportGroup) {
			lastEl = searchResult.found = $(this);
		}
	});

	return searchResult;
}

function initializeResultMap() {
	var reportGroupId = "Place_Information";
	var reportId = "Result_Map";
	var reportGroupRow, reportGroupRowTd;
	var $reportGroupResultRow = $("#reportGroupWrapper_" + reportGroupId);
	var reportGroupSearchResult = searchForReportGroupRow(reportGroupId);
	var reportGroupName = "Place Information";
	var newRow;
	var additionalInfoRowTd;
	var reportNameHeaderWrapper = $('<div class="reportResultsHeader">');
	var reportNameTextWrapper = $("<div class='reportNameHeaderTextContainer'>");
	var additionInfoRowId = "divAdditionalReportInformation_" + reportId;
	var trigger = $(
		"<a href='#' class='accordion-trigger' data-accordion-target='#" +
			additionInfoRowId +
			"' data-accordion-container='.reportResultsHeader' title='Expand Report'><span>Result Map</span></a>"
	);

	reportNameTextWrapper.append(trigger);
	reportNameHeaderWrapper.append(reportNameTextWrapper);

	newRow = $("<div class='reportTableRow' id='trResultMapTableRow'>");
	newRow.append(reportNameHeaderWrapper);

	additionalInfoRowTd = $(
		"<div id='" +
			additionInfoRowId +
			"' class='additionalInfo reportInfo'><table class='table'><tr><td><div id=\"divResultMapContainer\"><div id=\"divResultMap\"></div></div></td></tr></table></div>"
	);

	newRow.on("click", function (e) {
		if (document.createEvent) {
			var e = document.createEvent("HTMLEvents");

			e.initEvent("resize", true, false);
			document.body.dispatchEvent(e);
		} else if (document.createEventObject) {
			document.body.fireEvent("onresize");
		}

		window.resultMapController.recenter();
	});

	if ($reportGroupResultRow.length <= 0) {
		reportGroupRow = $(
			"<div id='reportGroup_" +
				reportGroupId +
				"' class='reportResultGroupHeader'>"
		);
		reportGroupRow.attr("reportGroupId", reportGroupId);
		reportGroupRow.attr("reportGroupName", reportGroupName);
		reportGroupRow.append(
			$(
				'<a class="accordion-trigger" href="#" data-accordion-target="#reportGroupWrapper_' +
					reportGroupId +
					'" title="Expand Group"><span class="reportGroupName">' +
					reportGroupName +
					"</span></a>"
			)
		);
		$reportGroupResultRow = $(
			"<div id='reportGroupWrapper_" +
				reportGroupId +
				"' class='report-group-wrapper'>"
		);
		reportGroupRow.append($reportGroupResultRow);

		reportGroupRow.on("click", function (e) {
			$(this).find(".divExpandable").toggleClass("expanded");
		});

		if (reportGroupSearchResult.previous == null) {
			$("#reportResults").prepend(reportGroupRow);
		} else {
			reportGroupRow.insertAfter(reportGroupSearchResult.previous);
		}
	}

	$("#reportGroupWrapper_" + reportGroupId).append(newRow);

	var additioanlInfoRowObj = newRow.append(additionalInfoRowTd);

	var options = {
		mapping: {
			mapObject: $("#divResultMap"),
			centerPoi: { lat: 37.967294, lng: -96.152343 },
			initalZoom: 20,
			mapTypeId: "satellite",
			streetViewControl: false,
			disableDefaultUI: true,
			panControl: true,
			draggable: false
		}
	};

	window.resultMapController = new ResultMapController(options);
	window.resultMapController.initMap();
}

function appendToResultMap(poi) {
	window.resultMapController.setMapPoi(poi, 0, 0, 18);

	var marker = window.resultMapController.addMarker(poi, poi.addressLine, true);

	window.resultMapController.addReportOverlay(marker);
}

function addAddressMarker(poi, reportContext) {
	if (!poi.addressLine) {
		if (poi.street && poi.city && poi.state) {
			poi.addressLine = "";
			poi.addressLine += poi.streetNumber ? poi.streetNumber + " " : "";
			poi.addressLine += `${poi.street}, ${poi.city}, ${poi.state} `;
			poi.addressLine += poi.zip ? poi.zip + " " : "";
			poi.addressLine += poi.country ? poi.country : "";
		}
	}

	var addTxtAsPdfLabel = true;
	var dragConfig = extractDragConfigFromAccountConfig();
	var isPoi = true;

	var layerObj = window.mapRiskController.addMarker(
		poi,
		poi.addressLine,
		addTxtAsPdfLabel,
		dragConfig,
		isPoi
	);

	window.mapRiskController.addReportOverlay(layerObj);

	if (reportContext) {
		reportContext.layers.push(layerObj);
	}

	appendToResultMap(poi);
}

function extractDragConfigFromAccountConfig() {
	var dragConfig;

	if (
		window.accountConfigurationModel &&
		window.accountConfigurationModel.runtimeConfiguration &&
		window.accountConfigurationModel.runtimeConfiguration.website &&
		window.accountConfigurationModel.runtimeConfiguration.website.poi &&
		window.accountConfigurationModel.runtimeConfiguration.website.poi
			.moveMarkerForReportReRun
	) {
		dragConfig =
			window.accountConfigurationModel.runtimeConfiguration.website.poi
				.moveMarkerForReportReRun;
	} else {
		dragConfig = {};
	}

	if (dragConfig.enabled == null || dragConfig.enabled == undefined) {
		dragConfig.enabled = true;
	}

	if (dragConfig.enabled && !dragConfig.numReRuns) {
		dragConfig.numReRuns = 2;
	}

	if (dragConfig.enabled && !dragConfig.maxMoveRadius) {
		dragConfig.maxMoveRadius = 402.336;
	}

	if (
		dragConfig.enabled &&
		!window.mapRiskController.skipGeocodeOnNextSearch &&
		!window.mapRiskController.originalPoi
	) {
		dragConfig.remainingReRuns = dragConfig.numReRuns;
	}

	return dragConfig;
}

function copyToClipboard() {
	var id = "url-link-display";
	var copyInput = document.querySelector("#" + id);
	copyInput.classList.add("active");
	copyInput.focus();
	copyInput.select();
	document.execCommand("SelectAll");
	document.execCommand("copy", false, null);
	copyInput.classList.remove("active");

	window.mapUNC.pushMessage(
		window.mrUserNotifications.urlLinkCopiedToClipboard,
		"info"
	);
	return false;
}

function buildSummaryTables(summaryTableObject, aggregateTable) {
	if (
		typeof summaryTableObject === "object" &&
		!Array.isArray(summaryTableObject)
	) {
		for (var e in summaryTableObject) {
			buildSummaryTables(summaryTableObject[e], aggregateTable);
		}
	} else if (Array.isArray(summaryTableObject)) {
		for (var i = 0; i < summaryTableObject.length; i++) {
			aggregateTable.push(summaryTableObject[i]);
		}
	}
}

function stripNonInterpolatedValues(result) {
	// delete report result columns with '%s' in the element's key OR
	// null out report result columns with '%s' in the element's value
	for (var i = 0; i < result.length; i++) {
		var resultMember = result[i];
		for (var prop in resultMember) {
			if (
				resultMember[prop] !== null &&
				typeof resultMember[prop] !== "object" &&
				!Array.isArray(resultMember[prop])
			) {
				if (prop.toString().indexOf("%s") >= 0) {
					delete result[i][prop];
				}

				if (resultMember[prop].toString().indexOf("%s") >= 0) {
					result[i][prop] = null;
				}
			} else if (
				resultMember[prop] !== null &&
				typeof resultMember[prop] === "object" &&
				!Array.isArray(resultMember[prop])
			) {
				for (var key in resultMember[prop]) {
					if (key.toString().indexOf("%s") >= 0) {
						delete result[i][prop][key];
					}

					if (
						resultMember[prop][key] &&
						resultMember[prop][key].toString().indexOf("%s") >= 0
					) {
						result[i][prop][key] = null;
					}
				}
			}
		}
	}

	return result;
}

var mrClickableOverrides = function (reportId) {
	var self = this;
	self.options = {};
	self.infoWindowToggleLinkBaseClass = "info-window-toggle-link";
	self.infoWindowLocationLinkBaseClass = "info-window-location-link";
	self.reportId = reportId;

	self.state = {
		activeFeature: [],
		currentMarkers: [],
		listeners: {},
		visibleMarkers: []
	};

	self.init = function (options) {
		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (self.state.listeners.boundsChanged) {
			self.detachBoundsChangeListener();
		}
		self.attachBoundsChangeListener();
		self.detachViewSwitchHandler();
		self.attachViewSwitchHandler();
		self.detachLocationSwitchHandler();
		self.attachLocationSwitchHandler();
	};

	self.setOpacity = function (opacity) {
		self.state.currentMarkers.forEach((el) => {
			el.marker.setOpacity(opacity);
		});
	};

	self.debouncedBoundsChange = utils.debounce(function () {
		self.createFeatures();
	}, 250);

	self.attachBoundsChangeListener = function () {
		self.state.listeners.boundsChanged = google.maps.event.addListener(
			window.mapRiskController.map,
			"bounds_changed",
			self.debouncedBoundsChange
		);
	};

	self.detachBoundsChangeListener = function () {
		google.maps.event.removeListener(self.state.listeners.boundsChanged);
	};

	self.triggerFeatureClick = function (marker) {
		google.maps.event.trigger(marker, "click");
		document.removeEventListener("clickable_override_markers_drawn", this);
	};

	self.getPreviousFeature = function (reportId, index) {
		var prevMarker = self.state.currentMarkers[index - 1].marker;
		if (!prevMarker.map) {
			self.createFeature(index - 1);
		}

		google.maps.event.trigger(prevMarker, "click");
	};

	self.getNextFeature = function (reportId, index) {
		var nextMarker = self.state.currentMarkers[index + 1].marker;

		if (!nextMarker.map) {
			self.createFeature(index + 1);
		}

		google.maps.event.trigger(nextMarker, "click");
	};

	self.depopulateFeatures = function () {
		self.state.currentMarkers = [];
	};

	self.attachViewSwitchHandler = function () {
		$(document).on("click", "." + self.infoWindowToggleLinkBaseClass, function (
			e
		) {
			e.preventDefault();
			self.switchView(this);
		});
	};

	self.detachViewSwitchHandler = function () {
		$(document).off("click", "." + self.infoWindowToggleLinkBaseClass);
	};

	self.attachLocationSwitchHandler = function () {
		$(document).on(
			"click",
			"." + self.infoWindowLocationLinkBaseClass,
			function (e) {
				e.preventDefault();
				$(this)
					.parents(".info-window-container")
					.find(".info-window-location-link")
					.removeClass("active");
				$(this).addClass("active");
				self.switchLocation(this);
			}
		);
	};

	self.detachLocationSwitchHandler = function () {
		$(document).off("click", "." + self.infoWindowLocationLinkBaseClass);
	};

	self.switchView = function (el) {
		var $container = $(el).parents(".info-window-container");
		var $summary = $container.find(".info-window-summary");
		var $features = $container.find(".info-window-features");

		if ($summary.hasClass("active")) {
			$summary.removeClass("active");
			$features.addClass("active");
		} else {
			$summary.addClass("active");
			$features.removeClass("active");
		}
	};

	self.switchLocation = function (el) {
		var $container = $(el).parents(".info-window-container");
		var id = $(el).attr("data-id");

		var $location = $container.find(
			".info-window-location[data-id=" + id + "]"
		);

		$container.find(".info-window-location").each(function () {
			$(this).removeClass("active");
		});

		$location.addClass("active");
	};

	self.createInfoWindowLocationLinks = function (locations) {
		var contentString =
			'<div class="info-window-location-links"><div class="info-window-location-link-heading">ARF#</div>';
		for (
			var locationIndex = 0;
			locationIndex < locations.length;
			locationIndex++
		) {
			var location = locations[locationIndex];

			contentString += '<div class="info-window-location-link-wrapper">';
			if (locationIndex === 0) {
				contentString +=
					'<a href="#" class="info-window-location-link active" data-id="' +
					locationIndex +
					'">';
			} else {
				contentString +=
					'<a href="#" class="info-window-location-link" data-id="' +
					locationIndex +
					'">';
			}

			contentString += location.arfnumber.value + "</a></div>";
		}
		contentString += "</div>";

		return contentString;
	};

	self.createInfoWindowFeatures = function (features) {
		var showPageNums =
			utils.getReportParameterByName(
				window.reportConfigurationModel,
				self.reportId,
				"showInfoWindowPageNumbers"
			) === false
				? false
				: true;
		var contentString = "<div class='info-window-features'>";

		contentString += '<div class="info-window-toggle">';
		contentString +=
			'<span class="' +
			self.infoWindowToggleLinkBaseClass +
			'">Switch to Location Summary</span>';
		contentString += "</div>";

		if (features.length > 1) {
			contentString +=
				'<div class="info-window-pagination" data-max-num="' +
				(features.length - 1) +
				'" data-current-page="1">';
			contentString +=
				'<span class="info-window-page-link info-window-page-link-previous">Prev</span>';
			if (showPageNums) {
				contentString +=
					'<span class="info-window-page-current"><span class="info-window-page-number">1</span> of ' +
					features.length +
					"</span>";
			}
			contentString +=
				'<span class="info-window-page-link info-window-page-link-next">Next</span>';
			contentString += "</div>";
		}

		contentString += '<div class="info-window-wrappers">';

		for (var featureIndex = 0; featureIndex < features.length; featureIndex++) {
			var active = featureIndex === 0 ? " active" : "";
			contentString +=
				'<div class="info-window-wrapper' +
				active +
				'" data-page-number="' +
				featureIndex +
				'">';

			contentString +=
				"<table class='info-window-table info-window-table-page'>";

			for (var property in features[featureIndex]) {
				var feature = features[featureIndex];

				if (property === "latitude") continue;
				if (property === "longitude") continue;

				contentString += "<tr class='info-window-table-row'>";
				contentString +=
					"<th class='info-window-table-cell info-window-table-cell-heading' scope='row'>" +
					feature[property].displayName +
					"</th><td class='info-window-table-cell'>" +
					feature[property].value +
					"</td>";
				contentString += "</tr>";
			}

			contentString += "</table>";
			contentString += "</div>";
		}

		contentString += "</div>";
		contentString += "</div>";

		return contentString;
	};

	self.createInfoWindowContents = function (
		reportId,
		location,
		locationIndex,
		stackedMarkers,
		markerIndex
	) {
		var features = location.features || {};

		var contentString =
			locationIndex === 0
				? '<div class="info-window-location active" data-id="' +
				  locationIndex +
				  '">'
				: '<div class="info-window-location" data-id="' + locationIndex + '">';
		contentString += '<div class="info-window-summary active">';

		contentString += '<div class="info-window-toggle">';
		contentString +=
			'<span class="' +
			self.infoWindowToggleLinkBaseClass +
			'">Switch to Loc/Layer/Peril Details</span>';
		contentString += "</div>";

		contentString += '<div class="info-window-pagination">';
		if (markerIndex > 0) {
			contentString +=
				'<span class="info-window-page-link" onclick="window.mrState.mrClickableOverrides[\'' +
				reportId +
				"'].getPreviousFeature('" +
				reportId +
				"', " +
				markerIndex +
				')">Previous</span>';
		}

		if (markerIndex < stackedMarkers.length - 1) {
			contentString +=
				'<span class="info-window-page-link" onclick="window.mrState.mrClickableOverrides[\'' +
				reportId +
				"'].getNextFeature('" +
				reportId +
				"', " +
				markerIndex +
				')">Next</span>';
		}
		contentString += "</div>";

		contentString += "<table class='info-window-table'>";
		for (var e in location) {
			if (e == "latitude") continue;
			if (e == "longitude") continue;
			if (e == "features") continue;
			contentString += "<tr class='info-window-table-row'>";
			contentString +=
				"<th class='info-window-table-cell info-window-table-cell-heading' scope='row'>" +
				location[e].displayName +
				"</th><td class='info-window-table-cell'>" +
				location[e].value +
				"</td>";
			contentString += "</tr>";
		}
		contentString += "</table>";
		contentString += "</div>";

		if (features) {
			contentString += self.createInfoWindowFeatures(features, reportId);
		}

		contentString += "</div>";

		return contentString;
	};

	self.populateFeatures = function (stackedMarkers) {
		self.state.currentMarkers = [];

		var markerConfigs = utils.getReportParameterByName(
			window.reportConfigurationModel,
			self.reportId,
			"clickableOverridesMarkerConfig"
		);

		var markerConfig = {
			path: google.maps.SymbolPath.CIRCLE,
			fillOpacity: 0.6,
			fillColor: "#000000",
			strokeOpacity: 1,
			strokeColor: "#000000",
			strokeWeight: 1,
			radius: 25,
			scale: 10
		};

		for (var index = 0; index < stackedMarkers.length; index++) {
			var lat = stackedMarkers[index].latitude;
			var lng = stackedMarkers[index].longitude;
			var locations = stackedMarkers[index].locations;
			var contentString = "";

			contentString +=
				'<div class="info-window-container info-window-clickable-overrides info-window-paginated">';

			if (locations.length > 1) {
				contentString += self.createInfoWindowLocationLinks(locations);
			}

			for (
				var locationIndex = 0;
				locationIndex < locations.length;
				locationIndex++
			) {
				var location = locations[locationIndex];
				contentString += self.createInfoWindowContents(
					self.reportId,
					location,
					locationIndex,
					stackedMarkers,
					index
				);
			}

			markerConfigs.forEach(function (config) {
				var column = config.column;
				var rangeTests = [];
				var testPassed = false;

				for (
					var rangeIndex = 0;
					rangeIndex < config.range.length;
					rangeIndex++
				) {
					var range = config.range[rangeIndex];
					var rangeTest = false;
					switch (range.operator) {
						case ">":
							if (stackedMarkers[index][column] > range.value) {
								rangeTest = true;
							}
							break;
						case ">=":
							if (stackedMarkers[index][column] >= range.value) {
								rangeTest = true;
							}
							break;
						case "<":
							if (stackedMarkers[index][column] < range.value) {
								rangeTest = true;
							}
							break;
						case "<=":
							if (stackedMarkers[index][column] <= range.value) {
								rangeTest = true;
							}
							break;
					}
					rangeTests.push(rangeTest);
				}

				testPassed = rangeTests.every(function (e) {
					return e === true;
				});

				config.markerConfig.path = google.maps.SymbolPath.CIRCLE;

				if (testPassed) {
					markerConfig = config.markerConfig || {
						path: google.maps.SymbolPath.CIRCLE,
						fillOpacity: 0.6,
						fillColor: "#ff9900",
						strokeOpacity: 1,
						strokeColor: "#000000",
						strokeWeight: 1,
						radius: 25,
						scale: 10
					};
				}
			});

			contentString += "</div>";

			var markerIcon = markerConfig;

			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(lat, lng),
				icon: markerIcon,
				index: index,
				infoWindow: new google.maps.InfoWindow({
					content: contentString
				})
			});

			stackedMarkers[index].marker = marker;

			self.state.currentMarkers.push(stackedMarkers[index]);
		}
	};

	self.createFeatures = function () {
		var data = self.state.currentMarkers || [];
		self.state.visibleMarkers = [];

		for (var index = 0; index < data.length; index++) {
			if (
				window.mapRiskController.map
					.getBounds()
					.contains(data[index].marker.position)
			) {
				self.createFeature(index);
				self.state.visibleMarkers.push(data[index]);
			} else {
				self.removeFeatureByIndex(index);
			}
		}
	};

	self.createFeature = function (index) {
		var marker = self.state.currentMarkers[index].marker;
		var markerPos = marker.getPosition();

		marker.setMap(window.mapRiskController.map);

		marker.addListener("click", function () {
			self.state.currentMarkers.forEach(function (el) {
				el.marker.infoWindow.close();
			});

			window?.mrState?.layerMultiselects?.[self.reportId]?.layers?.forEach(
				function (layer) {
					if (layer.currentInfoWindow) {
						layer.currentInfoWindow.close();
					}
				}
			);

			marker.infoWindow.open(window.mapRiskController.map, marker);
			self.state.activeFeature = marker;
		});
	};

	self.removeFeatureByIndex = function (index) {
		self.state.currentMarkers[index].marker.setMap(null);
	};

	self.clearFeatures = function () {
		if (self.state.currentMarkers && self.state.currentMarkers.length > 0) {
			for (var i = 0; i < self.state.currentMarkers.length; i++) {
				var currentMarker = self.state.currentMarkers[i];

				currentMarker.marker.setMap(null);
				google.maps.event.clearListeners(currentMarker.marker, "click");
			}
		}
	};
};

function appendReportResult(
	geoResult,
	reportData,
	reportId,
	reportContext,
	poi,
	layerObj
) {
	if (!reportData.success) {
		dialog.openDialog(CONSTANTS.DIALOG.APPEND_REPORT_RESULT_ERROR);
		return;
	}

	var leftSidebarWrapperWidth = $(".leftSidebarContentWrapper").width();
	var rightSidebarWrapperWidth = $(".rightSidebarContentWrapper").width();
	var reportTable = $(".leftSidebarContent .reportTableWrapper");
	var reportTableResults = $("#reportResults");

	if (poi) {
		/**
		 * Check that currently-searched address does not equal previously-searched address
		 * and re-center if true.
		 * Reset HighRes dropdown as well.
		 * 20181030 - CA
		 */
		if (
			typeof window.mapRiskController.currentPoi === "undefined" ||
			poi.lat !== window.mapRiskController.currentPoi.lat ||
			poi.lng !== window.mapRiskController.currentPoi.lng
		) {
			// reset map to default type only if new address has been searched
			window.mapRiskController.setMapPoi(poi, 0, 0, 17);
		}

		if (
			self.filterResultsWithDrawingTools &&
			window.mapRiskController.mapDrawingToolsFilterControl
		) {
			window.mapRiskController.mapDrawingToolsFilterControl.enable();
		}

		if (window.mapRiskController.mapStreetViewControl) {
			window.mapRiskController.mapStreetViewControl.enable();
		}
	}

	var $reportTableRow = $(
		"#" + reportContext.additionalInfoRowId + "_reportTableRow"
	);

	$reportTableRow.attr("transaction_id", reportData.request.transactionId);
	var $urlLinkDomEl = $(".url-link-display");

	if (window.displayUrlLink && $urlLinkDomEl.length == 0) {
		var $copyUrlLinkContainer = $(
			"<div id='url-link-container' class='url-link-container'><input type='text' id='url-link-display' class='url-link-display'><button type='button' id='button-url-link-display' class='button button-cta button-url-link-display'>Copy URL</button></div>"
		);
		$copyUrlLinkContainer.on("click", function (e) {
			e.preventDefault();
			copyToClipboard();
		});

		$(".report-results-toolbar").append($copyUrlLinkContainer);
		$urlLinkDomEl = $(".url-link-display");
	}

	if (
		window.displayUrlLink &&
		window.geocodeModel &&
		window.geocodeModel.response &&
		window.geocodeModel.response.urlLink
	) {
		$urlLinkDomEl.val(window.geocodeModel.response.urlLink);
	} else if (
		window.displayUrlLink &&
		window.transactionModel?.response?.urlLink
	) {
		$urlLinkDomEl.val(window.transactionModel?.response?.urlLink);
	}

	var $additionalInfoRowData = $reportTableRow.find("table");

	var reportCount = 0;
	var reportLength = Object.keys(reportData.response.reportResults).length;

	window.mrState.reportResults = reportData.response.reportResults;

	for (var result in reportData.response.reportResults) {
		reportCount++;
		var currentReportResult = reportData.response.reportResults[result];

		if (result === "property" && currentReportResult.error) {
			continue;
		}

		$additionalInfoRowData.empty();
		$('.get-report-btn[data-reportid="' + result + '"]').hide();

		if (currentReportResult.error && currentReportResult.error.length > 0) {
			$additionalInfoRowData.append(
				'<tr><td class="colName">Error:</td><td class="colValue">' +
					currentReportResult.error +
					"</td></tr>"
			);
			continue;
		}

		if (window?.mrState?.replacementCost?.otherAreas?.[reportId]) {
			window.mrState.replacementCost.otherAreas[reportId].removeAll();
		}

		for (var index in currentReportResult) {
			var reportParameters =
				window.reportConfigurationModel[reportId.toLowerCase()].parameters;
			var fieldConfiguration = utils.getReportParameterByName(
				window.reportConfigurationModel,
				reportId,
				"websiteFieldConfiguration"
			)
				? utils.getReportParameterByName(
						window.reportConfigurationModel,
						reportId,
						"websiteFieldConfiguration"
				  )
				: {};

			currentReportResult[index] =
				currentReportResult[index] === null ? [] : currentReportResult[index];

			if (index == "summaryTables") {
				var isAggregateSummaryReport = false;
				var aggregateTable = [];
				var summaryTableObject = currentReportResult[index];
				var summaryTableKeys = Object.keys(summaryTableObject);

				for (var i = 0; i < mainReportView.state.currentReports.length; i++) {
					if (mainReportView.state.currentReports[i].reportId === reportId) {
						foundReportView = true;
						break;
					}
				}

				buildSummaryTables(summaryTableObject, aggregateTable);

				if (aggregateTable.length > 0) {
					var viewConfig = {
						reportContext: reportContext,
						reportId: reportId,
						excludeColumns: [
							"aggregateSummaryTableName",
							"aggregateSummarySubTableName"
						],
						details: aggregateTable
					};

					if (foundReportView) {
						mainReportView.removeReport(reportId);
					}

					mainReportView.addReport(viewConfig);
				}
				continue;
			}

			if (index == "clickableOverrides") {
				window.mrState.mrClickableOverrides[
					reportId
				] = new mrClickableOverrides(reportId);
				window.mrState.mrClickableOverrides[reportId].init();
				window.mrState.mrClickableOverrides[reportId].populateFeatures(
					currentReportResult[index]
				);
				window.mrState.mrClickableOverrides[reportId].createFeatures();

				continue;
			}

			if (index == "results" || index == "features") {
				var foundReportView = false;
				var isAggregateSummaryReport = false;
				var aggregateTable = [];

				currentReportResult[index] = stripNonInterpolatedValues(
					currentReportResult[index]
				);

				for (var i = 0; i < mainReportView.state.currentReports.length; i++) {
					if (mainReportView.state.currentReports[i].reportId === reportId) {
						foundReportView = true;
						break;
					}
				}

				for (var i = 0; i < currentReportResult[index].length; i++) {
					if (
						currentReportResult[index][i] &&
						currentReportResult[index][i].aggregateSummaryTableName
					) {
						isAggregateSummaryReport = true;

						aggregateTable.push(currentReportResult[index][i]);
					}
				}

				if (isAggregateSummaryReport) {
					var viewConfig = {
						reportContext: reportContext,
						reportId: reportId,
						excludeColumns: [
							"aggregateSummaryTableName",
							"aggregateSummarySubTableName"
						],
						details: aggregateTable
					};

					if (foundReportView) {
						mainReportView.removeReport(reportId);
					}

					mainReportView.addReport(viewConfig);
				}

				for (var resultIndex in currentReportResult[index]) {
					var skipTable = false;

					for (var key in currentReportResult[index][resultIndex]) {
						if (
							key === "aggregateSummaryTableName" ||
							key === "aggregateSummarySubTableName"
						) {
							skipTable = true;
						}
					}

					if (!skipTable) {
						$additionalInfoRowData.append('<tr><td colspan="2"><hr></td></tr>');

						for (var key in currentReportResult[index][resultIndex]) {
							if (index === "features" && key === "polygon") {
								drawPolygon(
									reportContext,
									currentReportResult[index][resultIndex][key],
									layerObj
								);
								continue;
							}

							var keyDisplayName = utils.titleify(key);
							var hideKey = false;
							for (var fieldName in fieldConfiguration.fields) {
								if (fieldName.toLowerCase() === key.toLowerCase()) {
									keyDisplayName =
										fieldConfiguration.fields[fieldName].displayName ||
										utils.titleify(key);
									hideKey = fieldConfiguration.fields[fieldName].hide || false;
									break;
								}
							}

							if (
								key === "closestFeaturePoint" &&
								window.reportConfigurationModel[
									result.toLowerCase()
								].parameters.filter(function (param) {
									return param.key === "markerIsDraggable";
								}).length > 0
							) {
								// disable high-res imagery if report is of a closest feature type to keep the forced zoom from triggering a zoomed-out tile request.

								if (window.mapRiskController.mapOverlayControl) {
									window.mapRiskController.mapOverlayControl.reset();
									window.mapRiskController.mapOverlayControl.$mapControlSelect.val(
										null
									);
									window.mapRiskController.mapOverlayControl.$mapControlSelect.trigger(
										"change"
									);
									window.mapRiskController.mapOverlayControl.enable();
								}

								var reportResult = currentReportResult[index][resultIndex];

								var featPoi = {
									lat: reportResult.closestFeaturePoint.latitude,
									lng: reportResult.closestFeaturePoint.longitude
								};

								var markerDragConfig = { enabled: false };

								if (
									window.reportConfigurationModel &&
									window.reportConfigurationModel[result.toLowerCase()]
								) {
									for (var parametersIndex in window.reportConfigurationModel[
										result.toLowerCase()
									].parameters) {
										if (
											window.reportConfigurationModel[result.toLowerCase()]
												.parameters[parametersIndex].key === "markerIsDraggable"
										)
											markerDragConfig.enabled =
												window.reportConfigurationModel[
													result.toLowerCase()
												].parameters[parametersIndex].value;
									}
								}

								var markerLabel =
									reportResult &&
									reportResult.distanceToFeature &&
									reportResult.distanceToFeatureUnits
										? reportResult.distanceToFeature +
										  " " +
										  reportResult.distanceToFeatureUnits
										: null;

								layerObj = window.mapRiskController.addMarker(
									featPoi,
									markerLabel,
									null,
									markerDragConfig,
									false,
									reportId
								);
								window.mapRiskController.addReportOverlay(layerObj);
								reportContext.layers.push(layerObj);

								layerObj = window.mapRiskController.drawLine(poi, featPoi);
								window.mapRiskController.addReportOverlay(layerObj, true);
								reportContext.layers.push(layerObj);
								var bounds = new google.maps.LatLngBounds();
								var currentCenter = window.mapRiskController.latLngPoi;

								bounds.extend(currentCenter);
								bounds.extend(window.mapRiskController.normalizePoint(featPoi));
								window.mapRiskController.map.fitBounds(bounds);
								var midpoint = window.mapRiskController.getPointOnLineByPercent(
									window.mapRiskController.latLngPoi,
									featPoi,
									0.5
								);

								window.mapRiskController.setMapCenter(
									{ lat: midpoint.lat(), lng: midpoint.lng() },
									leftSidebarWrapperWidth / 2 - rightSidebarWrapperWidth / 2,
									0,
									window.mapRiskController.map.getZoom() - 1
								);
							}

							if (key === "radius") {
								var radius = currentReportResult[index][resultIndex].radius;
								var min;
								var max;
								var radiusIsReal = false;
								var radiusPoi = radius.poi || poi;

								if (!isNaN(radius)) {
									min = 0;
									max = radius;
									radiusIsReal = true;
								} else {
									if (radius.min || radius.max) {
										min = radius.min || 0;

										if (radius.max && !isNaN(radius.max) && radius.max > 0) {
											max = radius.max;
											radiusIsReal = true;
										}
									}
								}

								if (radiusIsReal) {
									var radiusExists = false;

									var radiusUnits =
										typeof currentReportResult[index][resultIndex]
											.radiusUnits !== "undefined"
											? currentReportResult[index][resultIndex].radiusUnits
											: null;

									if (!window.mapRiskController.radiusOverlays[result]) {
										window.mapRiskController.radiusOverlays[result] = {
											poi: radiusPoi,
											radii: []
										};
									}

									window.mapRiskController.radiusOverlays[result].radii.forEach(
										function (el) {
											if (
												el.radius.min == min &&
												el.radius.max == max &&
												el.radius.poi == radiusPoi
											) {
												radiusExists = true;
											}
										}
									);

									if (!radiusExists) {
										var radiusOverlay = window.mapRiskController.createPoiRadius(
											radiusPoi,
											{ min: min, max: max },
											radiusUnits,
											result
										);

										window.mapRiskController.radiusOverlays[result].radii.push({
											radius: {
												min: min,
												max: max,
												poi: radiusPoi
											},
											radiusUnits: radiusUnits,
											overlay: radiusOverlay
										});
									}
								}
							}

							var displayClosestFeaturePoint = false;

							if (window.reportConfigurationModel[result.toLowerCase()]) {
								for (var parametersIndex in window.reportConfigurationModel[
									result.toLowerCase()
								].parameters) {
									if (
										window.reportConfigurationModel[result.toLowerCase()]
											.parameters[parametersIndex].key ===
										"suppressClosestFeaturePoint"
									)
										displayClosestFeaturePoint = !window
											.reportConfigurationModel[result.toLowerCase()]
											.parameters[parametersIndex].value;
								}
							}

							if (
								key === "type" &&
								currentReportResult[index][resultIndex].type === "otherArea"
							) {
								if (window?.mrState?.replacementCost?.otherAreas?.[reportId]) {
									window.mrState.replacementCost.otherAreas[reportId].add({
										name: currentReportResult[index][resultIndex].name,
										squareFootage:
											currentReportResult[index][resultIndex].squareFootage,
										yearBuilt: currentReportResult[index][resultIndex].yearBuilt
									});
								}
							}

							if (window?.mrState?.replacementCost?.otherAreas?.[reportId]) {
								window.mrState.replacementCost.otherAreas[
									reportId
								].showAddTrigger();
							}

							if (!hideKey) {
								if (
									displayClosestFeaturePoint ||
									key.toLowerCase() !== "closestfeaturepoint"
								) {
									var isUnitsField = key.indexOf("Units") > -1;
									var hasUnitsTwin =
										!isUnitsField &&
										currentReportResult[index][resultIndex][key + "Units"]
											? true
											: false;

									if (!isUnitsField) {
										if (!hasUnitsTwin) {
											$additionalInfoRowData.append(
												'<tr data-key="' +
													key +
													'" data-value="' +
													currentReportResult[index][resultIndex][key] +
													'"><td class="colName">' +
													keyDisplayName +
													':</td><td class="colValue">' +
													utils.convertObjectToString(
														currentReportResult[index][resultIndex][key]
													) +
													"</td></tr>"
											);
										} else {
											if (key === "radius") {
												var displayMin;
												var displayMax;
												var displayUnits;
												var displayValue;

												if (
													typeof currentReportResult[index][resultIndex]
														.radius === "object"
												) {
													displayMin = !isNaN(
														currentReportResult[index][resultIndex].radius.min
													)
														? Number(
																currentReportResult[index][resultIndex].radius
																	.min
														  ).toString()
														: Number(0).toString();

													displayMax = !isNaN(
														currentReportResult[index][resultIndex].radius.max
													)
														? Number(
																currentReportResult[index][resultIndex].radius
																	.max
														  ).toString()
														: null;
												} else if (
													typeof currentReportResult[index][resultIndex]
														.radius === "number"
												) {
													displayMax =
														currentReportResult[index][resultIndex].radius;
												}

												displayUnits = utils.convertObjectToString(
													currentReportResult[index][resultIndex][key + "Units"]
												);

												if (displayMin && displayMax) {
													displayValue =
														displayMin +
														" - " +
														displayMax +
														" " +
														displayUnits;
												} else if (displayMin && !displayMax) {
													displayValue = displayMin + "+ " + displayUnits;
												} else if (!displayMin && displayMax) {
													displayValue = displayMax + " " + displayUnits;
												}

												$additionalInfoRowData.append(
													'<tr><td class="colName">' +
														keyDisplayName +
														':</td><td class="colValue">' +
														displayValue +
														"</td></tr>"
												);
											} else {
												$additionalInfoRowData.append(
													'<tr><td class="colName">' +
														keyDisplayName +
														':</td><td class="colValue">' +
														utils.convertObjectToString(
															currentReportResult[index][resultIndex][key]
														) +
														" " +
														utils.convertObjectToString(
															currentReportResult[index][resultIndex][
																key + "Units"
															]
														) +
														"</td></tr>"
												);
											}
										}
									} else {
										if (hasUnitsTwin) {
											$additionalInfoRowData.append(
												'<tr data-key="' +
													index +
													'" data-value="' +
													currentReportResult[index][resultIndex][key] +
													'"><td class="colName">' +
													keyDisplayName +
													':</td><td class="colValue">' +
													utils.convertObjectToString(
														currentReportResult[index][resultIndex][key]
													) +
													"</td></tr>"
											);
										} else {
											continue;
										}
									}
								} else {
									continue;
								}
							}
						}
					}
				}

				continue;
			}

			if (index === "details") {
				if (window.mainDataView) {
					window.mainDataView.setFilterParams(
						reportId,
						window.getReportFilterByFields(reportId, "index"),
						false
					);
				}

				if (currentReportResult[index].length > 0) {
					var foundDataView = false;

					currentReportResult[index] = stripNonInterpolatedValues(
						currentReportResult[index]
					);

					var details = [];
					currentReportResult[index].forEach(function (result) {
						details.push(result);
					});

					details.sort(function (a, b) {
						var poi = new google.maps.LatLng(
							window.currentPoiInfo.poi.lat,
							window.currentPoiInfo.poi.lng
						);

						var aPoi = new google.maps.LatLng(a.latitude, a.longitude);
						var bPoi = new google.maps.LatLng(b.latitude, b.longitude);

						var aDistance = google.maps.geometry.spherical.computeDistanceBetween(
							aPoi,
							poi
						);

						var bDistance = google.maps.geometry.spherical.computeDistanceBetween(
							bPoi,
							poi
						);

						return aDistance - bDistance;
					});

					var indexedReportResult = details.map(function (el, i) {
						var indexedEl = {
							mapriskId: i
						};

						indexedEl = utils.extend.deepmerge(indexedEl, el);
						return indexedEl;
					});

					for (
						var i = 0;
						i < window.mainDataView.state.currentReports.length;
						i++
					) {
						if (
							window.mainDataView.state.currentReports[i].reportId === reportId
						) {
							foundDataView = true;
							window.mainDataView.updateReport(
								reportId,
								indexedReportResult,
								fieldConfiguration
							);
						}
					}

					if (!foundDataView) {
						window.mainDataView.addReport({
							reportContext: reportContext,
							reportConfiguration:
								window.reportConfigurationModel[reportId.toLowerCase()],
							reportId: reportId,
							details: indexedReportResult,
							fieldConfiguration: fieldConfiguration
						});
					}
				} else {
					window.mainDataView.emptyReport(reportId);
				}
			}

			if (index === "error") {
				continue;
			}

			var isUnitsField = index.indexOf("Units") > -1;
			var hasUnitsTwin = !isUnitsField && currentReportResult[index + "Units"];
			var hasValueTwin =
				isUnitsField &&
				currentReportResult[index.substring(0, index.indexOf("Units"))];

			var keyDisplayName = utils.titleify(index);
			var hideKey = false;
			for (var fieldName in fieldConfiguration.fields) {
				if (fieldName.toLowerCase() === index.toLowerCase()) {
					if (fieldConfiguration.fields[fieldName]) {
						keyDisplayName =
							fieldConfiguration.fields[fieldName].displayName ||
							utils.titleify(index);
						hideKey = fieldConfiguration.fields[fieldName].hide || false;
					}
					break;
				}
			}

			if (!hideKey) {
				if (!isUnitsField && hasUnitsTwin) {
					$additionalInfoRowData.append(
						'<tr data-key="' +
							index +
							'" data-value="' +
							currentReportResult[index] +
							'"><td class="colName">' +
							keyDisplayName +
							':</td><td class="colValue">' +
							utils.convertObjectToString(
								currentReportResult[index] +
									" " +
									currentReportResult[index + "Units"]
							) +
							"</td></tr>"
					);
				} else if (
					!isUnitsField &&
					!hasUnitsTwin &&
					index !== "details" &&
					index !== "geojson"
				) {
					$additionalInfoRowData.append(
						'<tr data-key="' +
							index +
							'" data-value="' +
							currentReportResult[index] +
							'"><td class="colName">' +
							keyDisplayName +
							':</td><td class="colValue">' +
							utils.convertObjectToString(currentReportResult[index]) +
							"</td></tr>"
					);
				} else if (isUnitsField && !hasValueTwin) {
					$additionalInfoRowData.append(
						'<tr data-key="' +
							index +
							'" data-value="' +
							currentReportResult[index] +
							'"><td class="colName">' +
							keyDisplayName +
							':</td><td class="colValue">' +
							utils.convertObjectToString(currentReportResult[index]) +
							"</td></tr>"
					);
				} else {
					continue;
				}
			}

			if (index == "closestFeaturePoint") {
				var reportResult = currentReportResult;

				var featPoi = reportResult.closestFeaturePoint;

				featPoi.lat = featPoi.latitude;
				featPoi.lng = featPoi.longitude;

				layerObj = window.mapRiskController.addMarker(
					poi,
					reportResult.distanceToFeature +
						" " +
						reportResult.distanceToFeatureUnits
				);
				window.mapRiskController.addReportOverlay(layerObj);
				reportContext.layers.push(layerObj);

				layerObj = window.mapRiskController.drawLine(poi, featPoi);
				window.mapRiskController.addReportOverlay(layerObj);
				reportContext.layers.push(layerObj);
			}
		}

		window.mapRiskController.drawPoiRadii(result);

		if (
			utils.getReportParameterByName(
				window.reportConfigurationModel,
				reportId,
				"aggregateFeaturesInRadius"
			) != null
		) {
			if (
				window.mapRiskController.map.getZoom() <
				window.mapRiskController.layers[result].clickZoom
			) {
				window.aggregateFeaturesInRadius(result);
			}
		}
	}

	$("#trResultMapTableRow").click();
}

function checkInputFormFieldsForReport(
	reportConfig,
	reportParameters,
	reportResults
) {
	var reportDiv = "#div" + reportConfig.reportId + "_index";

	var $additionalInfoNode = $(reportDiv);

	// return early if there is no specific input div for a given report
	if (!$additionalInfoNode) {
		return;
	}

	// check first for any report parameters which should populate the sidebar input elements
	for (var paramKey in reportParameters) {
		populateInputFormFieldsForReport(
			paramKey,
			reportParameters[paramKey],
			$additionalInfoNode
		);
	}
	// then check for any sidebar elements which should be overridden by report results
	reportConfig.resultFields.forEach(function (
		resultField,
		index,
		resultFields
	) {
		if (resultField.overrideInputFormWithResults) {
			var resultFieldName = resultFields[index].fieldName;
			var resultFieldGroup = resultFields[index].group;

			if (resultFieldGroup === "result") {
				var inputFieldValue = reportResults[resultFieldName];
				var inputFormFieldName = resultField.inputFormFieldName;
				populateInputFormFieldsForReport(
					inputFormFieldName,
					inputFieldValue,
					$additionalInfoNode
				);
			}
		}
	});
}

function populateInputFormFieldsForReport(
	inputFormFieldName,
	inputFieldValue,
	$additionalInfoNode
) {
	var $additionalInfoNodeInput;
	var node;
	var nodeType;

	$additionalInfoNodeInput = $additionalInfoNode.find(
		"*[name='" + inputFormFieldName + "']"
	);

	$additionalInfoNodeInput.each(function () {
		node = this.tagName.toLowerCase();
		nodeType = this.attributes.type ? this.attributes.type.value : null;

		if (node === "select") {
			$(this)
				.find("option[value='" + inputFieldValue + "']")
				.attr("selected", "selected");
			$(this).trigger("change");
		} else if (node === "textarea") {
			$(this).val(inputFieldValue);
			$(this).trigger("change");
		} else if (node === "input") {
			if (nodeType === "checkbox" || nodeType === "radio") {
				if (inputFieldValue === true || inputFieldValue === "true") {
					$(this).prop("checked", inputFieldValue);
					$(this).trigger("change");
				}
			} else {
				// assume any other non-radio, non-checkbox input type (text, email, phone, number, etc)
				$(this).val(inputFieldValue);

				if ($(this).attr("data-type") === "currency") {
					formatAsCurrency(this, true, "$");
				}
			}
		}
	});
}

function drawPolygon(reportContext, polygon, layerObj) {
	if (
		polygon[0].longitude == null &&
		polygon[polygon.length - 1].longitude != null
	)
		polygon[0].longitude = polygon[polygon.length - 1].longitude;

	if (
		polygon[0].latitude == null &&
		polygon[polygon.length - 1].latitude != null
	)
		polygon[0].latitude = polygon[polygon.length - 1].latitude;

	layerObj = window.mapRiskController.drawPolygon(polygon);
	reportContext.layers.push(layerObj);
}

function determineRequestSettings() {
	var settings = {};
	var urlLinkConfig = {};

	if (window.transactionId) {
		if (
			window.accountConfigurationModel &&
			window.accountConfigurationModel.runtimeConfiguration &&
			window.accountConfigurationModel.runtimeConfiguration.urlLink &&
			window.accountConfigurationModel.runtimeConfiguration.urlLink
		) {
			urlLinkConfig =
				window.accountConfigurationModel.runtimeConfiguration.urlLink;
		}

		if (
			urlLinkConfig.mutateTransactionOnReportReRun == null ||
			typeof urlLinkConfig.mutateTransactionOnReportReRun === "undefined"
		) {
			settings.mutateTransactionOnReportReRun = false;
		} else {
			settings.mutateTransactionOnReportReRun =
				urlLinkConfig.mutateTransactionOnReportReRun;
		}
	} else {
		settings = undefined;
	}

	return settings;
}

window.getReportFilterByFields = function (reportId, viewId) {
	var filterByFields = {};

	if (window?.mrState?.mrMultiselect?.[reportId + "_" + viewId]?.keys) {
		var keys = window.mrState.mrMultiselect[reportId + "_" + viewId].keys || {};

		for (var key in keys) {
			var values = Object.keys(keys[key]);

			values.forEach(function (el, index) {
				values[index] = el.toLowerCase();
			});

			filterByFields[key] = values;
		}
	}

	return filterByFields;
};

function setGetReportButtonState(reportId, loadingText, disabled) {
	$('.get-report-btn[data-reportid="' + reportId + '"]')
		.html(loadingText)
		.attr("disabled", disabled);
}

function setRerunReportButtonState(reportId, loadingText, disabled) {
	$('.rerun-report-btn[data-reportid="' + reportId + '"]')
		.html(loadingText)
		.attr("disabled", disabled);
}

function invokeReportRequest(geoResult, poi, reportId, reportData, layerObj) {
	setGetReportButtonState(reportId, "Loading...", true);
	setRerunReportButtonState(reportId, "Loading...", true);

	let loaderId = `report-request-loading-overlay_${reportId}`;
	if (!window.mrState.loadingOverlays[loaderId]) {
		window.mrState.loadingOverlays[loaderId] = new LoadingOverlay({
			parentContainer:
				"#divAdditionalReportInformation_" + reportId + "_reportTableRow",
			id: loaderId
		});
	}
	window.mrState.loadingOverlays[loaderId].enable();

	if (window.mrState.dataLayers && window.mrState.dataLayers[reportId]) {
		for (var layer in window.mrState.dataLayers[reportId]) {
			window.mrState.dataLayers[reportId][layer].deactivate();
			delete window.mrState.dataLayers[reportId][layer];
		}
	}

	if (
		utils?.getReportParameterByName(
			window.reportConfigurationModel,
			reportId,
			"filter"
		)
	) {
		window.mrState.layerMultiselects[reportId].refreshLayers();
	}

	var data = {
		poi: geoResult,
		reportList: reportId,
		settings: determineRequestSettings()
	};

	var transactionId = null;

	if (
		window.transactionModel &&
		window.transactionModel.request &&
		window.transactionModel.request.transactionId
	) {
		transactionId = window.transactionModel.request.transactionId;
	} else if (
		window.reportsModel[reportId] &&
		window.reportsModel[reportId].transactionId
	) {
		transactionId = window.reportsModel[reportId].transactionId;
	} else if (
		window.geocodeModel &&
		window.geocodeModel.request &&
		window.geocodeModel.request.transactionId
	) {
		transactionId = window.geocodeModel.request.transactionId;
	}

	if (window.mapRiskController.skipGeocodeOnNextSearch) {
		data.poi.lat = window.mapRiskController.currentPoi.lat;
		data.poi.latitude = window.mapRiskController.currentPoi.lat;
		data.poi.lng = window.mapRiskController.currentPoi.lng;
		data.poi.longitude = window.mapRiskController.currentPoi.lng;
		data.transactionId = transactionId;
	} else if (window.reportsModel?.geocode?.report?.geocode) {
		data.poi = window.reportsModel.geocode.report.geocode;
	} else if (window.reportsModel?.geocode?.report) {
		data.poi = window.reportsModel.geocode.report;
	}

	data.transactionId = transactionId;
	data[reportId] = reportData;

	var getReportCallback = function (err, getReportsResponse) {
		if (err) {
			dialog.openGeneralDialog(
				null,
				new dialog.DialogOptions(
					err?.statusText,
					err?.responseJSON?.error?.message
				)
			);
			window.addressSearch.enableSearchButton();

			return;
		}

		for (var i = 0; i < getReportsResponse.request.reportList.length; i++) {
			var responseReportId = getReportsResponse.request.reportList[i].reportId;

			if (getReportsResponse.response.reportResults[responseReportId].error) {
				continue;
			}

			window.reportsModel[responseReportId] = {
				report: getReportsResponse.response.reportResults[responseReportId],
				transactionId: getReportsResponse.request.transactionId,
				urlLink: getReportsResponse.response.urlLink
			};
		}

		// Live update the credits remaining
		$("#spanCreditsRemaining").html(
			" " + getReportsResponse.response.creditsRemaining
		);

		onCreditsUpdated(getReportsResponse.response.creditsRemaining);

		if (
			window.mrState &&
			window.mrState.mrClickableOverrides &&
			window.mrState.mrClickableOverrides[reportId]
		) {
			window.mrState.mrClickableOverrides[reportId].clearFeatures();
			window.mrState.mrClickableOverrides[reportId].depopulateFeatures();
		}

		appendReportResult(
			geoResult,
			getReportsResponse,
			reportId,
			window.reportRequestList[reportId],
			poi,
			layerObj
		);

		window.mrState.loadingOverlays[
			`report-request-loading-overlay_${reportId}`
		].disable();
		window.addressSearch.enableSearchButton();
		setGetReportButtonState(reportId, "Get Report", false);

		if (disableReportReRunIfViewOnly() === false) {
			setRerunReportButtonState(reportId, "Rerun Report", false);
		}
	};

	var reportsClient = new ReportsClient();

	reportsClient.getReports(data, getReportCallback);
}

function setReportRequestData(report) {
	var reportType;

	var reportData = {};
	var reportTemplate =
		window.reportConfigurationModel[report.toLowerCase()].reportTemplate;
	var reportParameters =
		window.reportConfigurationModel[report.toLowerCase()].parameters;

	for (var reportParameter in reportParameters) {
		if (
			reportParameters[reportParameter].key === "setReportDataByTemplate" &&
			reportParameters[reportParameter].value
		)
			reportType = reportTemplate;
	}

	if (!reportType) {
		reportType = report;
	}

	if (reportType.toLowerCase().indexOf("dssextended") !== -1) {
		var coverageA = $("#txtDssExtendedCoverageA_index").val();
		var coverageB = $("#txtDssExtendedCoverageB_index").val();
		var coverageC = $("#txtDssExtendedCoverageC_index").val();
		var coverageALimit = $("#txtDssExtendedCoverageALimit_index").val();
		var coverageBLimit = $("#txtDssExtendedCoverageBLimit_index").val();
		var coverageCLimit = $("#txtDssExtendedCoverageCLimit_index").val();

		var constructionType = $("#selDssExtendedConstructionType_index").val();
		var constructionScheme = $("#selDssExtendedConstructionScheme_index").val();
		var cladding = $("#selDssExtendedCladding_index").val();

		var deductible = $("#txtDssExtendedDeductible_index").val();
		var constructionYear = $("#txtDssExtendedConstructionYear_index").val();
		var constructionYearRoof = $(
			"#txtDssExtendedConstructionRoofYear_index"
		).val();
		var roofGeometry = $("#selRoofGeometry_index").val();
		var premium = $("#txtDssExtendedPremium_index").val();
		var tiv = $("#txtDssExtendedTIV_index").val();

		reportData = {
			coverageA: coverageA,
			coverageALimit: coverageALimit,
			coverageB: coverageB,
			coverageBLimit: coverageBLimit,
			coverageC: coverageC,
			coverageCLimit: coverageCLimit,
			cladding: cladding,
			constructionScheme: constructionScheme,
			constructionType: constructionType,
			deductibleAmount: deductible,
			constructionYear: constructionYear,
			constructionYearRoof: constructionYearRoof,
			premium: premium,
			totalAccountPremium: premium,
			totalPremium: premium,
			tiv: tiv,
			roofGeometryType: roofGeometry
		};
	} else if (reportType.toLowerCase().indexOf("dss") !== -1) {
		var coverageA = $("#txtDssCoverageA_index").val();
		var coverageC = $("#txtDssCoverageC_index").val();
		var deductible = $("#txtDssDeductible_index").val();
		var constructionYear = $("#txtDssConstructionYear_index").val();
		var constructionYearRoof = $("#txtDssConstructionRoofYear_index").val();
		var roofGeometry = $("#txtDssRoofGeometry_index").val();
		var premium = $("#txtDssPremium_index").val();
		var tiv = $("#txtDssTIV_index").val();

		reportData = {
			coverageA: coverageA,
			coverageALimit: coverageA,
			coverageC: coverageC,
			coverageCLimit: coverageC,
			deductibleAmount: deductible,
			constructionYear: constructionYear,
			constructionYearRoof: constructionYearRoof,
			premium: premium,
			totalAccountPremium: premium,
			totalPremium: premium,
			tiv: tiv,
			roofGeometryType: roofGeometry
		};
	} else if (
		reportType
			.toLowerCase()
			.indexOf("replacementcostmobilemanufacturedhomes") !== -1
	) {
		var sqFootage = $(`#div${reportType}_index`)
			.find('.model-input[name="sqFootage"]')
			.val();
		var yearBuilt = $(`#div${reportType}_index`)
			.find('.model-input[name="yearBuilt"]')
			.val();
		var locale = $(`#div${reportType}_index`)
			.find('.model-input[name="locale"]')
			.val();
		var coverageA = $(`#div${reportType}_index`)
			.find('.model-input[name="coverageA"]')
			.val();
		var manufacturedHome = $(`#div${reportType}_index`)
			.find('.model-input[name="manufacturedHome"]')
			.val();
		var architecturalStyle = $(`#div${reportType}_index`)
			.find('.model-input[name="architecturalStyle"]')
			.val();
		var constructionQuality = $(`#div${reportType}_index`)
			.find('.model-input[name="constructionQuality"]')
			.val();
		var skirting = $(`#div${reportType}_index`)
			.find('.model-input[name="skirting"]')
			.val();
		var constructionType = $(`#div${reportType}_index`)
			.find('.model-input[name="constructionType"]')
			.val();
		var recentRenovations = $(`#div${reportType}_index`)
			.find('.model-input[name="recentRenovations"]')
			.val();
		var historicRegistry = $(`#div${reportType}_index`)
			.find('.model-input[name="historicRegistry"]')
			.val();
		var primaryExterior = $(`#div${reportType}_index`)
			.find('.model-input[name="primaryExterior"]')
			.val();
		var primaryRoofCovering = $(`#div${reportType}_index`)
			.find('.model-input[name="primaryRoofCovering"]')
			.val();
		var replacementCostType = $(`#div${reportType}_index`)
			.find('.model-input[name="replacementCostType"]')
			.val();
		var returnACV = $(`#div${reportType}_index`)
			.find('.model-input[name="returnACV"]')
			.val();
		var roofConfiguration = $(`#div${reportType}_index`)
			.find('.model-input[name="roofConfiguration"]')
			.val();
		var foundationType = $(`#div${reportType}_index`)
			.find('.model-input[name="foundationType"]')
			.val();
		var slopeOfSite = $(`#div${reportType}_index`)
			.find('.model-input[name="slopeOfSite"]')
			.val();
		var numberOfStories = $(`#div${reportType}_index`)
			.find('.model-input[name="numberOfStories"]')
			.val();
		var debrisRemoval = $(`#div${reportType}_index`)
			.find('.model-input[name="debrisRemoval"]')
			.val();

		reportData = {
			sqFootage: sqFootage,
			yearBuilt: yearBuilt,
			locale: locale,
			coverageA: coverageA,
			manufacturedHome: manufacturedHome,
			architecturalStyle: architecturalStyle,
			constructionQuality: constructionQuality,
			skirting: skirting,
			constructionType: constructionType,
			recentRenovations: recentRenovations,
			historicRegistry: historicRegistry,
			primaryExterior: primaryExterior,
			primaryRoofCovering: primaryRoofCovering,
			replacementCostType: replacementCostType,
			returnACV: returnACV,
			roofConfiguration: roofConfiguration,
			foundationType: foundationType,
			slopeOfSite: slopeOfSite,
			numberOfStories: numberOfStories,
			debrisRemoval: debrisRemoval
		};
		if (returnACV === "yes") {
			reportData.structureInUse = $(`#div${reportType}_index`)
				.find('.model-input[name="structureInUse"]')
				.val();
			reportData.generalCondition = $(`#div${reportType}_index`)
				.find('.model-input[name="generalCondition"]')
				.val();
			reportData.roofCondition = $(`#div${reportType}_index`)
				.find('.model-input[name="roofCondition"]')
				.val();
			reportData.wallCondition = $(`#div${reportType}_index`)
				.find('.model-input[name="wallCondition"]')
				.val();
			reportData.foundationCondition = $(`#div${reportType}_index`)
				.find('.model-input[name="foundationCondition"]')
				.val();
		}

		if (
			$(
				".divreplacementCostMobileManufacturedHomes .additionalInfo_index .other-area-container"
			).length > 0
		) {
			reportData.otherAreas = [];
			$(
				".divreplacementCostMobileManufacturedHomes .additionalInfo_index .other-area-container"
			).each(function () {
				reportData.otherAreas.push({
					name: $(this)
						.find('.other-area-name-select[name="otherAreas_name"]')
						.val(),
					squareFootage: $(this)
						.find('.other-area-sqft-input[name="otherAreas_squareFootage"]')
						.val(),
					yearBuilt: $(this)
						.find('.other-area-year-built-input[name="otherAreas_yearBuilt"]')
						.val()
				});
			});
		}
	} else if (
		reportType.toLowerCase().indexOf("replacementcostadditionalstructures") !==
		-1
	) {
		if ($(".additional-structure-container").length > 0) {
			reportData.additionalStructures = [];

			$(".additional-structure-container").each(function () {
				reportData.additionalStructures.push({
					structureType: $(this)
						.find(".additional-structure_type-select")
						.val(),
					squareFootage: $(this)
						.find(".additional-structure-square_footage-input")
						.val(),
					yearBuilt: $(this)
						.find(".additional-structure-year_built-input")
						.val(),
					constructionType: $(this)
						.find(".additional-structure-construction_type-select")
						.val(),
					primaryRoofCovering: $(this)
						.find(".additional-structure-primary_roof_covering-select")
						.val(),
					primaryExterior: $(this)
						.find(".additional-structure-primary_exterior-select")
						.val(),
					constructionQuality: $(this)
						.find(".additional-structure-construction_quality-select")
						.val(),
					structureCondition: $(this)
						.find(".additional-structure-structure_condition-select")
						.val(),
					replacementCostType: $(this)
						.find(".additional-structure-replacement_cost_type-select")
						.val(),
					returnACV: $(this)
						.find(".additional-structure-return_acv-select")
						.val(),
					returnACVGeneralCondition: $(this)
						.find(".additional-structure-return_acv_general_condition-select")
						.val(),
					returnACVRoofCondition: $(this)
						.find(".additional-structure-return_acv_roof_condition-select")
						.val(),
					returnACVWallCondition: $(this)
						.find(".additional-structure-return_acv_wall_condition-select")
						.val(),
					returnACVFoundationCondition: $(this)
						.find(
							".additional-structure-return_acv_foundation_condition-select"
						)
						.val(),
					returnACVStructureInUse: $(this)
						.find(".additional-structure-return_acv_structure_in_use-select")
						.val()
				});
			});
		} else if ($(".additional-structure-container").length <= 0) {
			reportData.additionalStructures = [];
		} else if (window?.reportsModel?.[report]?.report?.features) {
			reportData.additionalStructures = window.reportsModel[
				report
			].report.features
				.filter(function (feature) {
					return (
						feature.type && feature.type.toLowerCase() === "additionalstructure"
					);
				})
				.map(function (additionalStructure) {
					return {
						structureType: additionalStructure.name,
						squareFootage: additionalStructure.squareFootage,
						yearBuilt: additionalStructure.yearBuilt,
						constructionType: additionalStructure.constructionType,
						primaryRoofCovering: additionalStructure.primaryRoofCovering,
						primaryExterior: additionalStructure.primaryExterior,
						constructionQuality: additionalStructure.constructionQuality,
						structureCondition: additionalStructure.structureCondition,
						replacementCostType: additionalStructure.replacementCostType,
						returnACV: additionalStructure.returnACV
					};
				});
		}
	} else if (reportType.toLowerCase().indexOf("replacementcost") !== -1) {
		let $occupancyType = $("#replacementCost_occupancyType_index");
		let occupancyType = $occupancyType.val();
		let $productWrapper = $(
			"#div" +
				reportType +
				'_index .product-wrapper[data-occupancy-type="' +
				occupancyType.toLowerCase() +
				'"]'
		);

		let architecturalStyle = $productWrapper
			.find('.model-input[name="architecturalStyle"]')
			.val();
		let constructionType = $productWrapper
			.find('.model-input[name="constructionType"]')
			.val();
		let constructionQuality = $productWrapper
			.find('.model-input[name="constructionQuality"]')
			.val();
		let exterior = $productWrapper.find('.model-input[name="exterior"]').val();
		let roofCovering = $productWrapper
			.find('.model-input[name="roofCovering"]')
			.val();
		let sqFootage = $productWrapper
			.find('.model-input[name="sqFootage"]')
			.val();
		let yearBuilt = $productWrapper
			.find('.model-input[name="yearBuilt"]')
			.val();

		let roofCoveringDefaultChangeParamValue = utils.getReportParameterByName(
			window.reportConfigurationModel,
			report,
			"roofCoveringDefaultChange"
		);

		let tiledRoofCoverings = {
			["barrel tile"]: 0,
			["clay tile"]: 1,
			["concrete tile"]: 2,
			["glazed tile"]: 3,
			["ludowici tile"]: 4,
			["spanish tile"]: 5,
			["tile with solar panels"]: 6
		};

		if (
			window.currentPoiInfo?.poi?.state == "SC" &&
			roofCoveringDefaultChangeParamValue &&
			isRoofCoveringTile(tiledRoofCoverings, roofCovering) > -1
		) {
			roofCovering = "architectural shingle";
		}

		reportData = {
			reportConfiguration: occupancyType,
			architecturalStyle: architecturalStyle,
			constructionType: constructionType,
			constructionQuality: constructionQuality,
			exterior: exterior,
			roofCovering: roofCovering,
			sqFootage: sqFootage,
			yearBuilt: yearBuilt
		};

		let defaultConfigurationParamValue = utils.getReportParameterByName(
			window.reportConfigurationModel,
			report,
			"DefaultConfiguration"
		);
		let isPro =
			(defaultConfigurationParamValue &&
				defaultConfigurationParamValue.toLowerCase().indexOf("pro") > -1) ||
			false;

		if (isPro) {
			reportData.firstName = $productWrapper
				.find('.model-input[name="firstName"]')
				.val();
			reportData.middleInitial = $productWrapper
				.find('.model-input[name="middleInitial"]')
				.val();
			reportData.lastName = $productWrapper
				.find('.model-input[name="lastName"]')
				.val();
			reportData.isBusiness = $productWrapper
				.find('.model-input[name="isBusiness"]')
				.val();
			reportData.coverageA = $productWrapper
				.find('.model-input[name="coverageA"]')
				.val();
			reportData.coverageAOrigin = $productWrapper
				.find('.model-input[name="coverageAOrigin"]')
				.val();
			reportData.clientPolicyCarrier = $productWrapper
				.find('.model-input[name="clientPolicyCarrier"]')
				.val();
			reportData.clientPolicyNumber = $productWrapper
				.find('.model-input[name="clientPolicyNumber"]')
				.val();
			reportData.clientPolicyEffDate = $productWrapper
				.find('.model-input[name="clientPolicyEffDate"]')
				.val();
			reportData.clientPolicyAgencyName = $productWrapper
				.find('.model-input[name="clientPolicyAgencyName"]')
				.val();
			reportData.clientAgencyAddress = $productWrapper
				.find('.model-input[name="clientAgencyAddress"]')
				.val();
			reportData.clientAgencyAddress2 = $productWrapper
				.find('.model-input[name="clientAgencyAddress2"]')
				.val();
			reportData.clientAgencyCity = $productWrapper
				.find('.model-input[name="clientAgencyCity"]')
				.val();
			reportData.clientAgencyState = $productWrapper
				.find('.model-input[name="clientAgencyState"]')
				.val();
			reportData.clientAgencyZip = $productWrapper
				.find('.model-input[name="clientAgencyZip"]')
				.val();
			reportData.clientAgencyPhone = $productWrapper
				.find('.model-input[name="clientAgencyPhone"]')
				.val();
			reportData.clientAgencyCode = $productWrapper
				.find('.model-input[name="clientAgencyCode"]')
				.val();
			reportData.clientPolicyAgent = $productWrapper
				.find('.model-input[name="clientPolicyAgent"]')
				.val();
			reportData.clientRequestor = $productWrapper
				.find('.model-input[name="clientRequestor"]')
				.val();
			reportData.clientPersonInterviewed = $productWrapper
				.find('.model-input[name="clientPersonInterviewed"]')
				.val();
			reportData.clientInterviewDate = $productWrapper
				.find('.model-input[name="clientInterviewDate"]')
				.val();
			reportData.clientInspectedBy = $productWrapper
				.find('.model-input[name="clientInspectedBy"]')
				.val();
			reportData.slopeOfSite = $productWrapper
				.find('.model-input[name="slopeOfSite"]')
				.val();
			reportData.debrisRemoval = $productWrapper
				.find('.model-input[name="debrisRemoval"]')
				.val();
			reportData.constructionQuality = $productWrapper
				.find('.model-input[name="constructionQuality"]')
				.val();
			reportData.roofType = $productWrapper
				.find('.model-input[name="roofType"]')
				.val();
			reportData.roofPitch = $productWrapper
				.find('.model-input[name="roofPitch"]')
				.val();
			reportData.calculateAcv = $productWrapper
				.find('.model-input[name="calculateAcv"]')
				.val();
			reportData.structureInUse = $productWrapper
				.find('.model-input[name="structureInUse"]')
				.val();
			reportData.generalCondition = $productWrapper
				.find('.model-input[name="generalCondition"]')
				.val();
			reportData.foundationCondition = $productWrapper
				.find('.model-input[name="foundationCondition"]')
				.val();
			reportData.roofCondition = $productWrapper
				.find('.model-input[name="roofCondition"]')
				.val();
			reportData.exteriorWallCondition = $productWrapper
				.find('.model-input[name="exteriorWallCondition"]')
				.val();
			reportData.locale = $productWrapper
				.find('.model-input[name="locale"]')
				.val();
			reportData.foundationType = $productWrapper
				.find('.model-input[name="foundationType"]')
				.val();
			reportData.physicalShape = $productWrapper
				.find('.model-input[name="physicalShape"]')
				.val();
			reportData.exterior = $productWrapper
				.find('.model-input[name="exterior"]')
				.val();

			if (
				window.reportsModel &&
				window.reportsModel[report] &&
				window.reportsModel[report].report
			) {
				reportData.propertyId = window.reportsModel[report].report.propertyId;

				if (
					!reportData.sqFootage &&
					window.reportsModel[report].report.livingAreaSquareFeet
				) {
					reportData.sqFootage =
						window.reportsModel[report].report.livingAreaSquareFeet;
				}

				if (
					!reportData.yearBuilt &&
					window.reportsModel[report].report.yearBuilt
				) {
					reportData.yearBuilt = window.reportsModel[report].report.yearBuilt;
				}

				if (
					window.reportsModel[report].report.features &&
					window.reportsModel[report].report.features.length
				) {
					if ($(".other-area-container").length > 0) {
						reportData.otherAreas = [];

						$(".other-area-container").each(function () {
							reportData.otherAreas.push({
								name: $(this).find(".other-area-name-select").val(),
								squareFootage: $(this).find(".other-area-sqft-input").val(),
								yearBuilt: $(this).find(".other-area-year-built-input").val()
							});
						});
					} else if ($(".other-area-container").length <= 0) {
						reportData.propertyId =
							window.reportsModel[report].report.propertyId;
						reportData.otherAreas = [];
					} else {
						reportData.otherAreas = window.reportsModel[report].report.features
							.filter(function (feature) {
								return (
									feature.type && feature.type.toLowerCase() === "otherarea"
								);
							})
							.map(function (otherArea) {
								return {
									name: otherArea.name,
									squareFootage: otherArea.squareFootage,
									yearBuilt: otherArea.yearBuilt
								};
							});
					}
				} else {
					if ($(".other-area-container").length > 0) {
						reportData.otherAreas = [];

						$(".other-area-container").each(function () {
							reportData.otherAreas.push({
								name: $(this).find(".other-area-name-select").val(),
								squareFootage: $(this).find(".other-area-sqft-input").val(),
								yearBuilt: $(this).find(".other-area-year-built-input").val()
							});
						});
					}
				}
			}
		} else {
			reportData.constructionQuality = $productWrapper
				.find('.model-input[name="constructionQuality"]')
				.val();

			reportData.returnACV = $productWrapper
				.find('.model-input[name="returnACV"]')
				.val();
			if (reportData.returnACV === "yes") {
				reportData.structureInUse = $productWrapper
					.find('.model-input[name="structureInUse"]')
					.val();
				reportData.generalCondition = $productWrapper
					.find('.model-input[name="generalCondition"]')
					.val();
				reportData.roofCondition = $productWrapper
					.find('.model-input[name="roofCondition"]')
					.val();
				reportData.wallCondition = $productWrapper
					.find('.model-input[name="wallCondition"]')
					.val();
				reportData.foundationCondition = $productWrapper
					.find('.model-input[name="foundationCondition"]')
					.val();
			}
		}
	} else if (reportType.toLowerCase().indexOf("wildfireriskscore") !== -1) {
		var wildfireRiskScoreTIV = $("#txtWildfireRiskScoreTIV_index").val();

		reportData = {
			tiv: wildfireRiskScoreTIV
		};
	} else if (reportType.toLowerCase().indexOf("inspection") !== -1) {
		var occupancyType = $("#selInspection_OccupancyType_index").val();
		var policyNumber = $("#txtInspection_PolicyNumber_index").val();
		var policyType = $("#txtInspection_PolicyType_index").val();
		var insuredName = $("#txtInspection_InsuredName_index").val();
		var inspectionDate = $("#txtInspection_InspectionDate_index").val();
		var architecturalStyle = $(
			"#selInspection_ArchitecturalStyle_index_" + occupancyType
		).val();

		reportData = {
			occupancyType: occupancyType,
			policyNumber: policyNumber,
			policyType: policyType,
			insuredName: insuredName,
			inspectionDate: inspectionDate,
			architecturalStyle: architecturalStyle
		};
	} else if (reportType.toLowerCase().indexOf("claimsdocumentation") !== -1) {
		var claimsDocumentationValues = $(".txt_claimsDocumentation_index");

		reportData = {};
		for (var claimsDocumentationValIndex in claimsDocumentationValues) {
			var currenClaimsDocumentationVal =
				claimsDocumentationValues[claimsDocumentationValIndex];

			reportData[currenClaimsDocumentationVal.name] =
				currenClaimsDocumentationVal.value;
		}
	} else if (
		reportType.toLowerCase().indexOf("katriskaal") !== -1 ||
		reportType.toLowerCase().indexOf("katriskwindaal") !== -1
	) {
		reportData.buildingValue = $(
			"#buildingValueInput_" + report + "_index"
		).val();
		reportData.buildingDeductible = $(
			"#buildingDeductibleInput_" + report + "_index"
		).val();
		reportData.buildingLimit = $(
			"#buildingLimitInput_" + report + "_index"
		).val();
		reportData.contentsValue = $(
			"#contentsValueInput_" + report + "_index"
		).val();
		reportData.contentsDeductible = $(
			"#contentsDeductibleInput_" + report + "_index"
		).val();
		reportData.contentsLimit = $(
			"#contentsLimitInput_" + report + "_index"
		).val();
		reportData.timeValue = $("#timeValueInput_" + report + "_index").val();
		reportData.timeDeductible = $(
			"#timeDeductibleInput_" + report + "_index"
		).val();
		reportData.timeLimit = $("#timeLimitInput_" + report + "_index").val();
		reportData.siteDeductible = $(
			"#siteDeductibleInput_" + report + "_index"
		).val();
		reportData.siteLimit = $("#siteLimitInput_" + report + "_index").val();
		reportData.yearBuilt = $("#yearBuilt_" + report + "_index").val();
		reportData.buildingVulnerabilityGroup = $(
			"#buildingVulnerabilityGroup_" + report + "_index"
		).val();
		reportData.contentsVulnerabilityGroup = $(
			"#contentsVulnerabilityGroup_" + report + "_index"
		).val();
		reportData.timeVulnerabilityGroup = $(
			"#timeVulnerabilityGroup_" + report + "_index"
		).val();
		reportData.occupancyType = $(
			"#occupancyTypeSelect_" + report + "_index"
		).val();
		reportData.constructionType = $(
			"#constructionTypeSelect_" + report + "_index"
		).val();
		reportData.numberOfStories = $(
			"#numStoriesInput_" + report + "_index"
		).val();
		reportData.firstFloorElevation = $(
			"#firstFloorElevationInput_" + report + "_index"
		).val();
		reportData.hasBasement = $("#basementSelect_" + report + "_index").val();
	} else if (reportType.toLowerCase().indexOf("aggregateexposure") !== -1) {
		reportData.filterByFields = utils.extend.mergeObject(
			window.mainDataView.getFilterParams(report),
			window.getReportFilterByFields(report, "index")
		);

		for (let filter in reportData.filterByFields) {
			if (!reportData.filterByFields[filter]) {
				delete reportData.filterByFields[filter];
			}
		}
	} else if (reportType.toLowerCase().indexOf("hailuwguidelines") !== -1) {
		var hailUwGuidelinesRoofAge = $("#txtHailUwGuidelinesRoofAge_index").val();
		var hailUwGuidelinesRoofSqFeet = $(
			"#txtHailUwGuidelinesRoofSqFeet_index"
		).val();
		var hailUwGuidelinesDate = $("#txtHailUwGuidelinesDate_index").val();

		hailUwGuidelinesRoofAge =
			hailUwGuidelinesRoofAge.trim().length > 0 ? hailUwGuidelinesRoofAge : -1;
		hailUwGuidelinesRoofSqFeet =
			hailUwGuidelinesRoofSqFeet.trim().length > 0
				? hailUwGuidelinesRoofSqFeet
				: -1;

		reportData.roofAge = hailUwGuidelinesRoofAge;
		reportData.roofSqFeet = hailUwGuidelinesRoofSqFeet;
		reportData.date = hailUwGuidelinesDate;
	} else if (reportType.toLowerCase().indexOf("windhailstrike") !== -1) {
		var windStartDate = $("#txtWindStartDate_index").val();
		var windEndDate = $("#txtWindEndDate_index").val();
		reportData.windStartDate = windStartDate;
		reportData.windEndDate = windEndDate;
	} else if (
		reportType.toLowerCase().indexOf("hailunderwritingguidelinesextended") !==
		-1
	) {
		reportData.fiveYearLossExperience =
			$("#chk-hanoverHail-fiveYearLossExperience_index").is(":checked") ||
			false;
		reportData.numberOfHailLosses =
			$("#txt-hanoverHail-numberOfHailLosses_index").val() || null;
		reportData.isoSurveyComplete =
			$("#chk-hanoverHail-isoSurveyComplete_index").is(":checked") || false;
		reportData.roofAge = $("#txt-hanoverHail-roofAge_index").val() || null;
		reportData.roofMaterial =
			$("#sel-hanoverHail-roofMaterial_index").val() || null;
		reportData.roofSquareFootage =
			$("#txt-hanoverHail-roofSquareFootage_index").val() || null;
		reportData.rooftopHVACHailGuards =
			$("#chk-hanoverHail-rooftopHVACHailGuards_index").is(":checked") || false;
		reportData.numberOfHVACUnits =
			$("#txt-hanoverHail-numberOfHVACUnits_index").val() || null;
		reportData.eifsWallConstruction =
			$("#chk-hanoverHail-eifsWallConstruction_index").is(":checked") || false;
		reportData.numberOfSkylights =
			$("#txt-hanoverHail-numberOfSkylights_index").val() || null;
		reportData.hailImpactRatedSkylights =
			$("#chk-hanoverHail-hailImpactRatedSkylights_index").is(":checked") ||
			false;
		reportData.hasAirSupportedStructures =
			$("#chk-hanoverHail-hasAirSupportedStructures_index").is(":checked") ||
			false;
		reportData.hasSolarPanels =
			$("#chk-hanoverHail-hasSolarPanels_index").is(":checked") || false;
		reportData.hasGreenhouse =
			$("#chk-hanoverHail-hasGreenhouse_index").is(":checked") || false;
		reportData.notes = $("#txt-hanoverHail-notes_index").val() || null;
	} else if (
		reportType.toLowerCase() === "hailverification" ||
		reportType.toLowerCase() === "hailverificationhs" ||
		reportType.toLowerCase().indexOf("hailapi") !== -1 ||
		reportType.toLowerCase().indexOf("hailstrikeapi") !== -1
	) {
		reportData.startDate = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-date-start_index"
		).value;
		reportData.endDate = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-date-end_index"
		).value;
		reportData.minimumDHI = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-dhi_index"
		).value;
		reportData.minimumSize = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-size_index"
		).value;
		reportData.claimNumber = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-claim-number_index"
		).value;
	} else if (
		[
			"hailcanopyverification",
			"hailsizeanddamage",
			"hailverificationc",
			"distancetohighhailcanopysize"
		].indexOf(reportType.toLowerCase()) !== -1
	) {
		var convectiveDate = moment(
			document.querySelector(
				"#div" +
					report +
					"_index #model-input-" +
					report +
					"-convective-date_index"
			).value
		);
		var buffer = document.querySelector(
			"#div" +
				report +
				"_index #model-input-" +
				report +
				"-convective-date-buffer_index"
		).value;
		var claimNumber = document.querySelector(
			"#div" + report + "_index #model-input-" + report + "-claim-number_index"
		).value;

		reportData.convectiveDate = moment(convectiveDate).format("YYYY-MM-DD");
		reportData.buffer = buffer;
		reportData.claimNumber = claimNumber;
	} else if (
		["hailcanopystormfootprints", "hailstormfootprints"].indexOf(
			reportType.toLowerCase()
		) !== -1
	) {
		var convectiveDate = moment(
			document.querySelector(
				"#div" +
					report +
					"_index #model-input-" +
					report +
					"-convective-date_index"
			).value
		);
		var buffer = document.querySelector(
			"#div" +
				report +
				"_index #model-input-" +
				report +
				"-convective-date-buffer_index"
		).value;

		reportData.convectiveDate = moment(convectiveDate).format("YYYY-MM-DD");
		reportData.buffer = buffer;
	} else if (
		reportType.toLowerCase() === "heritagecommerciallinesunderwritingguidelines"
	) {
		reportData.numberOfBuildings = $(`#div${reportType}_index`)
			.find('.model-input[name="numberOfBuildings"]')
			.val();
		reportData.highlandExceptionMade = $(`#div${reportType}_index`)
			.find('.model-input[name="highlandExceptionMade"]')
			.val();

		reportData.buildings = [];

		$(".building-fieldset").each(function () {
			reportData.buildings.push({
				BFE: $(this).find('.model-input[name="BFE"]').val(),
				LFE: $(this).find('.model-input[name="LFE"]').val(),
				floodPriorLosses: $(this)
					.find('.model-input[name="floodPriorLosses"]')
					.val(),
				coverageA: $(this).find('.model-input[name="coverageA"]').val(),
				coverageC: $(this).find('.model-input[name="coverageC"]').val()
			});
		});
	}

	if (
		utils.getReportParameterByName(
			window.reportConfigurationModel,
			report,
			"filterResultsWithDrawingTools"
		) &&
		window.mapRiskController.getReportFilterOverlay()
	) {
		var reportFilterOverlay = window.mapRiskController.getReportFilterOverlay();
		var overlayType = reportFilterOverlay.mr_type;
		var polygon = {};

		switch (overlayType) {
			case "circle":
				polygon.type = "circle";
				polygon.poi =
					reportFilterOverlay.center.lat() +
					"," +
					reportFilterOverlay.center.lng();
				polygon.radius = reportFilterOverlay.getRadius();

				break;
			case "polygon":
				var path = reportFilterOverlay
					.getPath()
					.getArray()
					.map(function (el) {
						return el.lng() + " " + el.lat();
					});
				polygon.type = "wkt";
				polygon.wkt = "POLYGON ((";
				polygon.wkt += path.join(",") + path[0];
				polygon.wkt += "))";

				break;
			case "rectangle":
				var sLng = reportFilterOverlay.getBounds().getSouthWest().lng();
				var wLat = reportFilterOverlay.getBounds().getSouthWest().lat();
				var nLng = reportFilterOverlay.getBounds().getNorthEast().lng();
				var eLat = reportFilterOverlay.getBounds().getNorthEast().lat();

				polygon.type = "wkt";
				polygon.path = "";
				polygon.wkt =
					"POLYGON ((" +
					nLng +
					" " +
					wLat +
					"," +
					nLng +
					" " +
					eLat +
					"," +
					sLng +
					" " +
					eLat +
					"," +
					sLng +
					" " +
					wLat +
					"," +
					nLng +
					" " +
					wLat +
					"))";

				break;
		}

		if (polygon) {
			reportData.userPolygon = JSON.stringify(polygon);
		}

		if (
			window.mrState.mrFeatureClick.reports &&
			window.mrState.mrFeatureClick.reports[report] &&
			window.mrState.mrFeatureClick.reports[report].lastClickLocation
		) {
			reportData.filterByZone = JSON.stringify(
				window.mrState.mrFeatureClick.reports[report].lastClickLocation
			);
		}
	}

	return reportData;
}

function getReportConfiguration(report) {
	return window.reportConfigurationModel[report.toLowerCase()];
}

//#region Building Permits

function permitTypeSelectAll() {
	$(".permitTypeCheckbox_index").each(function (i, obj) {
		$(obj).prop("checked", true);
	});
}

function permitTypeSelectNone() {
	$(".permitTypeCheckbox_index").each(function (i, obj) {
		$(obj).prop("checked", false);
	});
}

//#endregion

function unlockPaywalledReports() {
	if (!window.accountConfigurationModel.hasMadeFirstPurchase) return;

	$(".rightSidebarContent .reportTableWrapper .tblSelectReportGroups > tbody")
		.children()
		.each(function () {
			$(this)
				.find(".divRequestReportGroupWrapper .tblSelectReports > tbody")
				.children()
				.each(function () {
					$(this).children(":first").find("input").prop("disabled", false);
					$(this).children(":first").find("img").remove();
					$(this).children(":first").find("input").show();
				});
		});
}

function autoCheckify() {
	$(".chkExecuteReport").each(function () {
		if (Boolean($(this).data("autochecked"))) $(this).trigger("click");
	});
}

function collapseReportGroups() {
	if (
		window.accountConfigurationModel.runtimeConfiguration.website
			.reportSelectMenu &&
		window.accountConfigurationModel.runtimeConfiguration.website
			.reportSelectMenu.reportGroups
	) {
		for (var group in window.accountConfigurationModel.runtimeConfiguration
			.website.reportSelectMenu.reportGroups) {
			if (
				window.accountConfigurationModel.runtimeConfiguration.website
					.reportSelectMenu.reportGroups[group].expanded === false
			) {
				var title = group.split(" ").join("_");
				var el = $(
					".requestReportGroupTitle[data-accordion-target='#trRequestReportGroup_" +
						title +
						"_index']"
				);
				accordions.close(el);
			}
		}
	}
}

window.runSearch = function (inputAddress) {
	window.addressSearch.disableSearchButton();

	if (mainReportView) {
		mainReportView.removeAllReports();
		mainReportView.events.detachAllToggleClicks();
		$(
			".reportNameHeaderReportViewToggleContainer .chkReportViewControl"
		).remove();
	}

	var data = {
		addressLine: inputAddress,
		settings: determineRequestSettings()
	};

	var reportSettings;
	var layerObject;
	var configClient = new ConfigurationSettingsClient();
	var reportRequestListLength = 0;
	// url link setup
	var isUrlLink = window.location.pathname == "/transaction";
	var urlLinkConfig;

	window.mapRiskController.clearPoiRadii();
	window.mapRiskController.radiusOverlays = {};

	for (var r in window.reportRequestList) {
		reportRequestListLength++;
	}

	if (reportRequestListLength === 0) {
		return;
	}

	window.resultsMenu.open(true, true);

	var skipGeocodingAndRunReports = function () {
		var updateGeocodeCallback = function (err, geocodeResponse) {
			if (err) {
				window.addressSearch.enableSearchButton();
				return;
			}
			var geocodeReportRequest = window.reportRequestList.geocode;

			if (geocodeReportRequest) {
				if (
					geocodeResponse.response &&
					geocodeResponse.response.geocodeResults.length > 0
				) {
					var poi = JSON.parse(
						JSON.stringify(geocodeResponse.response.geocodeResults[0])
					);

					poi.lat = window.mapRiskController.currentPoi.lat;
					poi.lng = window.mapRiskController.currentPoi.lng;

					geocodeResponse.response.reportResults = {
						geocode: geocodeResponse.response.geocodeResults[0]
					};
					window.geocodeModel = geocodeResponse;

					window.reportsModel[report] = {
						latitude: poi.lat,
						longitude: poi.lng,
						report: geocodeResponse.response.reportResults,
						transactionId: geocodeResponse.request.transactionId,
						urlLink: geocodeResponse.response.urlLink
					};

					window.currentPoiInfo = {
						geoResult: geocodeResponse.response.geocodeResults[0],
						poi: poi,
						layerObj: layerObject,
						request: geocodeResponse.request
					};

					appendReportResult(
						window.currentPoiInfo.geoResult,
						geocodeResponse,
						"geocode",
						geocodeReportRequest,
						poi,
						window.currentPoiInfo.layerObj
					);
				}
			}

			for (var report in window.reportRequestList) {
				if (report == "geocode") {
					if (
						window.mapRiskController.skipGeocodeOnNextSearch &&
						window.tempPoi
					) {
						var latLng = new google.maps.LatLng({
							lat: window.mapRiskController.currentPoi.lat,
							lng: window.mapRiskController.currentPoi.lng
						});

						window.resultMapController.reportOverlays.marker[0].setPosition(
							latLng
						);
						window.resultMapController.currentPoi.latitude = latLng.lat();
						window.resultMapController.currentPoi.lat = latLng.lat();
						window.resultMapController.currentPoi.longitude = latLng.lng();
						window.resultMapController.currentPoi.lng = latLng.lng();
						window.resultMapController.setMapCenter({
							lat: window.mapRiskController.currentPoi.lat,
							lng: window.mapRiskController.currentPoi.lng
						});
						window.mapRiskController.setMapCenter({
							lat: window.mapRiskController.currentPoi.lat,
							lng: window.mapRiskController.currentPoi.lng
						});

						delete window.tempPoi;
					}

					continue;
				}

				var reportData = setReportRequestData(report);

				window.reportRequestList[report].lat =
					window.reportRequestList[report].latitude;
				window.reportRequestList[report].lng =
					window.reportRequestList[report].longitude;

				var geoResult = window.geocodeModel.response
					? window.geocodeModel.response.geocodeResults[0]
					: window.transactionModel.inputLocation;

				invokeReportRequest(
					geoResult,
					window.currentPoiInfo.poi,
					report,
					reportData,
					layerObject
				);

				if (
					window.mapRiskController.skipGeocodeOnNextSearch &&
					window.tempPoi
				) {
					var latLng = new google.maps.LatLng({
						lat: window.mapRiskController.currentPoi.lat,
						lng: window.mapRiskController.currentPoi.lng
					});

					window.resultMapController.reportOverlays.marker[0].setPosition(
						latLng
					);
					window.resultMapController.currentPoi.latitude = latLng.lat();
					window.resultMapController.currentPoi.lat = latLng.lat();
					window.resultMapController.currentPoi.longitude = latLng.lng();
					window.resultMapController.currentPoi.lng = latLng.lng();
					window.resultMapController.setMapCenter({
						lat: window.mapRiskController.currentPoi.lat,
						lng: window.mapRiskController.currentPoi.lng
					});
					window.mapRiskController.setMapCenter({
						lat: window.mapRiskController.currentPoi.lat,
						lng: window.mapRiskController.currentPoi.lng
					});

					delete window.mapRiskController.skipGeocodeOnNextSearch;
					delete window.tempPoi;
				}
			}

			delete window.mapRiskController.skipGeocodeOnNextSearch;
			window.addressSearch.enableSearchButton();
		};

		var transactionId;
		var data = {};

		if (
			window.transactionModel &&
			window.transactionModel.request &&
			window.transactionModel.request.transactionId
		) {
			transactionId = window.transactionModel.request.transactionId;
		} else {
			transactionId = window.geocodeModel.request.transactionId;
		}

		if (
			window.geocodeModel.response &&
			window.geocodeModel.response.geocodeResults
		) {
			if (
				window.accountConfigurationModel.runtimeConfiguration.website &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun.preserveOriginalAddress === true
			) {
				data = {
					poi: {
						street: window.geocodeModel.response.geocodeResults[0].street,
						city: window.geocodeModel.response.geocodeResults[0].city,
						state: window.geocodeModel.response.geocodeResults[0].state,
						zip: window.geocodeModel.response.geocodeResults[0].zip,
						latitude: window.mapRiskController.currentPoi.lat,
						longitude: window.mapRiskController.currentPoi.lng
					},
					transactionId: transactionId
				};
			} else {
				data = {
					poi: {
						latitude: window.mapRiskController.currentPoi.lat,
						longitude: window.mapRiskController.currentPoi.lng
					},
					transactionId: transactionId
				};
			}
		} else if (window.transactionModel && window.transactionModel.request) {
			if (
				window.accountConfigurationModel.runtimeConfiguration.website &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun &&
				window.accountConfigurationModel.runtimeConfiguration.website.poi
					.moveMarkerForReportReRun.preserveOriginalAddress === true
			) {
				data = {
					poi: {
						street: window.geocodeModel.response.reportResults.geocode.street,
						city: window.geocodeModel.response.reportResults.geocode.city,
						state: window.geocodeModel.response.reportResults.geocode.state,
						zip: window.geocodeModel.response.reportResults.geocode.zip,
						latitude: window.mapRiskController.currentPoi.lat,
						longitude: window.mapRiskController.currentPoi.lng
					},
					transactionId: transactionId
				};
			} else {
				data = {
					poi: {
						latitude: window.mapRiskController.currentPoi.lat,
						longitude: window.mapRiskController.currentPoi.lng
					},
					transactionId: transactionId
				};
			}
		}

		var geocodeClient = new GeocodeClient();

		geocodeClient.geocodeAddress(data, updateGeocodeCallback);

		window.reportsMenu.close(true, true);
	};

	var geocodeCallback = function (error, geocodeResponse) {
		window.mapRiskController.originalPoi = null;
		window.reportsMenu.close(true, true);

		if (error) {
			window.addressSearch.enableSearchButton();
			return;
		}

		if (geocodeResponse.error) {
			window.addressSearch.enableSearchButton();

			if (geocodeResponse.error === "Insufficient Credits Availabile") {
				dialog.openDialog(CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_GENERAL);

				return;
			}
		}

		// Live update the credits remaining
		// 		if the geocode response results are null as that indicates
		// 		that the user is out of credits and can no longer obtain results
		//			- it is then set to be 0 as the current creditsRemaining value is
		//			  returned undefined
		//		else we will update the html with the credits remaining and pass it into
		if (geocodeResponse.response.geocodeResults === null) {
			$("#spanCreditsRemaining").html(" " + 0);
		} else {
			$("#spanCreditsRemaining").html(
				" " + geocodeResponse.response.creditsRemaining
			);
		}

		window.geocodeModel = geocodeResponse;

		//Check to see if the geocode results are not null and that there is atleast 1
		//array containing the geocode results, if it passes create a POI, otherwise
		//there was a problem regarding insufficient credits or a problem with the address
		if (
			geocodeResponse.response.geocodeResults !== null &&
			geocodeResponse.response.geocodeResults.length === 1
		) {
			var poi = geocodeResponse.response.geocodeResults[0];

			poi = JSON.parse(JSON.stringify(poi));
			poi.lat = poi.latitude;
			poi.lng = poi.longitude;

			window.currentPoiInfo = {
				geoResult: geocodeResponse.response.geocodeResults[0],
				poi: poi,
				layerObj: layerObject,
				request: geocodeResponse.request
			};

			$('.map-control[data-type="street-view"]').removeClass("disabled");

			addAddressMarker(window.currentPoiInfo.poi);

			for (let report in window.reportRequestList) {
				let reportData = setReportRequestData(report);

				window.reportRequestList[report].lat =
					window.reportRequestList[report].latitude;
				window.reportRequestList[report].lng =
					window.reportRequestList[report].longitude;

				if (report.toLowerCase() == "geocode") {
					geocodeResponse.response.reportResults = {
						geocode: geocodeResponse.response.geocodeResults[0]
					};

					window.reportsModel[report] = {
						report: geocodeResponse.response.reportResults,
						transactionId: geocodeResponse.request.transactionId,
						urlLink: geocodeResponse.response.urlLink
					};

					appendReportResult(
						window.currentPoiInfo.geoResult,
						geocodeResponse,
						report,
						window.reportRequestList[report],
						poi,
						window.currentPoiInfo.layerObj
					);
					window.addressSearch.enableSearchButton();

					continue;
				}

				invokeReportRequest(
					geocodeResponse.response.geocodeResults[0],
					poi,
					report,
					reportData,
					layerObject
				);
			}
		} else {
			window.addressSearch.enableSearchButton();

			//If the user is not allowed negative credits show them a dialog message saying
			//they have insufficient funds, otherwise they are allowed negative credits but
			//there was a geocoding error
			if (
				window.accountConfigurationModel.creditPool?.allowNegativeCredits ===
					false &&
				window.accountHasCredits === false
			) {
				dialog.openDialog(CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_GENERAL);
				onCreditsUpdated(geocodeResponse.response.creditsRemaining);
			} else {
				dialog.openDialog(CONSTANTS.DIALOG.GEOCODE_ERROR);
				onCreditsUpdated(geocodeResponse.response.creditsRemaining);
			}
		}
	};

	for (var r in window.reportRequestList) {
		for (var index in window.reportRequestList[r].layers) {
			if (!window.reportRequestList[r].layers[index]) continue;

			window.mapRiskController.removeOverlay(
				window.reportRequestList[r].layers[index]
			);
		}
	}

	if (!window.mapRiskController.skipGeocodeOnNextSearch) {
		window.mapRiskController.clearReportOverlays();
		window.resultMapController.clearReportOverlays();

		// clear models
		window.geocodeModel = {};
		window.reportsModel = {};

		var geocodeClient = new GeocodeClient();

		if (
			window.transactionModel &&
			window.transactionModel.request &&
			window.transactionModel.request.transactionId
		) {
			data.transactionId = window.transactionModel.request.transactionId;
		}

		geocodeClient.geocodeAddress(data, geocodeCallback);
	} else {
		skipGeocodingAndRunReports();
	}
};

function formatAsCurrency(el, includeSymbol, symbol) {
	/*
		Description:
			Reformat the int or string value of an element to a
			formatted string of U.S. dollars.
			e.g. 123456789 to $123,456,789
	*/
	if (includeSymbol == null) includeSymbol = true;
	if (symbol == null) symbol = "$";

	var str = $(el).val();

	// this should be improved
	str = str
		.split(symbol)
		.join("")
		.split(",")
		.join("")
		.split(" ")
		.join("")
		.split(".")
		.join("");
	var bare_str = str;

	if (str.length > 3) {
		str = str.split("").reverse().join("");

		for (var i = 3; i < str.length; i += 4) {
			str = str.substr(0, i) + "," + str.substr(i);
		}

		str = str.split("").reverse().join("");
	}

	if (str.length > 0) {
		if (str == "0") {
			str = "";
		}

		if (includeSymbol) {
			if (str != symbol && str != "") {
				str = symbol + str;
			}
		}
	}

	if (isNaN(bare_str)) {
		str = "";
	}

	$(el).val(str);
}

/**
 * Application State. These fields should only be changed by the classes that "own" them.
 */
var state = {
	menu: {},
	sidebar: {
		reports: {
			controlId: "reports-sidebar-toggle",
			sidebarClass: "rightSidebarWrapperOuter",
			user: {
				open: true
			},
			app: {
				open: false
			}
		},
		results: {
			controlId: "results-sidebar-toggle",
			sidebarClass: "leftSidebarWrapper",
			user: {
				open: true
			},
			app: {
				open: true
			}
		}
	},
	search: {
		textbox: {
			id: "txtInputReportAddress",
			isEmpty: true,
			enabled: true
		},
		button: {
			id: "searchBtn",
			enabled: false
		}
	}
};

/**
 * Sidebar controller
 * @param {string} name - The name of the sidebar to control
 * @param {object} state - The desired starting state of and specific to the sidebar
 */
function Sidebar(name, state) {
	this.name = name;
	this.state = state;
	this.control = document.getElementById(this.state.controlId);
	this.sidebar = document.getElementsByClassName(this.state.sidebarClass)[0];
	this.setClickHandler();
}

/**
 * Checks if sidebar is open
 * @returns {boolean}
 */
Sidebar.prototype.isOpen = function () {
	if (
		$(this.control).hasClass("active") === false ||
		$(this.sidebar).hasClass("active") === false
	)
		return false;
	else return true;
};

Sidebar.prototype.setClickHandler = function () {
	var self = this;

	$(self.control).on("click", function () {
		if (self.isOpen()) self.close();
		else self.open();
	});
};

/**
 * Opens the sidebar
 * @param {boolean} isApp  - Pass true if sidebar is being opened by the application
 * @param {boolean} force - Pass true if the sidebar should be forced open
 */
Sidebar.prototype.open = function (isApp, force) {
	if (isApp) this.state.app.open = true;
	else this.state.user.open = true;

	if (isApp && !this.state.user.open && !force) return;

	$(this.sidebar).addClass("active");
	$(this.control).addClass("active");

	/* This really needs to be reworked.  The addition and removal of 'active' classes
	 * to the sidebars triggers a 125ms transition event, which the StreetViewResize()
	 * method isn't exposed to.  Instead of a timeout, the css animations may need to
	 * be replaced with js animations and pass StreetViewResize as a callback.
	 * 20180803 - CA
	 */
	window.setTimeout(function () {
		window.mapRiskController.StreetViewResize(window.mapRiskController.map);
	}, 250);
};

/**
 * Closes the sidebar
 * @param {boolean} isApp - Pass true if sidebar is being closed by the application
 * @param {boolean} force - Pass true if the sidebar should be forced open
 */
Sidebar.prototype.close = function (isApp, force) {
	if (isApp) {
		this.state.app.open = false;
	} else {
		this.state.user.open = false;
	}

	if (isApp && this.state.user.open && !force) {
		return;
	}

	$(this.sidebar).removeClass("active");
	$(this.control).removeClass("active");

	/* This really needs to be reworked.  The addition and removal of 'active' classes
	 * to the sidebars triggers a 125ms transition event, which the StreetViewResize()
	 * method isn't exposed to.  Instead of a timeout, the css animations may need to
	 * be replaced with js animations and pass StreetViewResize as a callback.
	 * 20180803 - CA
	 */
	window.setTimeout(function () {
		window.mapRiskController.StreetViewResize(window.mapRiskController.map);
	}, 250);
};

/**
 * Menu controller
 * @param {string} name - The name of the menu to control
 * @param {object} state - The desired starting state of and specific to the menu
 */
function Menu(name, state) {
	this.name = name;
	this.state = state;
	this.control = document.getElementById(this.state.controlId);
	this.menu = document.getElementsByClassName(this.state.menuClass)[0];
	this.setClickHandler();
}

/**
 * Checks if menu is open
 * @returns {boolean}
 */
Menu.prototype.isOpen = function () {
	if (
		$(this.control).hasClass("disabled") &&
		$(this.menu).is(":visible") === false
	)
		return false;
	else return true;
};

Menu.prototype.setClickHandler = function () {
	var self = this;

	$(self.control).on("click", function () {
		if (self.isOpen()) self.close();
		else self.open();
	});
};

/**
 * Opens the menu
 * @param {boolean} isApp  - Pass true if menu is being opened by the application
 * @param {boolean} force - Pass true if the menu should be forced open
 */
Menu.prototype.open = function (isApp, force) {
	if (isApp) this.state.app.open = true;
	else this.state.user.open = true;

	if (isApp && !this.state.user.open && !force) return;

	$(this.control).removeClass("disabled");
	$(this.menu).parent().show();
};

/**
 * Closes the menu
 * @param {boolean} isApp - Pass true if menu is being closed by the application
 * @param {boolean} force - Pass true if the menu should be forced open
 */
Menu.prototype.close = function (isApp, force) {
	if (isApp) this.state.app.open = false;
	else this.state.user.open = false;

	if (isApp && this.state.user.open && !force) return;

	$(this.control).addClass("disabled");
	$(this.menu).parent().hide();
};

/**
 * Address search controller
 * @param {object} state - The desired starting state of and specific to the address search controls
 * @param {object} reportsMenu The reportsMenu dependency for control purposes
 * @param {object} resultsMenu The resultsMenu dependency for control purposes
 */
function Search(state, reportsMenu, resultsMenu) {
	this.state = state;
	this.textbox = document.getElementById(state.textbox.id);
	this.button = document.getElementById(state.button.id);
	this.reportsMenu = reportsMenu;
	this.resultsMenu;
	this.attachHandlers();
}

Search.prototype.setSearchText = function (text, forceEnable) {
	if (!this.state.button.enabled && !forceEnable) {
		return;
	} else {
		this.enableSearchButton();
	}

	$(this.textbox).val(text);
	this.state.textbox.isEmpty = false;
};

/**
 * Disables the text box
 */
Search.prototype.disableTextBox = function () {
	if (this.state.textbox.enabled) {
		this.state.textbox.enabled = false;
		$(this.textbox).attr("disabled", "disabled");
	}
};

/**
 * Enables the search button
 */
Search.prototype.enableSearchButton = function () {
	if (
		(window.mrState.viewOnly.enabled !== true ||
			(window.mrState.viewOnly.enabled === true &&
				window.mrState.viewOnly.modifyAddressBar === true)) &&
		$(".rightSidebarContent .chkExecuteReport:checked").length >= 1
	) {
		this.state.button.enabled = true;
		$(this.button).attr("disabled", null);
	}
};

/**
 * Disables the search button
 */
Search.prototype.disableSearchButton = function () {
	this.state.button.enabled = false;
	$(this.button).attr("disabled", "disabled");
};

/**
 * The submit handler for the address search controls
 * @param {object} me Provides access to self in order to access state
 */
Search.prototype.submit = function (me) {
	var self = me ? me : this;

	if (self.state.textbox.isEmpty) {
		//If the user attempts to hit the run button when the textbox is empty
		//and the report request list is empty we will disable the button
		if ($.isEmptyObject(window.reportRequestList)) {
			self.disableSearchButton();
			return;
		}
		//Otherwise they have selected a report but the address search box
		//is empty and we will populate a dialog
		dialog.openDialog(CONSTANTS.DIALOG.PLEASE_ENTER_AN_ADDRESS);
		for (let reportId in window.reportRequestList) {
			setGetReportButtonState(reportId, "Get Report", false);
		}

		return;
	} else if (!self.state.button.enabled) {
		//If the button is disabled and there isn't a report selected
		//notify the user to to select a report first
		if ($.isEmptyObject(window.reportRequestList)) {
			window.mapUNC.pushMessage(
				window.mrUserNotifications.noReportsSelected,
				"warning"
			);
		}
		return;
	}

	self.disableSearchButton();

	var inputAddress = $(self.textbox).val();

	var validationResults = reportValidation.validate(window.reportRequestList);
	var validationPassed = reportValidation.isValid(validationResults);

	reportValidation.hideInvalidStatus();

	if (validationPassed === true) {
		window.runSearch(inputAddress);
	} else {
		for (var reportId in validationResults) {
			if (Object.keys(validationResults[reportId]?.inputs).length === 0) {
				continue;
			}

			setGetReportButtonState(reportId, "Get Report", false);

			var warningString = [];
			for (var input in validationResults[reportId].inputs) {
				if (validationResults[reportId].inputs[input].valid === false) {
					if (validationResults[reportId].inputs[input].required) {
						warningString.push(
							validationResults[reportId].inputs[input].label + " is required"
						);
					}

					if (validationResults[reportId].inputs[input].message) {
						warningString.push(
							validationResults[reportId].inputs[input].label +
								" " +
								validationResults[reportId].inputs[input].message
						);
					}
				}
			}

			warningString = warningString.join("<br>");
			warningString += "<br>";

			warningString = warningString.slice(0, -4);
			window.mapUNC.pushMessage(
				validationResults[reportId].name +
					" has a Model Input issue:" +
					"<br>" +
					warningString,
				"warning",
				15000
			);
		}

		reportValidation.showInvalidStatus(validationResults);
		self.enableSearchButton();
	}
};

/**
 * Listens to the focus event on the textbox to manage reportsMenu state
 */
Search.prototype.setTextboxEnterHandler = function () {
	var self = this;

	$(this.textbox).focus(function () {
		if (self.reportsMenu.isOpen()) return;

		self.reportsMenu.open(true, true);
	});
};

/**
 * Listens to the textbox input event in order to manage the isEmpty state field
 */
Search.prototype.setTextboxInputHandler = function () {
	var self = this;

	$(this.textbox).on("input", function () {
		if ($(this).val() === "") self.state.textbox.isEmpty = true;
		else self.state.textbox.isEmpty = false;
	});
};

/**
 * Listens for the enter keypress in order to allow submission via the keyboard
 */
Search.prototype.setEnterKeyHandler = function () {
	var self = this;

	$(this.textbox).keypress(function (e) {
		if (e.which === 13) {
			self.submit();

			return false;
		}
	});
};

/**
 * Listens to the click event on the search button in order to trigger a search submission by click
 */
Search.prototype.setSubmitHandler = function () {
	var self = this;

	$(this.button).on("click", function () {
		self.submit(self);
	});
};

/**
 * A helper function used by the constructor to attach all event listeners
 */
Search.prototype.attachHandlers = function () {
	this.setTextboxEnterHandler();
	this.setTextboxInputHandler();
	this.setEnterKeyHandler();
	this.setSubmitHandler();
};

/**
 * Method to handle the creation of accordions based on classname and data attributes
 * Auto-closes any elements with a specific class on page load (specified in settings.triggerClosedState)
 * Includes manual methods to open/close
 * @param
 * 		{obj} options: {
 * 			{str} triggerEl: dom identifier of the "trigger" element
 * 			{str} triggerClosedState: classname for closed accordion trigger
 * 			{str} targetClosedState: classname for closed accordion target
 *			{str} containerClosedState: classname for closed accordion container
 *			{str} containerAttr: data attribute name applied to trigger element which contains the value of the accordion container
 *			{str} targetAttr: data attribute name applied to trigger element which contains the value of the accordion target to be toggled open/closed
 *		}
 */

function mrReportValidation() {
	var self = this;

	self.validate = function (reports) {
		var response = {};

		for (var reportId in reports) {
			var validationRules =
				utils.getReportParameterByName(
					window.reportConfigurationModel,
					reportId,
					"modelInputValidation"
				) || null;

			var inputConfig = {};

			response[reportId] = {
				name:
					window.reportConfigurationModel[reportId.toLowerCase()].reportName,
				inputs: {}
			};

			// rollup markup-based configuration with report configuration as override
			if ($("#div" + reportId + "_index").length > 0) {
				$(
					`#div${reportId}_index input[name]:visible, #div${reportId}_index textarea[name]:visible, #div${reportId}_index select[name]:visible`
				).each(function () {
					var name = $(this).attr("name");

					inputConfig[name] = {
						required:
							validationRules &&
							validationRules[name] &&
							(validationRules[name].required === true ||
								validationRules[name].required === false)
								? validationRules[name].required
								: $(this).attr("data-required") === "true"
								? true
								: false,
						regex:
							validationRules &&
							validationRules[name] &&
							validationRules[name].regex
								? validationRules[name].regex
								: $(this).attr("data-regex-string")
								? $(this).attr("data-regex-string")
								: null,
						flags:
							validationRules &&
							validationRules[name] &&
							validationRules[name].flags
								? validationRules[name].flags
								: $(this).attr("data-regex-flags")
								? $(this).attr("data-regex-flags")
								: null,
						message:
							validationRules &&
							validationRules[name] &&
							validationRules[name].message
								? validationRules[name].message
								: ""
					};
				});
			}

			let modelInput = ModelInputFactory.makeWebsiteValidator(
				window.reportConfigurationModel?.[reportId?.toLowerCase()],
				setReportRequestData(reportId),
				validationRules
			);

			let validationResult = modelInput.validate();

			if (!validationResult.pass) {
				for (let name in validationResult.errors) {
					let $el = $(
						"#div" + reportId + '_index *[name="' + name + '"]:visible'
					);
					var label = $el.prevAll("label").text();

					response[reportId].inputs[$el.attr("id")] = {
						label: label,
						valid: validationResult.errors?.[name] ? false : true,
						message: validationResult.errors?.[name]?.message ?? "",
						required: validationResult.errors?.[name]?.required ? true : false
					};
				}
			}
		}

		return response;
	};

	self.isValid = function (results) {
		var validationPassed = true;

		for (var reportId in results) {
			for (var input in results[reportId].inputs) {
				if (results[reportId].inputs[input].valid === false) {
					validationPassed = false;
				}
			}
		}

		return validationPassed;
	};

	self.showInvalidStatus = function (results) {
		for (var reportId in results) {
			for (var input in results[reportId].inputs) {
				if (results[reportId].inputs[input].valid === false) {
					$(`#${input}`).prevAll("label").addClass("required");
				}
			}
		}
	};

	self.hideInvalidStatus = function () {
		$(".tblSelectReportGroups label").removeClass("required");
	};
}

window.accordionController = function () {
	var self = this;

	self.settings = {
		triggerEl: ".accordion-trigger",
		triggerClosedState: ".accordion-collapsed",
		targetClosedState: ".accordion-target-collapsed",
		containerClosedState: ".accordion-container-collapsed",
		containerAttr: "data-accordion-container",
		targetAttr: "data-accordion-target"
	};

	self.init = function (options) {
		if (typeof options !== "undefined") {
			$.extend(true, self.settings, options);
		}

		// close any accordions meant to default to closed states as per the markup
		self.autoClose();

		// attach event listener for trigger clicks
		self.events.triggerClick();
	};

	self.events = {
		triggerClick: function () {
			$("body").on(
				"click.accordionController",
				self.settings.triggerEl,
				function (e) {
					if (!$(this).is("input")) {
						e.preventDefault();
					}

					if (
						$(this).hasClass(
							self.settings.triggerClosedState.split(".").join("")
						)
					) {
						self.open(this);
					} else {
						self.close(this);
					}
				}
			);
		}
	};

	self.autoClose = function () {
		$(self.settings.triggerClosedState).each(function () {
			var target = $(this).attr(self.settings.targetAttr);

			$(target).slideUp(0);
		});
	};

	self.open = function (el) {
		if (
			$(el).hasClass("accordion-no-batch") &&
			$(el).parents(".batch-wizard").length > 0
		) {
			return;
		}

		var trigger = el;
		var target = $(el).attr(self.settings.targetAttr);

		if (typeof $(trigger).attr(self.settings.containerAttr) !== "undefined") {
			var container = $(trigger).attr(self.settings.containerAttr);
		}

		$(trigger).removeClass(
			self.settings.triggerClosedState.split(".").join("")
		);

		$(target).slideDown(125, function () {
			$(this).removeClass(self.settings.targetClosedState.split(".").join(""));
		});

		if (typeof container !== "undefined") {
			$(trigger)
				.parents(self.container)
				.removeClass(self.settings.containerClosedState.split(".").join(""));
		}
	};

	self.close = function (el) {
		var trigger = el;
		var target = $(el).attr(self.settings.targetAttr);

		if (typeof $(trigger).attr(self.settings.containerAttr) !== "undefined") {
			var container = $(trigger).attr(self.settings.containerAttr);
		}

		$(trigger).addClass(self.settings.triggerClosedState.split(".").join(""));
		$(target).slideUp(125, function () {
			$(this).addClass(self.settings.targetClosedState.split(".").join(""));
		});

		if (typeof container !== "undefined") {
			$(trigger)
				.parents(container)
				.addClass(self.settings.containerClosedState.split(".").join(""));
		}
	};
};

function mrUserNotificationCenter() {
	var self = this;

	self.options = {
		animationTick: 16.66666667, // making the hefty assumption that requestAnimationFrame will be running at 60fps
		autoFadeOut: true,
		autoFadeOutTime: 5000,
		classNames: "user-notification-center",
		fadeTime: 250,
		id: "user-notification-center",
		messageClassName: "user-notification-center-message",
		messageOrder: "desc",
		position: "before",
		preventDuplicates: true
	};

	self.state = window.mrState.mrUserNotificationCenter || {};

	self.types = {
		info: {
			className: "user-notification-center-message-info"
		},
		warning: {
			className: "user-notification-center-message-warning"
		},
		error: {
			className: "user-notification-center-message-error"
		}
	};

	self.$notificationContainer = $("<div></div>");
	self.$closeButton = $(
		'<div class="user-notification-message-close"><i class="icon-cancel"></i></div>'
	);
	self.$messageWrapper = $("<div></div>").append(self.$closeButton);

	self.init = function (options) {
		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"User Notification Center error: no parent container defined in options."
			);

			return;
		}

		self.$notificationContainer.attr("id", self.options.id);
		self.$notificationContainer.addClass(self.options.classNames);
		self.$messageWrapper.addClass(self.options.messageClassName);

		if ($("#" + self.options.id).length <= 0) {
			self.createNotificationCenter();
		}
	};

	self.createNotificationCenter = function () {
		if (self.options.position === "before") {
			$(self.options.parentContainer).prepend(self.$notificationContainer);
		} else {
			$(self.options.parentContainer).append(self.$notificationContainer);
		}
	};

	self.isMessageActive = function (msg) {
		var messageFound = false;

		for (var i = self.state.currentMessages.length; i--; ) {
			if (self.state.currentMessages[i].message === msg) {
				messageFound = true;
			}
		}

		return messageFound;
	};

	self.removeActiveMessage = function (msg) {
		self.state.currentMessages.splice(
			self.state.currentMessages.indexOf(msg),
			1
		);
	};

	self.pushMessage = function (msg, type, time) {
		var messageFound = self.isMessageActive(msg);

		self.state.history.push({
			message: msg,
			timestamp: moment().toISOString()
		});

		if (
			self.options.preventDuplicates === false ||
			(self.options.preventDuplicates === true && messageFound === false)
		) {
			var animationPromise = $.Deferred();

			$.when(animationPromise).done(function (msg) {
				self.afterFade(msg);
			});

			self.state.currentMessages.push({
				message: msg,
				timestamp: moment().toISOString()
			});

			var $message = $(self.$messageWrapper).clone().html(msg);

			$message.on("click", self.events.messageClick);

			if (self.options.messageOrder === "desc") {
				$("#" + self.options.id).prepend($message);
			} else {
				$("#" + self.options.id).append($message);
			}

			if (type && self.types[type]) {
				$message.addClass(self.types[type].className);
			}

			if ($(self.options.messageClassName).length > 0) {
				$("#" + self.options.id).addClass("active");
			}

			self.fadeIn($message, animationPromise, time);
		}
	};

	self.fadeIn = function ($message, promise, time) {
		var op = 0; // initial opacity
		var interval = self.options.animationTick / self.options.fadeTime;
		var animationId;

		var fade = function () {
			op += interval;
			$($message).css("opacity", op);
			$($message).css("filter", "alpha(opacity=" + op * 100 + ")");

			if (op < 1) {
				animationId = requestAnimationFrame(fade);
			} else {
				op = 1;
				$($message).css("opacity", op);
				$($message).css("display", "block");
				cancelAnimationFrame(animationId);

				if (self.options.autoFadeOut === true) {
					setTimeout(function () {
						self.fadeOut($message, promise);
					}, time || self.options.autoFadeOutTime);
				}
			}
		};

		requestAnimationFrame(fade);
	};

	self.fadeOut = function ($message, promise) {
		var op = 1; // initial opacity
		var interval = self.options.animationTick / self.options.fadeTime;
		var animationId;

		var fade = function () {
			op -= interval;
			$($message).css("opacity", op);
			$($message).css("filter", "alpha(opacity=" + op * 100 + ")");

			if (op > 0) {
				animationId = requestAnimationFrame(fade);
			} else {
				op = 0;
				$($message).css("opacity", op);
				$($message).css("display", "none");
				promise.resolve();
				cancelAnimationFrame(animationId);
			}
		};

		requestAnimationFrame(fade);
	};

	self.afterFade = function (msg) {
		self.removeActiveMessage(msg);
	};

	self.events = {
		messageClick: function () {
			$(this).fadeOut();
		}
	};
}

var ReportView = function () {
	var self = this;

	self.options = {
		containerBaseClass: "view-container popup",
		containerClassNames: "",
		innerContainerBaseClass: "view-inner-container",
		innerContainerClassNames: "",
		headerBaseClass: "view-header",
		headerClassNames: "",
		exportButtonBaseClass: "view-export-button",
		exitButtonBaseClass: "view-exit-button",
		wrapperBaseClass: "view-wrapper",
		wrapperClassNames: "",
		tableBaseClass: "view-table",
		tableClassNames: "",
		headingBaseClass: "view-heading",
		headingClassNames: "",
		position: "before"
	};

	self.stateModel = {
		currentReports: [],
		activeReport: null
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$viewContainer = $("<div></div>");
	self.$viewInnerContainer = $("<div></div>");
	self.$viewHeader = $("<div></div>");
	self.$viewExportButton = $(
		"<div class='button button-cta'>Export Raw Data</div>"
	);
	self.$viewExitButton = $('<div><i class="icon-cancel"></i></div>');
	self.$viewWrapper = $("<div></div>");
	self.$viewTable = $("<div></div>");

	self.init = function (options) {
		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log("Report View error: no parent container defined in options.");

			return;
		}

		if (!self.options.id) {
			console.log("Report View error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.create();
		} else {
			console.log(
				"Report View error: Cannot create duplicate Report View container."
			);

			return;
		}
	};

	self.create = function () {
		self.$viewContainer.attr("id", self.options.id);
		self.$viewContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$viewHeader.addClass(
			self.options.headerBaseClass + " " + self.options.headerClassNames
		);
		self.$viewExportButton.addClass(self.options.exportButtonBaseClass);
		self.$viewExitButton.addClass(self.options.exitButtonBaseClass);
		self.$viewInnerContainer.addClass(self.options.innerContainerBaseClass);
		self.$viewWrapper.addClass(
			self.options.wrapperBaseClass + " " + self.options.wrapperClassNames
		);
		self.$viewTable.addClass(
			self.options.tableBaseClass + " " + self.options.tableClassNames
		);

		self.$viewHeader
			.append(self.$viewExportButton)
			.append(self.$viewChangeViewButton)
			.append(self.$viewExitButton);

		self.$viewInnerContainer.append(self.$viewHeader);

		self.$viewContainer.append(self.$viewInnerContainer);

		$(self.options.parentContainer).append(self.$viewContainer);

		self.events.exportClick();
		self.events.exitClick();
	};

	self.destroy = function () {
		self.$viewContainer.remove();
	};

	self.showView = function () {
		// doesn't actually do anything since Tabulator completely eats the browser's render cycle
		$(".loading-overlay").addClass("active");

		self.state.currentReports.forEach(function (report) {
			if (report.reportId === self.state.activeReport) {
				var height =
					self.$viewContainer.outerHeight() - self.$viewHeader.outerHeight();

				for (var table in report.tables) {
					report.tables[table].tabulator.setHeight("auto");
					report.tables[table].tabulator.redraw(true);
				}

				self.$viewContainer.addClass("active");
				$(".bodyContentMainWrapper").addClass("view-active");
			}
		});
	};

	self.hideView = function () {
		$(".reportNameHeaderReportViewToggleContainer .chkReportViewControl").each(
			function () {
				$(this).removeClass("active");
			}
		);

		self.$viewContainer.removeClass("active");
		$(".bodyContentMainWrapper").removeClass("view-active");
	};

	self.flattenData = function (data) {
		var flatData = [];

		data.forEach(function (el) {
			var flatEl = JSON.parse(JSON.stringify(el));

			for (var prop in flatEl) {
				if (Array.isArray(flatEl[prop]) || flatEl[prop] instanceof Object) {
					flatEl[prop] = JSON.stringify(flatEl[prop]);
				}
			}

			if (flatEl && Object.keys(flatEl).length > 0) {
				flatData.push(flatEl);
			}
		});

		return flatData;
	};

	self.getColumns = function (data) {
		var columns = [];
		var flatData = data.map(function (el) {
			var flatEl = JSON.parse(JSON.stringify(el));

			for (var prop in flatEl) {
				if (columns.indexOf(prop) === -1) {
					columns.push(prop);
				}
			}
		});

		return columns;
	};

	self.mapColumns = function (columns, excludedColumns) {
		return columns.map(function (el) {
			var foo = {
				title: utils.titleify(el),
				field: el,
				headerSort: false,
				visible: excludedColumns.indexOf(el) > -1 ? false : true
			};

			return foo;
		});
	};

	self.mapTables = function (data, excludedColumns) {
		var tables = {};

		if (!Array.isArray(excludedColumns)) {
			excludedColumns = [excludedColumns];
		}

		data.forEach(function (el) {
			if (!tables[el["aggregateSummaryTableName"]]) {
				tables[el["aggregateSummaryTableName"]] = {
					data: []
				};
			}

			tables[el["aggregateSummaryTableName"]].data.push(el);
		});

		for (var table in tables) {
			var isTableCollection = false;
			var defaultAggregationZone = "1/4 Mile";
			var fallbackAggregationZone;
			var defaultAggregationZoneFound = false;
			tables[table].initialFilters = [];

			tables[table].columns = self.getColumns(tables[table].data);
			tables[table].tableColumns = self.mapColumns(
				tables[table].columns,
				excludedColumns
			);
			tables[table].flatData = self.flattenData(tables[table].data);

			for (var i = 0; i < tables[table].data.length; i++) {
				if (tables[table].data[i].aggregateSummarySubTableName) {
					isTableCollection = true;

					if (
						tables[table].data[i].aggregateSummarySubTableName ==
						defaultAggregationZone
					) {
						defaultAggregationZoneFound = true;
					}
				}
			}

			for (var i = 0; i < tables[table].data.length; i++) {
				if (tables[table].data[i].aggregateSummarySubTableName) {
					fallbackAggregationZone =
						tables[table].data[i].aggregateSummarySubTableName;
					break;
				}
			}

			if (!defaultAggregationZoneFound) {
				defaultAggregationZone = fallbackAggregationZone;
			}

			if (isTableCollection) {
				tables[table].initialFilters.push({
					field: "aggregateSummarySubTableName",
					type: "=",
					value: defaultAggregationZone
				});

				tables[table].filterField = "aggregateSummarySubTableName";
			}
		}

		return tables;
	};

	self.addReport = function (report) {
		var report = $.extend(true, [], report);
		var reportFound = false;
		var reportId = report.reportId;
		var tables = self.mapTables(report.details, report.excludeColumns);

		for (var table in tables) {
			var $viewWrapper = self.$viewWrapper.clone();
			var $viewTable = self.$viewTable.clone();
			var $viewHeading = $(
				'<div class="' +
					self.options.headingBaseClass +
					'">' +
					utils.titleify(table) +
					"</div>"
			);

			$viewWrapper.attr({
				"data-reportId": reportId
			});

			if (tables[table].initialFilters) {
				var initialFilters = tables[table].initialFilters;

				initialFilters.forEach(function (initialFilter) {
					var filterVals = [];
					var filterField = initialFilter.field;
					var $viewHeadingDropdown = $(
						'<select class="view-heading-dropdown" data-field="' +
							filterField +
							'"></select>'
					);

					tables[table].data.forEach(function (el) {
						if (el[filterField]) {
							if (filterVals.indexOf(el[filterField]) < 0) {
								filterVals.push(el[filterField]);
							}
						}
					});

					filterVals.forEach(function (filter) {
						var selected = "";
						if (initialFilter.value === filter) {
							selected = "selected";
						}

						$viewHeadingDropdown.append(
							$(
								'<option value="' +
									filter +
									'"' +
									selected +
									">" +
									filter +
									"</option>"
							)
						);
					});

					$viewHeading.append($viewHeadingDropdown);
				});
			}

			$viewTable
				.attr("id", self.options.id + "-" + report.reportId + "-" + table)
				.attr("data-reportId", report.reportId)
				.attr("data-table-name", utils.titleify(table));

			$viewWrapper.append($viewHeading);
			$viewWrapper.append($viewTable);
			self.$viewInnerContainer.append($viewWrapper);
		}

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId === reportId
			) {
				reportFound = true;
				self.state.currentReports[i] = report;

				break;
			}
		}

		if (reportFound === false) {
			self.state.currentReports.push(report);
		}

		self.addToggle(reportId);
		self.addTables(report);
	};

	self.addTables = function (report) {
		var reportIndex = -1;
		var tables = self.mapTables(report.details, report.excludeColumns);

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId.toLowerCase() ===
					report.reportId.toLowerCase()
			) {
				reportIndex = i;
				break;
			}
		}

		self.state.currentReports[reportIndex].tables = tables;

		for (var table in self.state.currentReports[reportIndex].tables) {
			(function (table) {
				self.state.currentReports[reportIndex].tables[
					table
				].tabulator = new Tabulator(
					"#" + self.options.id + "-" + report.reportId + "-" + table,
					{
						index: "mapriskId",
						autoResize: true,
						columns: tables[table].tableColumns,
						data: tables[table].flatData,
						height: "auto",
						layout: "fitDataFill",
						layoutColumnsOnNewData: false,
						resizableColumns: false,
						sorting: false,
						initialFilter: tables[table].initialFilters,
						renderComplete: function () {
							$(".loading-overlay").removeClass("active");
						},
						tableBuilt: function () {
							$("#" + self.options.id + "-" + report.reportId + "-" + table)
								.prev(".view-heading")
								.find(".view-heading-dropdown")
								.on("change", function () {
									self.state.currentReports[reportIndex].tables[
										table
									].tabulator.setFilter(
										"aggregateSummarySubTableName",
										"=",
										$(this).val()
									);
								});
						}
					}
				);
			})(table);
		}
	};

	self.updateReport = function (report) {
		self.addToggle(report.reportId);
		self.addTables(report);
	};

	self.removeReport = function (reportId) {
		if (
			self.state.activeReport &&
			self.state.activeReport.toLowerCase() === reportId.toLowerCase()
		) {
			self.state.activeReport = null;
		}

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId == reportId
			) {
				self.$viewContainer
					.find(
						"." +
							self.options.wrapperBaseClass +
							'[data-reportId="' +
							reportId +
							'"]'
					)
					.remove();

				self.events.detachToggleClick(reportId);
				$(
					".reportNameHeaderReportViewToggleContainer #chkReportViewControl_" +
						reportId
				).remove();
				self.state.currentReports.splice(i, 1);
			}
		}

		if (self.state.currentReports.length <= 0) {
			self.hideView();
		}
	};

	self.removeAllReports = function () {
		self.state = JSON.parse(JSON.stringify(self.stateModel));

		self.hideView();
		self.$viewContainer
			.find("." + self.options.wrapperBaseClass)
			.each(function () {
				$(this).remove();
			});
		self.events.detachAllToggleClicks();
	};

	self.addToggle = function (reportId) {
		if (
			$(
				"#divAdditionalReportInformation_" +
					reportId +
					"_reportTableRow .report-toolbar .reportNameHeaderReportViewToggleContainer .chkReportViewControl"
			).length <= 0
		) {
			var $toggleWrapper = $(
				"#divAdditionalReportInformation_" +
					reportId +
					"_reportTableRow .report-toolbar .reportNameHeaderReportViewToggleContainer"
			);
			var $toggleIcon = $(
				'<a href="#" id="chkReportViewControl_' +
					reportId +
					'" class="chkReportViewControl" title="Table Report View"><i class="icon-expand"></i></a>'
			);

			$toggleWrapper.append($toggleIcon);

			self.events.detachToggleClick(reportId);
			self.events.attachToggleClick(reportId);
		}
	};

	self.enableReportButton = function (reportId) {
		$(
			".reportNameHeaderReportViewToggleContainer #chkReportViewControl_" +
				reportId
		).removeClass("disabled");
	};

	self.disableReportButton = function (reportId) {
		$(
			".reportNameHeaderReportViewToggleContainer #chkReportViewControl_" +
				reportId
		).addClass("disabled");
	};

	self.viewReport = function (reportId) {
		// hide all reports
		self.$viewContainer
			.find("." + self.options.wrapperBaseClass)
			.removeClass("active");

		// show specified report
		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId.toLowerCase() ===
				reportId.toLowerCase()
			) {
				for (var table in self.state.currentReports[i].tables) {
					self.$viewContainer
						.find("#" + self.options.id + "-" + reportId + "-" + table)
						.addClass("active");
				}
			}
		}

		self.state.activeReport = reportId;

		self.showView();
	};

	self.getFilterParams = function (reportId) {
		var params = {};

		$("#" + self.options.id + "-" + reportId)
			.find(".tabulator-header-filter input")
			.each(function () {
				var col = $(this).parent().siblings(".tabulator-col-title").text();
				var value = $(this).val();

				if (value) {
					value = value.split("||");

					if (Array.isArray(value)) {
						value.forEach(function (val, index, array) {
							array[index] = val.trim();
						});

						params[col] = value;
					} else {
						params[col] = [value];
					}
				}
			});

		return params;
	};

	self.events = {
		exitClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.exitButtonBaseClass,
				function () {
					self.hideView();
				}
			);
		},

		changeViewClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.changeViewButtonBaseClass,
				function () {
					self.changeView();
				}
			);
		},

		exportClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.exportButtonBaseClass,
				function () {
					self.state.currentReports.forEach(function (report) {
						if (report.reportId === self.state.activeReport) {
							var date = moment().format("YYYY-MM-DD_HH-mm-ss");
							for (var table in report.tables) {
								report.tables[table].tabulator.download(
									"csv",
									report.reportId + "_" + date + ".csv"
								);
							}
						}
					});
				}
			);
		},

		attachToggleClick: function (reportId) {
			$(".report-toolbar").on(
				"click." + reportId,
				"#chkReportViewControl_" + reportId,
				function (e) {
					e.preventDefault();
					var otherViewEnabled = false;

					if ($(this).hasClass("active")) {
						$(this).removeClass("active");
					} else {
						$(".report-toolbar .chkReportViewControl").removeClass("active");
						$(this).addClass("active");
						self.viewReport(reportId);
					}

					$(".report-toolbar .chkReportViewControl").each(function () {
						if ($(this).hasClass("active")) {
							otherViewEnabled = true;
						}
					});

					if (!otherViewEnabled) {
						self.hideView();
					}
				}
			);
		},

		detachToggleClick: function (reportId) {
			$(".report-toolbar").off("click", "#chkReportViewControl_" + reportId);
		},

		detachAllToggleClicks: function () {
			$(".report-toolbar").off("click", ".chkReportViewControl");
		}
	};
};

var mrSlider = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-slider-container",
		containerClassNames: "",
		containerInnerBaseClass: "mr-slider-container-inner",
		containerInnerClassNames: "",
		bgBaseClass: "mr-slider-bg",
		bgClassNames: "",
		bgFillBaseClass: "mr-slider-bg-fill",
		bgFillClassNames: "",
		handleBaseClass: "mr-slider-handle",
		handleClassNames: "",
		initialPosition: 1
	};

	self.state = {
		dragging: false,
		position: null,
		get: function (prop) {
			return self.state[prop];
		},
		set: function (prop, val) {
			self.state[prop] = val;

			if (prop === "position") {
				$(document).trigger("slider-position-change." + self.options.id);
			}
		}
	};

	self.init = function (options) {
		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log("Slider error: no parent container defined in options");

			return;
		}

		if (!self.options.id) {
			console.log("Slider error: no id specified");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.create();
		} else {
			console.log("Slider error: Cannot create duplicate Slider container.");

			return;
		}
	};

	self.create = function () {
		self.$sliderContainer = $("<div></div>");
		self.$sliderContainerInner = $("<div></div>");
		self.$sliderBg = $("<div></div>");
		self.$sliderBgFill = $("<div></div>");
		self.$sliderHandle = $("<div></div>");

		self.$sliderContainer.attr("id", self.options.id);
		self.$sliderContainer.addClass(
			self.options.id +
				" " +
				self.options.containerBaseClass +
				" " +
				self.options.containerClassNames
		);
		self.$sliderContainerInner.addClass(self.options.containerInnerBaseClass);
		self.$sliderBg.addClass(
			self.options.bgBaseClass + " " + self.options.bgClassNames
		);
		self.$sliderBgFill.addClass(
			self.options.bgFillBaseClass + " " + self.options.bgFillClassNames
		);
		self.$sliderHandle.addClass(
			self.options.handleBaseClass + " " + self.options.handleClassNames
		);

		self.$sliderContainerInner
			.append(self.$sliderBg)
			.append(self.$sliderBgFill)
			.append(self.$sliderHandle);

		self.$sliderContainer.append(self.$sliderContainerInner);

		$(self.options.parentContainer).append(self.$sliderContainer);

		self.setHandlePosition(self.options.initialPosition);
		self.events.handle();
	};

	self.destroy = function () {
		self.$sliderContainer.remove();
	};

	self.enable = function () {
		self.$sliderContainer.addClass("active");
	};

	self.disable = function () {
		self.$sliderContainer.removeClass("active");
	};

	self.setHandlePosition = function (position) {
		var sliderWidth = self.$sliderContainer.outerWidth();
		var handleWidth = self.$sliderHandle.outerWidth();
		var handleCenter = handleWidth / 2;
		var absolutePosition = sliderWidth * position;
		var relativePosition = absolutePosition * 100;

		self.$sliderHandle.css({ left: absolutePosition + "px" });
		self.$sliderBgFill.css({ width: absolutePosition + "px" });
		self.position = relativePosition;
	};

	self.moveHandle = function (e) {
		var sliderWidth = self.$sliderContainer.outerWidth();
		var handleWidth = self.$sliderHandle.outerWidth();
		var handleCenter = handleWidth / 2;
		var absoluteMouseX = e.pageX - self.$sliderContainer.offset().left;
		var relativeMouseX = (absoluteMouseX / sliderWidth) * 100;

		if (absoluteMouseX < 0) {
			absoluteMouseX = 0;
			relativeMouseX = 0;
		}

		if (absoluteMouseX > sliderWidth) {
			absoluteMouseX = sliderWidth;
			relativeMouseX = 100;
		}

		self.$sliderHandle.css({ left: absoluteMouseX + "px" });
		self.$sliderBgFill.css({ width: absoluteMouseX + "px" });
		self.state.dragging = true;
		self.state.set("position", relativeMouseX);
	};

	self.events = {
		handle: function () {
			self.events.attachHandleMouseEnter();
			self.events.attachHandleMouseLeave();
			self.events.attachHandleMouseDown();
		},

		attachHandleMouseEnter: function () {
			self.$sliderHandle.on("mouseenter." + self.options.id, function (e) {
				$("html").addClass("mr-slider-grab");
			});
		},

		attachHandleMouseLeave: function () {
			self.$sliderHandle.on("mouseleave." + self.options.id, function (e) {
				$("html").removeClass("mr-slider-grab");
			});
		},

		attachHandleMouseDown: function () {
			self.$sliderHandle.on("mousedown." + self.options.id, function (e) {
				$("html").addClass("mr-slider-grabbing");

				self.events.attachHandleDrag();
				self.events.attachHandleMouseUp();
			});
		},

		detachHandleMouseDown: function () {
			self.$sliderHandle.off("mousedown." + self.options.id);
		},

		attachHandleDrag: function () {
			$(document).on("mousemove." + self.options.id, self.moveHandle);
		},

		detachHandleDrag: function () {
			$(document).off("mousemove." + self.options.id);
		},

		attachHandleMouseUp: function () {
			$(document).on("mouseup." + self.options.id, function (e) {
				$("html").removeClass("mr-slider-grabbing");
				self.state.dragging = false;
				self.events.detachHandleDrag();
				self.events.detachHandleMouseUp();
			});
		},

		detachHandleMouseUp: function () {
			$(document).off("mouseup." + self.options.id);
		}
	};
};

window.rerunReportOnModelInputChange = function (reportId) {
	var reportData = setReportRequestData(reportId);

	invokeReportRequest(
		window.currentPoiInfo.geoResult,
		window.currentPoiInfo.poi,
		reportId,
		reportData,
		window.currentPoiInfo.layerObj
	);
};

var attachPaginationToInfoWindows = function () {
	$(".bodyContentMainWrapper").on(
		"click.infoWindowPagination",
		".info-window-page-link",
		function () {
			var $location = $(this).parents(".info-window-location");
			var $container;
			var currentIndex;
			var maxLength = parseInt(
				$(this).parents(".info-window-pagination").attr("data-max-num")
			);

			if ($location.length > 0) {
				$container = $location;
			} else {
				$container = $(this).parents(".info-window-container");
			}

			$container
				.find(".info-window-wrappers")
				.find(".info-window-wrapper")
				.each(function () {
					if ($(this).hasClass("active")) {
						currentIndex = $(this).index();
					}
				});

			if ($(this).hasClass("info-window-page-link-previous")) {
				if (currentIndex - 1 < 0) {
					currentIndex = maxLength;
				} else {
					currentIndex--;
				}
			} else if ($(this).hasClass("info-window-page-link-next")) {
				if (currentIndex + 1 > maxLength) {
					currentIndex = 0;
				} else {
					currentIndex++;
				}
			}

			$(this)
				.parents(".info-window-pagination")
				.attr("data-current-page", currentIndex + 1);

			$container
				.find(".info-window-page-number")
				.text(parseInt(currentIndex) + 1);
			$container.find(".info-window-wrapper.active").removeClass("active");
			$container
				.find(".info-window-wrapper")
				.eq(currentIndex)
				.addClass("active");
		}
	);
};

window.goToInfoWindowPage = function (propertyId) {
	$(".info-window-wrapper").each(function () {
		if ($(this).attr("data-property-id").toString() === propertyId.toString()) {
			var $container = $(this).parents(".info-window-container");
			var currentIndex = $(this).index();

			$container
				.find("info-window-pagination")
				.attr("data-current-page", currentIndex + 1);
			$container
				.find(".info-window-page-number")
				.text(parseInt(currentIndex) + 1);
			$container.find(".info-window-wrapper.active").removeClass("active");
			$(this).addClass("active");
		}
	});
};

var extractMaxRadius = function (radius) {
	var max = null;

	if (!isNaN(radius)) {
		max = radius;
	} else {
		if (radius.max && !isNaN(radius.max) && radius.max > 0) {
			max = radius.max;
		}
	}

	return max;
};

window.aggregateFeaturesInRadius = function (reportId) {
	var radiusOverlays = [];
	var outermostRadius;
	var contentBlocks = [];

	if (
		!window.mapRiskController.reportOverlays ||
		!window.mapRiskController.reportOverlays.radii ||
		window.mapRiskController.reportOverlays.radii.length < 1
	) {
		return;
	}

	window.mapRiskController.reportOverlays.radii.forEach(function (
		radiusOverlay
	) {
		if (radiusOverlay.reportId.toLowerCase() === reportId.toLowerCase()) {
			radiusOverlays.push(radiusOverlay.radius);
		}
	});

	outermostRadius = radiusOverlays.reduce(function (a, b) {
		return Math.max(a, b);
	});

	for (var report in window.mrState.reportResults) {
		if (report.toLowerCase() === reportId.toLowerCase()) {
			window.mrState.mrClickableOverrides[reportId].currentMarkers.forEach(
				function (detail) {
					var radius = extractMaxRadius(detail.radius);

					if (radius) {
						var radiusUnits =
							typeof detail.radiusUnits !== "undefined"
								? detail.radiusUnits
								: null;

						var radiusInMeters = window.mapRiskController.convertUnits(
							radius,
							radiusUnits,
							"meters"
						);

						if (radiusInMeters <= outermostRadius) {
							contentBlocks.push(detail);
						}
					}
				}
			);
		}
	}

	if (contentBlocks.length > 0) {
		window.mapRiskController.reportOverlays.radii.forEach(function (
			radiusOverlay
		) {
			if (radiusOverlay.radius === outermostRadius) {
				radiusOverlay.setOptions({
					clickable: true,
					fillOpacity: 0.3
				});

				contentBlocks.sort(function (a, b) {
					var disp = {};
					var poi = new google.maps.LatLng(
						window.currentPoiInfo.poi.lat,
						window.currentPoiInfo.poi.lng
					);
					var featurePoi = {
						a: {},
						b: {}
					};

					var poiA = [a.latitude, a.longitude];
					var poiB = [b.latitude, b.longitude];

					featurePoi.a = new google.maps.LatLng(poiA[0], poiA[1]);
					featurePoi.b = new google.maps.LatLng(poiB[0], poiB[1]);

					disp.a = google.maps.geometry.spherical.computeDistanceBetween(
						featurePoi.a,
						poi
					);
					disp.b = google.maps.geometry.spherical.computeDistanceBetween(
						featurePoi.b,
						poi
					);

					return disp.a - disp.b;
				});

				var contentDiv =
					'<div class="info-window-container info-window-paginated" id="info-window-container-' +
					radiusOverlay.radius +
					'">';

				if (contentBlocks.length > 1) {
					contentDiv +=
						'<div class="info-window-pagination" data-max-num="' +
						(contentBlocks.length - 1) +
						'">';
					contentDiv +=
						'<span class="info-window-page-link info-window-page-link-previous">Prev</span>';
					contentDiv +=
						'<span class="info-window-page-current"><span class="info-window-page-number">1</span> of ' +
						contentBlocks.length +
						"</span>";
					contentDiv +=
						'<span class="info-window-page-link info-window-page-link-next">Next</span>';
					contentDiv += "</div>";
				}

				contentDiv += '<div class="info-window-wrappers">';

				contentBlocks.forEach(function (property, index) {
					var active = index === 0 ? " active" : "";
					var contentWrapper =
						'<div class="info-window-wrapper' + active + '">';
					var contentString = '<table class="info-window-table">';

					for (var propertyIndex in property) {
						contentString += '<tr class="info-window-table-row">';
						contentString +=
							'<th class="info-window-table-cell info-window-table-cell-heading" scope="row">' +
							propertyIndex +
							"</th>";
						contentString +=
							'<td class="info-window-table-cell">' +
							property[propertyIndex] +
							"</td>";
						contentString += "</tr>";
					}

					contentString += "</table>";

					contentWrapper += contentString + "</div>";
					contentDiv += contentWrapper;
				});

				contentDiv += "</div>";

				contentDiv += "</div>";

				if (radiusOverlay.infoWindow) {
					radiusOverlay.infoWindow.setMap(null);
					delete radiusOverlay.infoWindow;
				}

				radiusOverlay.infoWindow = new google.maps.InfoWindow({
					content: contentDiv,
					position: radiusOverlay.center
				});

				radiusOverlay.clickListener = radiusOverlay.addListener(
					"click",
					function () {
						radiusOverlay.infoWindow.close();

						radiusOverlay.infoWindow.open(self.map, radiusOverlay);
					}
				);
			}
		});
	}
};

window.clearAggregateFeaturesInRadius = function (reportId) {
	if (
		window.mapRiskController.radiusOverlays &&
		window.mapRiskController.radiusOverlays[reportId] &&
		window.mapRiskController.radiusOverlays[reportId].radii &&
		window.mapRiskController.radiusOverlays[reportId].radii.length > 0
	) {
		window.mapRiskController.radiusOverlays[reportId].radii.forEach(function (
			radius
		) {
			radius.overlay.setOptions({
				clickable: false,
				fillOpacity: 0
			});
		});
	}
};

function remapReplacementCostKeys(config, reportId, parameterName) {
	const keyMap = utils.getReportParameterByName(
		window.reportConfigurationModel,
		reportId,
		parameterName
	);

	for (var type in keyMap) {
		let $productWrapper = $(
			'.product-wrapper[data-occupancy-type="' + type + '"]'
		);

		// Clear the list of preset options for each property type specified in the parameter
		$productWrapper
			.find('.model-input[name="architecturalStyle"] > option')
			.each(function () {
				$(this).remove();
			});

		// Inject the new list of options
		for (var key in keyMap[type]) {
			var $opt = $(
				'<option value="' + keyMap[type][key] + '">' + key + "</option>"
			);

			$productWrapper
				.find('.model-input[name="architecturalStyle"]')
				.prepend($opt);
		}
	}
}

window.getClickableMarkerByLatLng = function (reportId, layerId, lat, lng) {
	var foundMarker;

	if (
		window.mapRiskController.layers[reportId] &&
		window.mapRiskController.layers[reportId][layerId] &&
		window.mapRiskController.layers[reportId][layerId].interactiveFeatures
	) {
		for (
			var i = 0;
			i <
			window.mapRiskController.layers[reportId][layerId].interactiveFeatures
				.length;
			i++
		) {
			var marker =
				window.mapRiskController.layers[reportId][layerId].interactiveFeatures[
					i
				];
			var markerLat = marker.mrLatitude;
			var markerLng = marker.mrLongitude;

			if (
				markerLat.toString() === lat.toString() &&
				markerLng.toString() === lng.toString()
			) {
				foundMarker =
					window.mapRiskController.layers[reportId][layerId]
						.interactiveFeatures[i];
				break;
			}
		}
	}

	return foundMarker;
};

const getReportById = function (reportId) {
	var reportDataObj;

	if ($("#txtInputReportAddress").val() === "") {
		dialog.openDialog(CONSTANTS.DIALOG.PLEASE_ENTER_AN_ADDRESS);

		return;
	}

	var validationResults = reportValidation.validate(window.reportRequestList);
	var validationPassed = reportValidation.isValid(validationResults);

	reportValidation.hideInvalidStatus();

	if (validationPassed === true) {
		if (reportId === "geocode") {
			reportDataObj = {
				success: true,
				response: {
					reportResults: { geocode: window.currentPoiInfo.geoResult }
				},
				request: window.currentPoiInfo.request
			};

			appendReportResult(
				window.currentPoiInfo.geoResult,
				reportDataObj,
				reportId,
				window.reportRequestList[reportId],
				window.currentPoiInfo.poi,
				window.currentPoiInfo.layerObj
			);
		} else {
			reportDataObj = setReportRequestData(reportId);

			invokeReportRequest(
				window.currentPoiInfo.geoResult,
				window.currentPoiInfo.poi,
				reportId,
				reportDataObj,
				window.currentPoiInfo.layerObj
			);
		}
	} else {
		for (var reportId in validationResults) {
			setGetReportButtonState(reportId, "Get Report", false);

			var warningString = [];
			for (var input in validationResults[reportId].inputs) {
				if (validationResults[reportId].inputs[input].valid === false) {
					if (validationResults[reportId].inputs[input].required) {
						warningString.push(
							validationResults[reportId].inputs[input].label + " is required"
						);
					}

					if (validationResults[reportId].inputs[input].message) {
						warningString.push(
							validationResults[reportId].inputs[input].label +
								" " +
								validationResults[reportId].inputs[input].message
						);
					}
				}
			}

			warningString = warningString.join("<br>");
			warningString += "<br>";

			warningString = warningString.slice(0, -4);
			window.mapUNC.pushMessage(
				validationResults[reportId].name +
					" has a Model Input issue:" +
					"<br>" +
					warningString,
				"warning",
				15000
			);
		}

		reportValidation.showInvalidStatus(validationResults);
	}
};

var restrictNumberInput = function (el) {
	var $el = $(el);
	var val = $el.val();

	if ($el.attr("min")) {
		if (
			!isNaN(Number($el.val())) &&
			Number($el.val()) < Number($el.attr("min"))
		) {
			val = $el.attr("min");
		}
	}

	if ($el.attr("max")) {
		if (
			!isNaN(Number($el.val())) &&
			Number($el.val()) > Number($el.attr("max"))
		) {
			val = $el.attr("max");
		}
	}

	return val;
};

window.mapUNC = new mrUserNotificationCenter();
var mainReportView = new ReportView();
var accordions = new window.accordionController();
var reportValidation = new mrReportValidation();

document.addEventListener("google-maps-callback", function () {
	initFromGoogleMapsCallback();
});

document.addEventListener("page-load-callback", function () {
	initGoogleAnalytics(window.email);
});

document.addEventListener("open-dialog-callback", function () {
	initOpenDialog();
});

$(document).ready(function () {
	// Track browser version in analytics (only if user is logged in, impersonating, or following url link)
	if (window.id) {
		let label = {
			_id: window.id,
			request_type: utils.getAuthenticationType(),
			browser_name: window.mrState.browser.name,
			browser_version: window.mrState.browser.version
		};

		eventTracker("technology", "browser_usage", label);
	}
	//  INSTANTIATE GLOSSARY/LEGEND/TOOLTIPS CONTROLLER
	window.tooltips = new Tooltips({
		fileNotFoundPath:
			"https://s3.amazonaws.com/maprisk.static/404-file-not-found.png"
	});

	// INSTANTIATE MENU CONTROLLERS
	window.reportsMenu = new Sidebar("reports", state.sidebar.reports);
	window.resultsMenu = new Sidebar("results", state.sidebar.results);

	// INSTANTIATE SEARCH CONTROLLERS
	window.addressSearch = new Search(
		state.search,
		window.reportsMenu,
		window.resultsMenu
	);

	if (
		window.mrState.viewOnly.enabled === true &&
		window.mrState.viewOnly.modifyAddressBar !== true
	) {
		window.addressSearch.disableSearchButton();
	}

	$("#logoutBtn").on("click", function (e) {
		e.preventDefault();

		var logOutError = function (err) {
			console.log("Error logging out");
			console.log(err);
		};

		var logOutSuccess = function (response) {
			if (response.success === true) {
				document.cookie =
					"token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

				location.href = location.origin;
			}
		};

		var accountClient = new AccountClient();

		accountClient.logOut(logOutError, logOutSuccess);
	});

	$("#txtInspection_InspectionDate_index").datepicker();
	$("#txtInspection_InspectionDate_batch").datepicker();

	// Claims Documentation
	$("#txt_claimsDocumentation_LossDate_index").datepicker();
	$("#txt_claimsDocumentation_LossDate_batch").datepicker();
	$("#txt_claimsDocumentation_ReportedDate_index").datepicker();
	$("#txt_claimsDocumentation_ReportedDate_batch").datepicker();
	$("#txt_claimsDocumentation_PolicyEffective_index").datepicker();
	$("#txt_claimsDocumentation_PolicyEffective_batch").datepicker();
	$("#txt_claimsDocumentation_PolicyExpires_index").datepicker();
	$("#txt_claimsDocumentation_PolicyExpires_batch").datepicker();
	// End Claims Documentation

	//Allied Trust Claims Documentation
	$("#txt_claimsDocumentation_ClaimDate_index").datepicker();
	$("#txt_claimsDocumentation_ClaimDate_batch").datepicker();
	//End Allied Trust Claims Documentation

	$("#txtHailUwGuidelinesDate_index").datepicker();
	$("#txtHailUwGuidelinesDate_batch").datepicker();

	$("#txtWindStartDate_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#txtWindStartDate_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#txtWindEndDate_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#txtWindEndDate_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});

	$("#model-input-hailApi-date-start_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailApi-date-end_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailApi-date-start_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailApi-date-end_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});

	$("#model-input-hailStrikeApi-date-start_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailStrikeApi-date-end_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailStrikeApi-date-start_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailStrikeApi-date-end_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});

	$("#model-input-hailVerification-date-start_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerificationHs-date-start_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerification-date-end_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerificationHs-date-end_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerification-date-start_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerificationHs-date-start_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerification-date-end_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});
	$("#model-input-hailVerificationHs-date-end_batch").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});

	$("#model-input-hailSizeAndDamage-convective-date_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd"
	});

	$("#model-input-hailCanopyStormFootprints-convective-date_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd",
		onSelect: function () {
			let reportId = "hailCanopyStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	});
	$("#model-input-hailStormFootprints-convective-date_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd",
		onSelect: function () {
			let reportId = "hailStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	});
	$("#model-input-hailCanopyStormFootprints-convective-date_index").on(
		"change",
		function () {
			let reportId = "hailCanopyStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailStormFootprints-convective-date_index").on(
		"change",
		function () {
			let reportId = "hailStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailCanopyStormFootprints-convective-date-buffer_index").on(
		"change",
		function () {
			let reportId = "hailCanopyStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailStormFootprints-convective-date-buffer_index").on(
		"change",
		function () {
			let reportId = "hailStormFootprints";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);

	$("#model-input-hailCanopyVerification-convective-date_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd",
		onSelect: function () {
			let reportId = "hailCanopyVerification";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	});
	$("#model-input-hailVerificationC-convective-date_index").datepicker({
		changeMonth: true,
		changeYear: true,
		dateFormat: "yy-mm-dd",
		onSelect: function () {
			let reportId = "hailVerificationC";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	});
	$("#model-input-hailCanopyVerification-convective-date_index").on(
		"change",
		function () {
			let reportId = "hailCanopyVerification";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailVerificationC-convective-date_index").on(
		"change",
		function () {
			let reportId = "hailVerificationC";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailCanopyVerification-convective-date-buffer_index").on(
		"change",
		function () {
			let reportId = "hailCanopyVerification";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);
	$("#model-input-hailVerificationC-convective-date-buffer_index").on(
		"change",
		function () {
			let reportId = "hailVerificationC";
			window.generateCanopyLayersFromModelInput(reportId);
		}
	);

	// force restriction of number-type inputs to min and max values on keyup
	$(document).on("keyup", 'input[type="number"]', function () {
		$(this).val(restrictNumberInput(this));
	});

	$(document).on("click", "#button-prefill-replacementCost_index", function () {
		if (!$("#txtInputReportAddress").val()) {
			dialog.openDialog(CONSTANTS.DIALOG.PLEASE_ENTER_AN_ADDRESS);

			return;
		}

		var btn = $(this);
		var view = btn.attr("data-viewId");

		btn.attr("disabled", "disabled");

		var populateReplacementCostData = function (reportData) {
			if (reportData.success) {
				reportData.response = reportData.response || {};
				reportData.response.reportResults =
					reportData.response.reportResults || {};

				let prefillReportResultName;
				let $occupancyType = $("#replacementCost_occupancyType_index");
				let $productWrapper = $(
					'#divreplacementCost_index .product-wrapper[data-occupancy-type="' +
						$occupancyType.val().toLowerCase() +
						'"]'
				);

				for (var reportResultName in reportData.response.reportResults) {
					prefillReportResultName =
						reportResultName.toLowerCase().indexOf("prefill") !== -1
							? reportResultName
							: prefillReportResultName;
				}

				reportData.response.reportResults.prefill =
					reportData.response.reportResults[prefillReportResultName] || {};

				var yearBuilt, grossLivingAreaSquareFeet;

				for (var prop in reportData.response.reportResults.prefill) {
					if (prop.toLowerCase().indexOf("yearbuilt") !== -1) {
						yearBuilt = reportData.response.reportResults.prefill[prop];
					} else if (
						prop.toLowerCase().indexOf("grosslivingareasquarefeet") !== -1
					) {
						grossLivingAreaSquareFeet =
							reportData.response.reportResults.prefill[prop];
					}
				}

				$productWrapper.find('.model-input[name="yearBuilt"]').val(yearBuilt);
				$productWrapper
					.find('.model-input[name="sqFootage"]')
					.val(grossLivingAreaSquareFeet);
			}

			btn.attr("disabled", null);
		};

		var geocodeCallback = function (err, geocodeResponse) {
			//Err doesnt have message text pertaining to why the error occured
			//In this case we will suggest they try again
			if (err) {
				return;
			}

			if (!geocodeResponse?.response?.geocodeResults) {
				btn.attr("disabled", null);
				return;
			}

			var poi = geocodeResponse.response.geocodeResults[0];

			poi.lat = poi.latitude;
			poi.lng = poi.longitude;

			var prefillReportId = null;

			for (var reportConfigName in window.reportConfigurationModel) {
				prefillReportId =
					reportConfigName.toLowerCase().indexOf("prefill") !== -1
						? reportConfigName
						: prefillReportId;
			}

			var data = {
				poi: poi,
				reportList: prefillReportId
			};

			var getReportsCallback = function (err, reportResponse) {
				if (err) {
					dialog.openDialog(CONSTANTS.DIALOG.GEOCODE_SERVER_ERROR);
				}

				populateReplacementCostData(reportResponse);
			};

			var reportsClient = new ReportsClient();

			reportsClient.getReports(data, getReportsCallback);
		};

		var inputAddress = $("#txtInputReportAddress").val();
		var data = { addressLine: inputAddress };

		var geocodeClient = new GeocodeClient();

		geocodeClient.geocodeAddress(data, geocodeCallback);
	});

	$(document).on(
		"click",
		"#button-prefill-replacementCostMobileManufacturedHomes_index",
		function () {
			if (!$("#txtInputReportAddress").val()) {
				dialog.openDialog(CONSTANTS.DIALOG.PLEASE_ENTER_AN_ADDRESS);

				return;
			}

			var btn = $(this);

			var populateReplacementCostMMHData = function (reportData) {
				if (reportData.success) {
					reportData.response = reportData.response || {};
					reportData.response.reportResults =
						reportData.response.reportResults || {};

					let prefillReportResultName;

					for (var reportResultName in reportData.response.reportResults) {
						prefillReportResultName =
							reportResultName.toLowerCase().indexOf("prefill") !== -1
								? reportResultName
								: prefillReportResultName;
					}

					reportData.response.reportResults.prefill =
						reportData.response.reportResults[prefillReportResultName] || {};

					var yearBuilt, grossLivingAreaSquareFeet;

					for (var prop in reportData.response.reportResults.prefill) {
						if (prop.toLowerCase().indexOf("yearbuilt") !== -1) {
							yearBuilt = reportData.response.reportResults.prefill[prop];
						} else if (
							prop.toLowerCase().indexOf("grosslivingareasquarefeet") !== -1
						) {
							grossLivingAreaSquareFeet =
								reportData.response.reportResults.prefill[prop];
						}
					}
					$(`#replacementCostMobileManufacturedHomes_sqFootage_index`).val(
						grossLivingAreaSquareFeet
					);
					$(`#replacementCostMobileManufacturedHomes_yearBuilt_index`).val(
						yearBuilt
					);
				}

				btn.attr("disabled", null);
			};
			var geocodeCallback = function (err, geocodeResponse) {
				//Err doesnt have message text pertaining to why the error occured
				//In this case we will suggest they try again
				if (err) {
					return;
				}

				if (!geocodeResponse?.response?.geocodeResults) {
					btn.attr("disabled", null);
					return;
				}

				var poi = geocodeResponse.response.geocodeResults[0];
				poi.lat = poi.latitude;
				poi.lng = poi.longitude;

				var prefillReportId = null;

				for (var reportConfigName in window.reportConfigurationModel) {
					prefillReportId =
						reportConfigName.toLowerCase().indexOf("prefill") !== -1
							? reportConfigName
							: prefillReportId;
				}
				var data = {
					poi: poi,
					reportList: prefillReportId
				};

				var getReportsCallback = function (err, reportResponse) {
					if (err) {
						dialog.openDialog(CONSTANTS.DIALOG.GEOCODE_SERVER_ERROR);
					}

					populateReplacementCostMMHData(reportResponse);
				};

				var reportsClient = new ReportsClient();
				reportsClient.getReports(data, getReportsCallback);
			};

			var inputAddress = $("#txtInputReportAddress").val();
			var data = { addressLine: inputAddress };

			var geocodeClient = new GeocodeClient();
			geocodeClient.geocodeAddress(data, geocodeCallback);
		}
	);

	function configureReplacementCostReportDataInputs(
		configurationModel,
		occupancyType,
		viewId
	) {
		let replacementCostConfig = configurationModel.replacementcost;

		if (!replacementCostConfig) {
			return;
		}

		occupancyType = occupancyType
			? occupancyType
			: utils.getReportParameterByName(
					configurationModel,
					"replacementCost",
					"DefaultConfiguration"
			  );

		let residentialParam = utils.getReportParameterByName(
			configurationModel,
			"replacementCost",
			"Residential"
		);
		let commercialParam = utils.getReportParameterByName(
			configurationModel,
			"replacementCost",
			"Commercial"
		);
		let residentialIsPro =
			residentialParam?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase().indexOf(
				"residentialpro"
			) > -1
				? true
				: false;
		let residentialIsProWithAdjustment =
			residentialParam?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
				"residentialproadjustment" || false;
		let commercialIsPro =
			commercialParam?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
				"commercialpro" || false;

		let replacementCostSections;

		if (viewId) {
			replacementCostSections = $(
				".divAdditionalInfo.divreplacementCost[data-view='" + viewId + "']"
			);
		} else {
			replacementCostSections = $(".divAdditionalInfo.divreplacementCost");
		}

		if (!replacementCostSections || replacementCostSections.length === 0) {
			return;
		}

		for (let i = 0; i < replacementCostSections.length; i++) {
			let replacementCostSection = replacementCostSections[i];
			let $replacementCostProduct = $(replacementCostSection).find(
				".product-container .product-wrapper"
			);

			$replacementCostProduct.hide();

			if (occupancyType.toLowerCase().indexOf("residential") > -1) {
				if (residentialIsPro && !residentialIsProWithAdjustment) {
					$replacementCostProduct
						.filter('[data-occupancy-type="residential"][data-tier="pro"]')
						.show();
				} else if (residentialIsProWithAdjustment) {
					$replacementCostProduct
						.filter(
							'[data-occupancy-type="residential"][data-tier="pro-with-adjustment"]'
						)
						.show();
				} else if (!residentialIsPro && !residentialIsProWithAdjustment) {
					$replacementCostProduct
						.filter('[data-occupancy-type="residential"][data-tier="lite"]')
						.show();
				}
			} else if (occupancyType.toLowerCase().indexOf("commercial") > -1) {
				if (commercialIsPro) {
					$replacementCostProduct
						.filter('[data-occupancy-type="commercial"][data-tier="pro"]')
						.show();
				} else if (!commercialIsPro) {
					$replacementCostProduct
						.filter('[data-occupancy-type="commercial"][data-tier="lite"]')
						.show();
				}
			}
		}
	}

	$(document).on(
		"customEvent.replacementCost.configureReportDataInputs",
		function eventConfigureReplacementCostReportDataInputs(
			e,
			configurationModel
		) {
			configureReplacementCostReportDataInputs(configurationModel, null, null);
		}
	);

	$("#replacementCost_occupancyType_index").on("change", function () {
		let $occupancyType = $("#replacementCost_occupancyType_index");
		let occupancyType = $occupancyType.val();
		let viewId = $(this).attr("data-viewId");

		configureReplacementCostReportDataInputs(
			window.reportConfigurationModel,
			occupancyType,
			viewId
		);

		let residentialParam = utils.getReportParameterByName(
			window.reportConfigurationModel,
			"replacementCost",
			"Residential"
		);

		let residentialIsProWithAdjustment =
			residentialParam?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
				"residentialproadjustment" || false;

		if (residentialIsProWithAdjustment) {
			window?.mrState?.replacementCost?.otherAreas?.[
				"replacementCost"
			]?.showAddTrigger();
		} else {
			window?.mrState?.replacementCost?.otherAreas?.[
				"replacementCost"
			]?.removeAll();
			window?.mrState?.replacementCost?.otherAreas?.[
				"replacementCost"
			]?.hideAddTrigger();
		}
	});

	$("#replacementCost_occupancyType_index").val("Residential");

	$("#replacementCost_returnACV_index_residential").on("change", function () {
		if ($(this).val() === "yes") {
			$("#replacementCost_returnACV_index_residential_container").show();
		} else {
			$("#replacementCost_returnACV_index_residential_container").hide();
		}
	});

	$("#replacementCost_returnACV_index_commercial").on("change", function () {
		if ($(this).val() === "yes") {
			$("#replacementCost_returnACV_index_commercial_container").show();
		} else {
			$("#replacementCost_returnACV_index_commercial_container").hide();
		}
	});

	$(".selInspection_OccupancyType").on("change", function () {
		var viewId = $(this).attr("data-viewId");
		var val = $(this).val();
		var architecturalStyleTarget = $(
			"#selInspection_ArchitecturalStyle_" + viewId + "_" + val
		);

		$(".selInspection_ArchitecturalStyle_" + viewId).hide();
		architecturalStyleTarget.show();
	});
	$(".selInspection_OccupancyType").val("Residential");

	$(".btn-moreInfo").on("click", function (e) {
		e.stopPropagation();
		$(".divAdditionalInfo").toggle();
	});

	$("#update-contact-form").on("submit", function (e) {
		e.preventDefault();
		// serializeArray() grabs all the fields and their values
		var payload = $(this)
			.serializeArray()
			.reduce((p, field) => {
				p[field.name] = field.value;

				return p;
			}, {});

		var updateContactError = function (err) {
			window.mapUNC.pushMessage(
				window.mrUserNotifications.accountUpdateError,
				"error"
			);
		};

		var updateContactSuccess = function (reponse) {
			dialog.dismissAccountSettingsDialog();
			window.mapUNC.pushMessage(
				window.mrUserNotifications.accountUpdateSuccess,
				"info"
			);
		};

		var accountClient = new AccountClient();

		accountClient.updateContact(
			payload,
			updateContactError,
			updateContactSuccess
		);
	});

	$("tr.expandable").on("click", function (e) {
		$(this).find(".divExpandable").toggleClass("expanded");
	});

	$("#purchase-credits-amount").keypress(function (e) {
		var code = e.keyCode || e.which;

		if (code < 48 || code > 57) {
			e.preventDefault();
		}
	});

	$("#purchase-credits-amount").val("100");

	$("#batch-button").on("click", function () {
		if ($(this).hasClass("active")) {
			dialog.dismissBatchDialog();
		} else {
			if (window.mrState.batchEnabled) {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH);
			} else if (
				!window.mrState.batchEnabled &&
				window.mrState.viewOnly.enabled === true
			) {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_DISABLED_VIEW_ONLY);
			} else {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_DISABLED);
			}
		}
	});

	$("#account-tools-trigger").on("click", function (e) {
		e.preventDefault();

		let $dropdown = $(this).siblings(".account-tools-dropdown");

		if ($dropdown.hasClass("active")) {
			$dropdown.removeClass("active");
		} else {
			$dropdown.addClass("active");
		}
	});

	$(document).on("click", ".account-profile-backdrop", function (e) {});

	$(".rightSidebarContent .chkExecuteReport").on("click", function (e) {
		let checked = $(this).is(":checked");

		let reportText;
		let $newRow;
		let $reportResultsHeader = $('<div class="reportResultsHeader"></div>');
		let $additionalInfoRow;
		let $reportToolbar = $('<div class="report-toolbar"></div>');
		let reportId;
		let additionalInfoRowId;
		let reportGroupName;
		let layers;
		let reportGroupSearchResult;
		let $reportGroupRow, $reportGroupRowTd;
		let reportGroupId;
		let $reportGroupResultRow;
		let reportTemplate =
			$(this).attr("reportTemplate") === "undefined"
				? null
				: $(this).attr("reportTemplate");
		let glossaryUrl;
		let glossaryUrlPath = "https://s3.amazonaws.com/maprisk.static/glossaries";

		if ($(".rightSidebarContent .chkExecuteReport:checked").length >= 1) {
			window.addressSearch.enableSearchButton();
		} else {
			window.addressSearch.disableSearchButton();
		}

		if (checked) {
			reportGroupId = $(this).attr("reportGroupId");
			reportGroupName = $(this).attr("reportGroupName");

			layers = $(this).attr("layers");
			if (typeof layers !== "undefined" && layers.length > 0) {
				layers = JSON.parse(layers);
			}

			reportId = $(this).val();

			glossaryUrl =
				utils.getReportParameterByName(
					window.reportConfigurationModel,
					reportId.toLowerCase(),
					"glossaryUrl"
				) || `${glossaryUrlPath}/${reportId}.html`;

			additionalInfoRowId = "divAdditionalReportInformation_" + reportId;
			window.reportRequestList[reportId] = {
				reportGroupId: reportGroupId,
				resultRow: $newRow,
				additionalInfoRowId: additionalInfoRowId,
				layers: []
			};

			$newRow = $(
				"<div id='" +
					additionalInfoRowId +
					"_reportTableRow' class='reportTableRow' data-layers='" +
					JSON.stringify(layers) +
					"' data-report-id='" +
					reportId +
					"'>"
			);

			var getReportBtn = document.createElement("button");

			getReportBtn.setAttribute("data-reportid", reportId);
			getReportBtn.classList.add("btn");
			getReportBtn.classList.add("get-report-btn");
			getReportBtn.innerHTML = "Get Report";

			getReportBtn.onclick = function () {
				setGetReportButtonState(reportId, "Loading...", true);

				if (!window.currentPoiInfo) {
					window.addressSearch.submit();
				} else {
					getReportById(reportId);
				}
			};

			// set up header containers
			var $reportNameHeaderTextContainer = $(
				"<div class='reportNameHeaderTextContainer'></div>"
			);
			var $reportNameText = $(
				'<a href="#" class="reportNameText accordion-trigger" data-accordion-target="#' +
					additionalInfoRowId +
					'" data-accordion-container=".reportResultsHeader" title="Expand Report"></a>'
			);

			// add contents to header containers
			$reportNameHeaderTextContainer.append($reportNameText);

			$newRow.append($reportResultsHeader);

			$additionalInfoRow = $(
				'<div id="' +
					additionalInfoRowId +
					'" class="additionalInfo reportInfo"><table class="table table-striped"><tr><td></td></tr></table></div>'
			);

			var $reportNameHeaderGlossaryContainer = $(
				"<div id='report-name-header-glossary-container_" +
					reportId +
					"' class='report-name-header-glossary-container'/>"
			);

			if (glossaryUrl) {
				$reportNameHeaderGlossaryContainer.attr(
					"data-mr-tooltip-content",
					glossaryUrl
				);

				$reportNameHeaderGlossaryContainer.attr(
					"data-mr-tooltip-content-type",
					"html"
				);

				$reportNameHeaderGlossaryContainer.attr(
					"data-mr-tooltip-type",
					"glossary"
				);

				$reportNameHeaderGlossaryContainer.attr("data-mr-tooltip-id", reportId);

				$reportNameHeaderGlossaryContainer.attr(
					"data-mr-tooltip-attach-to",
					".leftSidebarWrapper"
				);

				$reportNameHeaderGlossaryContainer.attr(
					"data-mr-tooltip-position",
					"right"
				);

				$reportNameHeaderGlossaryContainer.addClass("mr-tooltip-trigger");
			}

			var $reportNameHeaderDataViewToggleContainer = $(
				'<div class="reportNameHeaderDataViewToggleContainer">'
			);
			var $reportNameHeaderReportViewToggleContainer = $(
				'<div class="reportNameHeaderReportViewToggleContainer">'
			);

			var $reportNameHeaderLayerSelectContainer = $(
				"<div class='reportNameHeaderLayerSelectContainer'>"
			);

			// add glossary icon
			var $glossaryIcon = $(
				'<i class="icon-info-circle reportGlossaryImage" title="Report Glossary"></i>'
			);

			$reportNameHeaderGlossaryContainer.append($glossaryIcon);

			// add header containers to header
			$reportResultsHeader.append($reportNameHeaderTextContainer);
			$reportResultsHeader.append(getReportBtn);

			// add icons to report toolbar
			if (glossaryUrl) {
				$reportToolbar.append($reportNameHeaderGlossaryContainer);
			}

			$reportToolbar.append($reportNameHeaderDataViewToggleContainer);
			$reportToolbar.append($reportNameHeaderReportViewToggleContainer);
			$reportToolbar.append($reportNameHeaderLayerSelectContainer);

			$reportToolbar.insertAfter($reportResultsHeader);

			$newRow.append($additionalInfoRow);

			reportGroupSearchResult = searchForReportGroupRow(reportGroupId);

			$reportGroupResultRow = $("#reportGroupWrapper_" + reportGroupId);

			if (!$reportGroupResultRow.length) {
				$reportGroupRow = $(
					"<div id='reportGroup_" +
						reportGroupId +
						"' class='expandable reportResultGroupHeader'></div>"
				);
				$reportGroupRow.attr("reportGroupId", reportGroupId);
				$reportGroupRow.attr("reportGroupName", reportGroupName);
				$reportGroupRow.append(
					$(
						'<a class="accordion-trigger" href="#" data-accordion-target="#reportGroupWrapper_' +
							reportGroupId +
							'" title="Expand Group"><span class="reportGroupName">' +
							reportGroupName +
							"</span></a>"
					)
				);
				$reportGroupResultRow = $(
					"<div id='reportGroupWrapper_" +
						reportGroupId +
						"' class='report-group-wrapper'></div>"
				);
				$reportGroupRow.append($reportGroupResultRow);

				if (reportGroupSearchResult.previous == null) {
					$("#reportResults").prepend($reportGroupRow);
				} else {
					$reportGroupRow.insertAfter(reportGroupSearchResult.previous);
				}
			}

			if (
				reportGroupId.toLowerCase() === "place_information" &&
				$("#trResultMapTableRow").length
			) {
				$newRow.insertBefore("#trResultMapTableRow");
			} else {
				$("#reportGroupWrapper_" + reportGroupId).append($newRow);
			}

			reportText =
				window.reportConfigurationModel[reportId.toLowerCase()].reportName;

			$reportNameText.html(
				"<span class='reportName'>" + reportText + "</span>"
			);

			// if layers, add layer items to layer toggle container
			if (layers && layers.length > 0) {
				let $multiSelectParentContainer = $(
					"#divAdditionalReportInformation_" +
						reportId +
						"_reportTableRow .reportNameHeaderLayerSelectContainer"
				);

				let layerMultiselectVM = new LayerViewModelFactory(
					window.eventBus,
					window.mapRiskController,
					window.mapClickDetection,
					window.reportConfigurationModel,
					window.reportConfigurationModel[reportId.toLowerCase()],
					window.tooltips,
					$multiSelectParentContainer,
					window.wfsClient,
					window.overlayFactory,
					window.mainDataView
				).getLayerViewModel();

				window.mrState.layerMultiselects[reportId] = layerMultiselectVM;
			}
		} else {
			reportId = $(this).val();
			var reportName = this.value;
			var $row = $(
				"#divAdditionalReportInformation_" + reportId + "_reportTableRow"
			);

			window?.mrState?.layerMultiselects?.[reportId]?.unbind();
			delete window.mrState.layerMultiselects[reportId];

			if (
				window.mrState &&
				window.mrState.dataLayers &&
				window.mrState.dataLayers[reportId]
			) {
				delete window.mrState.dataLayers[reportId];
			}

			$("#div" + reportId + "_index .mr-date-picker").val(null);

			if (window.reportsModel[reportName]) {
				delete window.reportsModel[reportName];
			}

			if ($row.parent().children().length == 1) {
				$row.parents(".reportResultGroupHeader").remove();
			} else {
				$row.remove();
			}

			delete window.reportRequestList[reportId];

			window.clearAggregateFeaturesInRadius(reportId);
			window.mainDataView.removeReport(reportId);
			mainReportView.removeReport(reportId);

			if (
				window.mrState &&
				window.mrState.mrClickableOverrides &&
				window.mrState.mrClickableOverrides[reportId]
			) {
				window.mrState.mrClickableOverrides[
					reportId
				].detachBoundsChangeListener();
				window.mrState.mrClickableOverrides[reportId].clearFeatures();
			}

			if (
				window.mainDataView &&
				window.mainDataView.state &&
				window.mainDataView.state.activeReport &&
				window.mainDataView.state.activeReport.toLowerCase() ===
					reportId.toLowerCase()
			) {
				window.mainDataView.state.activeReport = null;
				window.mainDataView.hideView();
			}

			if (
				mainReportView &&
				mainReportView.state &&
				mainReportView.state.activeReport &&
				mainReportView.state.activeReport.toLowerCase() ===
					reportId.toLowerCase()
			) {
				mainReportView.state.activeReport = null;
				mainReportView.hideView();
			}

			if (
				window.mrState.drawingTools.isDrawingToolReportFilteringEnabled === true
			) {
				if (
					window.mapRiskController.mapDrawingToolsFilterControl &&
					window.mapRiskController.mapDrawingToolsFilterControl.state.active
				) {
					var filterTools =
						window.mapRiskController.mapDrawingToolsFilterControl.state
							.filterTools;
					window.mrState.drawingTools.isDrawingToolReportFilteringEnabled = false;
					for (var type in window.mapRiskController.mapDrawingToolsControl.state
						.currentTools) {
						window.mapRiskController.mapDrawingToolsControl.state.currentTools[
							type
						].$control.removeClass("filtering");
					}

					window.mapRiskController.mapDrawingToolsFilterControl.disableDrawingToolsFilteringByName(
						filterTools
					);
					window.mapRiskController.mapDrawingToolsControl.enable();
					window.mapRiskController.drawingManager.setDrawingMode(null);
					window.mapRiskController.mapDrawingToolsControl.activateDrawingToolByType(
						"pan"
					);
				}
			}
		}
	});

	$("#pdf-button").on("click", function () {
		// if no user logged in, don't allow pdf to run
		if (!utils.getCookie("token")) {
			return dialog.openDialog(
				CONSTANTS.DIALOG.NOT_AUTHORIZED_TO_IMPERSONATE,
				function () {
					window.location = window.location.href.split("?")[0];
				}
			);
		}

		var reportRows = Array.prototype.slice.call($(".reportTableRow"));
		var transactionIds = [];

		if (window.transactionId) {
			transactionIds.push(window.transactionId);
		} else {
			transactionIds = reportRows.reduce(function (ids, row) {
				var $row = $(row);
				var checkbox = $row.find(".chkLayerControl")[0];

				var t_id = $row.attr("transaction_id");

				if (t_id && ids.indexOf(t_id) === -1) {
					ids.push(t_id);
				}

				return ids;
			}, []);
		}

		if (transactionIds.length === 0) {
			dialog.openDialog(CONSTANTS.DIALOG.NO_REPORT_DATA_UNABLE_TO_DOWNLOAD_PDF);
		} else {
			// Export the maps
			var mainMapStaticParams = window.mapRiskController.toStaticMapUrl();
			var poiMapStaticParams = window.resultMapController.toStaticMapUrl();
			var policyInfoParam = window.policyInfo
				? "policyInfo=" + window.policyInfo + "&"
				: "";

			/**
			 * Reverses the array of currently enabled report overlays
			 * TODO: REWRITE THIS TO UTILIZE THE LAYER MODELS
			 */

			let enabledOverlays = window.mapRiskController.enabledOverlays
				.slice()
				.reverse();
			let urlEnabledVisualLayers = [];

			enabledOverlays.forEach(function (el) {
				let overlay = {
					reportId: el.reportId,
					namespace: el.overlayConfiguration.namespace,
					name: el.overlayConfiguration.name,
					viewParamString: el.overlayConfiguration.filterFields
						? utils.buildDerivedLayerParams(
								el.reportId,
								el.overlayConfiguration
						  ).wms
						: null
				};

				urlEnabledVisualLayers.push(overlay);
			}, "");

			var urlTransactionIds =
				transactionIds.reduce(function (params, id, i) {
					if (i !== 0) params += "&";

					return (params += "transactionId=" + id);
				}, "") || "";

			var token = utils.getAuthTokenFromQueryParam();

			var uri =
				"/pdf/generate/Maprisk?" +
				policyInfoParam +
				urlTransactionIds +
				"&address=" +
				encodeURIComponent(
					window?.currentPoiInfo?.poi?.addressLine ??
						`${window.currentPoiInfo.poi.latitude},${window.currentPoiInfo.poi.longitude}`
				) +
				"&mainMapStaticParams=" +
				mainMapStaticParams +
				"&poiMapStaticParams=" +
				poiMapStaticParams;

			if (urlEnabledVisualLayers.length > 0) {
				uri +=
					"&visualLayers=" +
					encodeURIComponent(JSON.stringify(urlEnabledVisualLayers));
			}

			if (token) {
				uri += "&token=" + token;
			}

			let label = {
				_id: window.id,
				request_type: utils.getAuthenticationType()
			};

			eventTracker("user_action", "download_pdf", label);

			window.open(uri);
		}
	});

	$(document).on("customEvent.batch.updateCredits", function (
		error,
		creditsRemaining
	) {
		$("#spanCreditsRemaining").html(" " + creditsRemaining);
		onCreditsUpdated(creditsRemaining);
	});

	$(document).on("click", ".scroll-to-link", function (e) {
		e.preventDefault();
		var target = $(this).attr("data-target");

		if (typeof target !== "undefined") {
			var top = $(target).offset().top;

			$(".content-wrapper").animate(
				{
					scrollTop: top
				},
				125
			);
		}
	});

	if ($("#input-username").length > 0) {
		$("#input-username").focus();
	}

	$(document).on("click", ".mobile-nav-trigger-link", function (e) {
		e.preventDefault();

		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			if ($(".mobile-nav").hasClass("active")) {
				$(".mobile-nav").removeClass("active");
			}
		} else {
			$(this).addClass("active");
			$(".mobile-nav").addClass("active");
		}
	});

	$(document).on("keyup", 'input[data-type="currency"]', function (e) {
		formatAsCurrency(this, true, "$");
	});

	$(document).on("click", ".info-window-portfolio-address-link", function (e) {
		e.preventDefault();
		$("#txtInputReportAddress").val($(this).attr("data-address"));
		window.runSearch($(this).attr("data-address"));
	});

	$(document).on("click", ".account-button", function (e) {
		e.preventDefault();
	});

	accordions.init();
});

document.addEventListener(
	"main_map_loaded",
	function () {
		autoCheckify();
		attachPaginationToInfoWindows();

		window.mapUNC.init({
			id: "user-notification-center-main-map",
			classNames: "user-notification-center-main-map",
			parentContainer: ".bodyContentMainWrapper"
		});

		window.mainDataView.init({
			id: "mr-data-view-main",
			containerClassNames: "mr-data-view-main",
			parentContainer: ".bodyContentMainWrapper",
			position: "before"
		});

		mainReportView.init({
			id: "mr-report-view-main",
			containerClassNames: "mr-report-view-main",
			parentContainer: ".bodyContentMainWrapper",
			position: "before"
		});

		window.mapClickDetection = new MapClickDetection(
			window.mrState.mrFeatureClick,
			"GoogleMapDiv"
		);

		$("#account-control").on("click", function (e) {
			e.preventDefault();

			if ($("#account-settings-dialog").hasClass("active")) {
				dialog.dismissAccountSettingsDialog();
			} else {
				if (window.mrState.viewOnly.enabled === true) {
					dialog.openDialog(CONSTANTS.DIALOG.ACCOUNT_SETTINGS_VIEW_ONLY);
				} else {
					dialog.openAccountSettingsDialog(null);
				}
			}
		});

		window.APIKeyManager = new APIKeyManager(
			new AccountClient(),
			window.eventBus
		);

		window.userManager = new UserManager(
			new AccountClient(),
			window.eventBus,
			window?.contact?.company ?? ""
		);
	},
	false
);

function MrDataLayer(mapController, reportId, layerName, geoJson) {
	var self = this;
	self.mapController = mapController;
	self.reportId = reportId;
	self.layerName = layerName;
	self.active = false;
	self.geoJson = geoJson;
	self.dataLayer = new google.maps.Data({ map: self.mapController.map });
	self.opacity = 0.75;

	self.activate = function () {
		self.active = true;

		self.dataLayer.addGeoJson(
			window.mrState.dataLayers[self.reportId][self.layerName].geoJson
		);

		self.style();
	};

	self.deactivate = function () {
		self.active = false;

		self.dataLayer.forEach(function (feature) {
			self.dataLayer.remove(feature);
		});
	};

	self.style = function (styles) {
		self.dataLayer.setStyle(function (feature) {
			var fillColor =
				styles && styles.fillColor
					? styles.fillColor
					: feature.getProperty("fill");
			var fillOpacity = self.opacity;
			var strokeColor =
				styles && styles.strokeColor
					? styles.strokeColor
					: feature.getProperty("stroke") || "black";
			var strokeOpacity = self.opacity;
			var strokeWeight =
				styles && styles.strokeWeight
					? styles.strokeWeight
					: feature.getProperty("stroke-width");

			return {
				fillColor: fillColor,
				fillOpacity: fillOpacity,
				strokeColor: strokeColor,
				strokeOpacity: strokeOpacity,
				strokeWeight: strokeWeight
			};
		});
	};

	self.setOpacity = function (opacity) {
		self.opacity = opacity;
		self.style();
	};

	self.getOpacity = function () {
		return self.opacity;
	};
}

$(document).ready(function () {
	//jQuery selector that holds the different login forms
	var $loginForm = $("#form-login, #form-login-mobile, #login-form");
	$loginForm.on("submit", function (e) {
		// Grab all the fields and their values using serializeArray()
		// and assign it to formData to pass in to the account client
		var formData = $(this)
			.serializeArray()
			.reduce((prev, field) => {
				field.name === "id"
					? (prev[field.name] = field.value.trim())
					: (prev[field.name] = field.value);
				return prev;
			}, {});

		var accountClient = new AccountClient();
		accountClient.login(formData);

		// Prevent page refresh
		return false;
	});

	if ($("#password-reset-form").length > 0) {
		$("#password-reset-form").on("submit", function (e) {
			e.preventDefault();

			if (
				$('[name="newPassword"]').val() !==
				$('[name="confirmNewPassword"]').val()
			) {
				dialog.openDialog(CONSTANTS.DIALOG.PASSWORD_FIELDS_MUST_MATCH);

				return false;
			}

			// serializeArray() grabs all the fields and their values
			var payload = $(this)
				.serializeArray()
				.reduce(function (p, field) {
					if (field.name !== "confirmNewPassword") p[field.name] = field.value;

					return p;
				}, {});

			payload.resetToken = $("#password-reset-form").attr("data-reset-token");

			var accountClient = new AccountClient();

			accountClient.submitNewPassword(payload);

			return false;
		});
	}

	if ($("#request-password-reset-form").length > 0) {
		$("#request-password-reset-form").on("submit", function (e) {
			e.preventDefault();

			var formData = $(this)
				.serializeArray()
				.reduce(function (prev, field) {
					prev[field.name] = field.value;

					return prev;
				}, {});

			var accountClient = new AccountClient();

			accountClient.requestPasswordReset(formData);

			return false;
		});
	}
});

$(function () {
	if ($("#batch-wizard").length <= 0) {
		return;
	}

	var batchWizardState = {
		currentStep: 0,
		currentBatch: null,
		mapriskColumns: [
			"Address Line",
			"Street",
			"City",
			"State",
			"Zip",
			"Country",
			"Latitude",
			"Longitude"
		],
		mapriskPortfolioColumns: ["TIV", "Policy ID", "Sub Aggregate"],
		columnMap: {},
		selectedReports: {},
		portfolioName: null
	};

	var steps = Array.prototype.slice.call($("#stepper .step"));

	steps[batchWizardState.currentStep].style.display = "block";

	$("#batch-wizard").on("click", ".next-button", nextStep);
	$("#batch-wizard").on("click", ".back-button", prevStep);

	$("#batch-wizard").on("click", ".new-portfolio-button", newPortfolio);
	$("#batch-wizard").on("click", ".new-batch-button", resetPortfolio);

	$("#batch-wizard").on("click", ".new-portfolio-button-submit", function () {
		batchWizardState.portfolioName = $(".new-portfolio-input-name").val();
		nextStep();
	});

	$("#batch-wizard").on("keyup", ".new-portfolio-input-name", function () {
		if ($(this).val().length > 0 && $(this).val().length <= 255) {
			$(".new-portfolio-button-submit").removeClass("disabled");
		} else {
			$(".new-portfolio-button-submit").addClass("disabled");
		}
	});

	function newPortfolio() {
		$(".new-portfolio-input-name").addClass("active");
		$(".new-portfolio-input-name").focus();
		$(".new-portfolio-input-name").val("");
		$(".new-portfolio-button-submit").addClass("active");
		$(this).removeClass("active");
	}

	function resetPortfolio() {
		$(".new-portfolio-input-name").removeClass("active");
		$(".new-portfolio-input-name").val("");
		$(".new-portfolio-button-submit")
			.removeClass("active")
			.addClass("disabled");
		$(".new-portfolio-button").addClass("active");
		batchWizardState.portfolioName = null;
	}

	function reset() {
		batchWizardState.columnMap = {};
		batchWizardState.selectedReports = [];
		batchWizardState.totalCost = 0;

		$(".report-list .chkExecuteReport").each(function (i, checkbox) {
			checkbox.checked = false;

			var batchAccordion = new window.accordionController();

			batchAccordion.close(this);
		});
	}

	function gotoStep(stepNum) {
		steps.forEach(function (stepElement) {
			stepElement.style.display = "none";
		});

		batchWizardState.currentStep = stepNum;

		steps[stepNum].style.display = "block";
	}

	function nextStep() {
		if ($(this).hasClass("disabled")) return;

		if (batchWizardState.currentStep < steps.length - 1) {
			batchWizardState.currentStep++;
			gotoStep(batchWizardState.currentStep);
		}
	}

	function prevStep() {
		if ($(this).hasClass("disabled")) return;

		if (batchWizardState.currentStep > 0) {
			batchWizardState.currentStep--;
			gotoStep(batchWizardState.currentStep);
		}
	}

	function createReportParams(reportId) {
		if (
			!batchWizardState.reportParams ||
			batchWizardState.reportParams === null
		) {
			batchWizardState.reportParams = {};
		}

		if (typeof reportId !== "undefined") {
			if (
				!batchWizardState.reportParams[reportId] ||
				batchWizardState.reportParams[reportId] === null
			) {
				batchWizardState.reportParams[reportId] = {};
			}
		}
	}

	function removeReportParams(reportId) {
		delete batchWizardState.reportParams[reportId];
	}

	function createReportData(reportId) {
		if (!batchWizardState.reportData || batchWizardState.reportData === null) {
			batchWizardState.reportData = {};
		}

		if (typeof reportId !== "undefined") {
			if (
				!batchWizardState.reportData[reportId] ||
				batchWizardState.reportData[reportId] === null
			) {
				batchWizardState.reportData[reportId] = {};
			}
		}
	}

	function removeReportData(reportId) {
		delete batchWizardState.reportData[reportId];
	}

	function onSelectionChange(currentSelectElement, selectIndex) {
		var originalSelectionVal = $(currentSelectElement)
			.find("option:selected")
			.val();
		var userColumn = batchWizardState.currentBatch.columns[selectIndex];
		var reportId = $(currentSelectElement)
			.parents(".divAdditionalInfo")
			.attr("data-report-id");

		if (originalSelectionVal === "") {
			delete batchWizardState.columnMap[userColumn];
		} else {
			batchWizardState.columnMap[userColumn] = originalSelectionVal;
		}

		// Start from clean slate
		Object.keys(batchWizardState.mapriskColumns).forEach(function (key) {
			var value = batchWizardState.mapriskColumns[key];

			$('.column-mapper [value="' + value + '"]').each(function (i, element) {
				element.removeAttribute("disabled");
			});
		});

		Object.keys(batchWizardState.columnMap).forEach(function (key) {
			var value = batchWizardState.columnMap[key];

			if (value === "") {
				return;
			}

			$('.column-mapper [value="' + value + '"]').each(function (i, element) {
				element.setAttribute("disabled", "");
			});
		});
	}

	function onReportParamsSelectionChange(currentSelectElement, selectIndex) {
		var originalSelectionVal = $(currentSelectElement)
			.find("option:selected")
			.val();
		var userColumn = batchWizardState.currentBatch.columns[selectIndex];
		var reportId = $(currentSelectElement)
			.parents(".divAdditionalInfo")
			.attr("data-report-id");

		if (typeof reportId !== "undefined") {
			createReportParams(reportId);

			if (originalSelectionVal === "") {
				delete batchWizardState.reportParams[reportId][userColumn];
			} else {
				batchWizardState.reportParams[reportId][
					userColumn
				] = originalSelectionVal;
			}
		}

		// Start from clean slate
		Object.keys(batchWizardState.mapriskColumns).forEach(function (key) {
			var value = batchWizardState.mapriskColumns[key];

			$('.column-mapper [value="' + value + '"]').each(function (i, element) {
				element.removeAttribute("disabled");
			});
		});

		Object.keys(batchWizardState.columnMap).forEach(function (key) {
			var value = batchWizardState.columnMap[key];

			if (value === "") return;

			$('.column-mapper [value="' + value + '"]').each(function (i, element) {
				element.setAttribute("disabled", "");
			});
		});
	}

	function addToMapriskColumns(columnArray) {
		for (let i = 0; i < columnArray.length; i++) {
			let columnValue = columnArray[i];
			let indexOfColumn = batchWizardState.mapriskColumns.indexOf(columnValue);

			//If our standard maprisk columns array doesnt have it currently
			//add it the mapriskColumns array
			if (indexOfColumn === -1) {
				batchWizardState.mapriskColumns.push(columnValue);
			}
		}
	}
	function removeFromMapriskColumns(columnArray) {
		for (let i = 0; i < columnArray.length; i++) {
			let columnValue = columnArray[i];
			let indexOfColumn = batchWizardState.mapriskColumns.indexOf(columnValue);

			//If our standard maprisk does have the column entry already
			//remove it from the mapriskColumns array as it is not needed
			if (indexOfColumn !== -1) {
				batchWizardState.mapriskColumns.splice(indexOfColumn, 1);
			}
		}
	}
	function setColumnMapperOptions() {
		reset();
		//If portfolio ingestion is enabled and the user has started a new portfolio
		//batch request, we will add the portfolio columns to the dropdown selector
		if (
			window.accountConfigurationModel?.portfolioIngestion?.enabled &&
			batchWizardState?.portfolioName
		) {
			addToMapriskColumns(batchWizardState.mapriskPortfolioColumns);
		}
		//If portfolio ingestion is enabled but the user is not performing a portfolio
		//batch request, we will remove the portfolio columns from the dropdown selector
		else if (
			window.accountConfigurationModel?.portfolioIngestion?.enabled &&
			!batchWizardState?.portfolioName
		) {
			removeFromMapriskColumns(batchWizardState.mapriskPortfolioColumns);
		}

		var selects = $(".column-mapper select");

		Array.prototype.slice.call(selects).forEach(function (selectElement, i) {
			var columnMapSelect = $(selectElement);

			columnMapSelect.empty();
			columnMapSelect.append('<option value="">(No selection)</option>');

			batchWizardState.mapriskColumns.forEach(function (poiField) {
				columnMapSelect.append(
					'<option value="' + poiField + '">' + poiField + "</option>"
				);
			});
		});

		//  attach an event handler to each selection box to keep disabled options up to date
		var currents = $("#batch-upload .column-map-container select").each(
			function (i, val) {
				var sel = $(val);

				sel.data("previousSelection", sel.val());
				sel.on("change", function (e) {
					onSelectionChange(val, i);
				});
			}
		);

		// Automap certain column title names
		var automapValues = {
			addressLine: ["addressline", "address line", "full address"],
			street: ["street"],
			city: ["city"],
			state: ["state"],
			zip: [
				"zip",
				"zipcode",
				"zip code",
				"postal",
				"postalcode",
				"postal code",
				"postcode",
				"post code"
			],
			country: ["country"]
		};

		if (
			window.accountConfigurationModel?.portfolioIngestion?.enabled &&
			batchWizardState?.portfolioName
		) {
			automapValues.tiv = [
				"tiv",
				"total insured value",
				"totalinsuredvalue",
				"total_insured_value"
			];

			automapValues.policyId = ["policyid", "policy id", "policy_id"];

			automapValues.subAggregate = [
				"subaggregate",
				"sub_aggregate",
				"division"
			];
		}

		var options = [];
		var values = [];
		var automapKeys = [];

		for (var key in automapValues) {
			automapKeys.push(key);
		}

		//  loop through each .column-map-container to attempt to smartly pre-select a mapping
		Array.prototype.slice
			.call($(".column-map-container"))
			.forEach(function (mapContainer, i) {
				//  select the title of spreadsheet's column
				var userColumnTitle = $(mapContainer)
					.find(".column-title")[0]
					.innerHTML.toLowerCase();
				var userColumnText = $(userColumnTitle)[0].innerHTML;

				$(".column-map-container").eq(i).attr("data-title", userColumnText);

				//  loop through automapKeys and attempt word-match
				for (var i = 0; i < automapKeys.length; ++i) {
					var key = automapKeys[i];

					//  does column title contain substring of key?
					var userColumnTitleTermIndex;
					for (var termIndex in automapValues[key]) {
						userColumnTitleTermIndex = userColumnTitleTermIndex = userColumnTitle.indexOf(
							automapValues[key][termIndex]
						);

						if (userColumnTitleTermIndex >= 0) {
							break;
						}
					}
					if (userColumnTitleTermIndex >= 0) {
						//  grab options as array of option
						if (options.length === 0) {
							$(mapContainer)
								.find(".column-dropdown-container .column-dropdown option")
								.each(function () {
									options.push($(this));
								});
						}
						//  stuff lower-case'd values from options into values array
						values = options.map(function (option) {
							return option.val().toLowerCase();
						});
						//  grab the position of the option identified as a fuzzy match
						var automappedOptionIndex;

						for (var termIndex in automapValues[key]) {
							automappedOptionIndex = values.indexOf(
								automapValues[key][termIndex]
							);

							if (automappedOptionIndex >= 0) {
								break;
							}
						}

						//  if no eligible match available, move on
						if (
							automappedOptionIndex === -1 ||
							options[automappedOptionIndex].is(":disabled")
						)
							continue;

						//  programmatically select matched option
						$(mapContainer)
							.find(".column-dropdown-container .column-dropdown")
							.val(options[automappedOptionIndex].val())
							.trigger("change");
						//  disable matched option for all other .column-map-container
						values.splice(automappedOptionIndex, 1);

						break;
					}
				}
			});
	}

	/**
	 * Copy of setColumnMapperOptions, but with the ability to pass a custom context, reportId, and data fields for additionalInfo columns
	 * @param {str} context
	 * 		A css selector format string denoting DOM context of the column map container wrapper
	 * @param {str} reportId
	 * 		The ID of the report being mapped
	 * @param {arr} data
	 * 		An array of column names to be passed,
	 */

	function setReportParamsColumnMapperOptions(context, reportId, data) {
		var selects = $("#div" + reportId + "_batch" + " select");

		Array.prototype.slice.call(selects).forEach(function (selectElement, i) {
			var columnMapSelect = $(selectElement);

			columnMapSelect.empty();
			columnMapSelect.append('<option value="">(No selection)</option>');

			data.forEach(function (poiField) {
				columnMapSelect.append(
					'<option value="' + poiField + '">' + poiField + "</option>"
				);
			});
		});

		//  attach an event handler to each selection box to keep disabled options up to date
		var currents = $(
			"#batch-reports #div" +
				reportId +
				"_batch" +
				" .column-map-container select"
		).each(function (i, val) {
			var sel = $(val);

			sel.data("previousSelection", sel.val());
			sel.on("change", function (e) {
				onReportParamsSelectionChange(val, i);
			});
		});

		// Automap certain column title names
		var automapValues = {
			addressLine: ["addressline", "address line", "full address"],
			street: ["street"],
			city: ["city"],
			state: ["state"],
			zip: [
				"zip",
				"zipcode",
				"zip code",
				"postal",
				"postalcode",
				"postal code",
				"postcode",
				"post code"
			],
			country: ["country"]
		};

		if (
			window.accountConfigurationModel?.portfolioIngestion?.enabled &&
			batchWizardState?.portfolioName
		) {
			automapValues.tiv = [
				"tiv",
				"total insured value",
				"totalinsuredvalue",
				"total_insured_value"
			];

			automapValues.policyId = ["policyid", "policy id", "policy_id"];

			automapValues.subAggregate = [
				"subaggregate",
				"sub_aggregate",
				"division"
			];
		}

		var options = [];
		var values = [];
		var automapKeys = [];

		for (var key in automapValues) {
			automapKeys.push(key);
		}

		//  loop through each .column-map-container to attempt to smartly pre-select a mapping
		Array.prototype.slice
			.call($("#div" + reportId + "_batch" + " .column-map-container"))
			.forEach(function (mapContainer, i) {
				//  select the title of spreadsheet's column
				var userColumnTitle = $(mapContainer)
					.find(".column-title")[0]
					.innerHTML.toLowerCase();
				var userColumnText = $(userColumnTitle)[0].innerHTML;

				$("#div" + reportId + "_batch" + " .column-map-container")
					.eq(i)
					.attr("data-title", userColumnText);

				//  loop through automapKeys and attempt word-match
				for (var i = 0; i < automapKeys.length; ++i) {
					var key = automapKeys[i];

					//  does column title contain substring of key?
					if (userColumnText.indexOf(key) !== -1) {
						//  grab options as array of option
						$(mapContainer)
							.find(".column-dropdown-container .column-dropdown option")
							.each(function () {
								options.push($(this));
							});
						//  stuff lower-case'd values from options into values array
						values = options.map(function (option) {
							return option.val().toLowerCase();
						});
						//  grab the position of the option identified as a fuzzy match
						var automappedOptionIndex = values.indexOf(automapValues[key]);

						//  if no eligible match available, move on
						if (
							automappedOptionIndex === -1 ||
							options[automappedOptionIndex].is(":disabled")
						) {
							continue;
						}

						//  programmatically select matched option
						$(mapContainer)
							.find(".column-dropdown-container .column-dropdown")
							.val(options[automappedOptionIndex].val())
							.trigger("change");
						//  disable matched option for all other .column-map-container
						values.splice(automappedOptionIndex, 1);

						break;
					}
				}
			});
	}

	function toggleReportSelection(data) {
		if (data.checked) {
			batchWizardState.selectedReports[data.report] = data.cost;

			if (data.report) {
				createReportParams(data.report);
				createReportData(data.report);
			}
		} else {
			delete batchWizardState.selectedReports[data.report];
			removeReportParams(data.report);
			removeReportData(data.report);
		}

		var totalCost = 0;

		for (var report in batchWizardState.selectedReports) {
			var cost = batchWizardState.selectedReports[report];

			if (
				batchWizardState.currentBatch &&
				batchWizardState.currentBatch.numRows
			) {
				totalCost += batchWizardState.currentBatch.numRows * cost;
			}
		}
	}

	$(document).on("customEvent.batch.toggleReportSelection", function (e, data) {
		toggleReportSelection(data);
	});

	$(document).on("customEvent.batch.gotoStep", function (e, data) {
		gotoStep(data);
	});

	$(document).on("customEvent.batch.setCurrentBatch", function (e, batch) {
		batchWizardState.currentBatch = batch;
		$(".totals-container .total-records .value")[0].innerHTML = batch.numRows;
	});

	$(document).on("customEvent.batch.newBatchClicked", function () {
		reset();
		batchWizardState.currentBatch = null;
	});
	function setMapperFields(columns) {
		var columnMapper = $(".column-mapper").empty();
		var additionalInfo = ".additionalInfo_batch";

		var buildColumnMapper = function (columnMapper, columns) {
			columns.forEach(function (column) {
				var columnMapContainer = $('<div class="column-map-container"></div>');
				var columnTitle = $(
					'<div class="column-title"><p>' + column + "</p></div>"
				);
				var columnDropdownContainer = $(
					'<div class="column-dropdown-container"></div>'
				);
				var columnDropdown = $(
					'<select class="column-dropdown">' + column + "</select>"
				);

				columnDropdownContainer.append(columnDropdown);

				columnMapContainer.append(columnTitle);
				columnMapContainer.append(columnDropdownContainer);

				columnMapper.append(columnMapContainer);
			});
		};

		buildColumnMapper(columnMapper, columns);

		$(document).trigger("customEvent.batch.setColumnMapperOptions");

		$(additionalInfo).each(function (
			additionalBatchInfoIndex,
			additionalBatchInfo
		) {
			var batchReportId = $(this)
				.attr("id")
				.substr(0, $(this).attr("id").indexOf("_batch"));
			var reportTableCol = "#" + batchReportId + "_index";
			var isMultiSelectReport = false;
			var batchInfo = $(reportTableCol).find(".batchInput");
			var batchInfoCols = [];
			var reportId = $(this)
				.parents(".divAdditionalInfo." + batchReportId)
				.attr("data-report-id");
			var shouldAddBatchInfoLabelToCols = null;
			var $multiSelect;

			if (reportId.toLowerCase() === "replacementcost") {
				let isResidentialPro = false;
				let isResidentialProWithAdjustment = false;
				let isCommercialPro = false;

				let rcConfig = window.reportConfigurationModel[reportId.toLowerCase()];
				let residential =
					rcConfig && rcConfig.parameters
						? utils.getReportParameterByName(
								window.reportConfigurationModel,
								reportId,
								"Residential"
						  )
						: null;
				let commercial =
					rcConfig && rcConfig.parameters
						? utils.getReportParameterByName(
								window.reportConfigurationModel,
								reportId,
								"Commercial"
						  )
						: null;

				isResidentialPro =
					residential?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase().indexOf(
						"residentialpro"
					) > -1
						? true
						: false;
				isResidentialProWithAdjustment =
					residential?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
						"residentialproadjustment" || false;
				isCommercialPro =
					commercial?.ExternalAPIs?.ReplacementCost?.E2Value?.toLowerCase() ===
						"commercialpro" || false;

				shouldAddBatchInfoLabelToCols = function (label) {
					let $parent = $(label).parents(".product-wrapper");
					let occupancyType;

					if ($parent.length > 0) {
						occupancyType = $parent.data("occupancy-type");
					} else {
						occupancyType = $("#" + $(label).attr("for"))
							.val()
							.toLowerCase();
					}

					let parentIsPro =
						$parent.data("tier") === "pro" ||
						$parent.data("tier") === "pro-with-adjustment"
							? true
							: false;
					let parentIsLite = $parent.data("tier") === "lite" ? true : false;

					let input = $("#" + $(label).attr("for"));

					let commonFieldPresent = false;
					let residentialInputPresent = false;
					let commercialInputPresent = false;

					input.each(function (index, field) {
						if (
							field?.id?.toLowerCase()?.indexOf("occupancytype") > -1 ||
							field?.id?.toLowerCase()?.indexOf("sqfootage") > -1 ||
							field?.id?.toLowerCase()?.indexOf("yearbuilt") > -1
						) {
							commonFieldPresent = true;
						}
					});

					if (occupancyType === "residential") {
						residentialInputPresent = true;
					}

					if (occupancyType === "commercial") {
						commercialInputPresent = true;
					}

					if (commonFieldPresent) {
						return true;
					}

					if (
						residentialInputPresent &&
						((parentIsPro && isResidentialPro) ||
							(parentIsLite && !isResidentialPro))
					) {
						return true;
					}

					if (
						commercialInputPresent &&
						((parentIsPro && isCommercialPro) ||
							(parentIsLite && !isCommercialPro))
					) {
						return true;
					}

					return false;
				};
			}

			batchInfo.each(function (batchInfoLabelIndex, batchInfoLabel) {
				if (
					!shouldAddBatchInfoLabelToCols ||
					shouldAddBatchInfoLabelToCols(batchInfoLabel)
				) {
					batchInfoCols.push($(batchInfoLabel).attr("label"));
				}
			});

			// deduplicate values (added for replacement cost)
			batchInfoCols = Array.from(new Set(batchInfoCols));

			if (
				window.mrState &&
				window.mrState.mrMultiselect &&
				window.mrState.mrMultiselect[reportId + "_batch"]
			) {
				isMultiSelectReport = true;
				window.mrState.mrMultiselect[reportId + "_batch"].clear();
				$multiSelect = window.mrState.mrMultiselect[
					reportId + "_batch"
				].$multiSelectContainer.clone(false, false);
				delete window.mrState.mrMultiselect[reportId + "_batch"];
			}

			$(additionalBatchInfo).empty();

			buildColumnMapper($(additionalBatchInfo), columns);

			$(additionalBatchInfo).prepend($multiSelect);

			if (isMultiSelectReport) {
				window.mrState.mrMultiselect[reportId + "_batch"] = new Multiselect(
					reportId,
					"batch",
					`#div{$reportId}_batch}`
				);

				var modelInputDefaults = utils.getReportParameterByName(
					window.reportConfigurationModel,
					reportId,
					"filterDefaults"
				);

				if (modelInputDefaults) {
					for (var column in modelInputDefaults) {
						modelInputDefaults[column].forEach(function (val) {
							window.mrState.mrMultiselect[
								reportId + "_batch"
							].triggerLabelClick(column, val);
						});
					}
				}
			}

			$(
				document
			).trigger("customEvent.batch.setReportParamsColumnMapperOptions", [
				additionalInfo,
				reportId,
				batchInfoCols
			]);

			var mappedColumns = [];

			$("#batch-upload .column-mapper .column-map-container").each(function () {
				var title = $(this).attr("data-title");
				var value = $(this).find(".column-dropdown option:selected").val();

				if (typeof value !== "undefined" && value) {
					mappedColumns.push(title);
				}
			});

			mappedColumns.forEach(function (el) {
				$("#div" + reportId + "_batch")
					.find('.column-map-container[data-title="' + el + '"]')
					.remove();
			});
		});
	}

	$(document).on("customEvent.batch.newBatchClicked", function (e) {
		$("#batch-upload #upload-batch-form input")[0].value = "";
		$("#batch-upload .file-upload-header .file-name p")[0].innerText = "";
		$("#batch-upload .content .column-mapper").empty();
	});

	$(document).on("customEvent.batch.configureUploadedBatch", function (
		e,
		batch
	) {
		$("#batch-upload #upload-batch-form input")[0].value = "";
		$("#batch-upload .file-upload-header .file-name p")[0].innerText = "";
		$("#batch-upload .content .column-mapper").empty();
		$(document).trigger("customEvent.batch.newBatchClicked");

		if (batch.socket) {
			delete batch.socket;
		}

		$(document).trigger("customEvent.batch.setCurrentBatch", batch);
		setMapperFields(batch.columns);
	});

	$(document).on("customEvent.batch.newPortfolioClicked", function (e) {
		$("#batch-upload #upload-batch-form input")[0].value = "";
		$("#batch-upload .file-upload-header .file-name p")[0].innerText = "";
		$("#batch-upload .content .column-mapper").empty();
	});

	const BatchUpload = require("./components/batch-upload/batch-upload");
	var batchUpload = new BatchUpload();

	$("#upload-batch-form").on("submit", function () {
		if ($("#upload-batch-button input")[0].files.length > 0) {
			var batchClient = new BatchClient(window.batchUrlBase, window.batchPath);

			var beforeSubmit = function () {
				batchUpload.prepareUploadBtn();
			};

			var success = function (response) {
				batchUpload.resetUploadBtnOnSuccess();
				batchUpload.enableButtons();

				var file = $("#upload-batch-button input")[0].files[0];

				$("#batch-upload .file-name p")[0].innerText = file.name;

				var batch = response.data.batch;

				$(document).trigger("customEvent.batch.setCurrentBatch", batch);
				setMapperFields(batch.columns);
			};

			var error = function (error) {
				batchUpload.resetUploadBtnOnError();
				batchUpload.enableButtons();

				var errorResponse = JSON.parse(error.responseText);
				var errorMsg = errorResponse.error.message;

				if (typeof errorResponse.error.code !== "undefined") {
					switch (errorResponse.error.code) {
						case "FILE_TOO_LARGE":
							errorMsg =
								"Error: Please upload a file of " +
								errorResponse.error.data.rowLimit +
								" rows or less.";
							break;
						case "SPREADSHEET_CONTAINS_BLANK_COLUMNS":
							errorMsg =
								"Error: Please ensure spreadsheet doesn't contain any blank column headers.";
							break;
					}
				}
				// display error message in filename field
				$("#batch-upload .file-name p")[0].innerHTML =
					'<span class="error">' + errorMsg + "</span>";
				// empty column map field if user has successfully uploaded the correct file type, then attempted another upload that fails
				$(".column-mapper").empty();
			};

			batchClient.uploadBatchFile(
				this,
				batchWizardState,
				error,
				success,
				beforeSubmit
			);
		}

		return false;
	});

	$("#upload-batch-button input").on("change", function () {
		$("#upload-batch-form").trigger("submit");
	});

	$(document).on("customEvent.batch.newPortfolioClicked", function () {
		reset();
		batchWizardState.currentBatch = null;
	});

	$(document).on("customEvent.batch.setColumnMapperOptions", function (
		e,
		data
	) {
		setColumnMapperOptions();
	});

	$(document).on(
		"customEvent.batch.setReportParamsColumnMapperOptions",
		function (e, context, reportId, data) {
			setReportParamsColumnMapperOptions(context, reportId, data);
		}
	);

	$(document).on("customEvent.batch.onRunReportsSubmit", function (
		checkedReports
	) {
		if (!batchWizardState.portfolioName && checkedReports.length <= 0) return;

		var creditsRemaining = $("#spanCreditsRemaining").html()
			? Number($("#spanCreditsRemaining").html().trim())
			: null;

		if (creditsRemaining && batchWizardState.total > creditsRemaining) {
			dialog.openDialog(CONSTANTS.DIALOG.INSUFFICIENT_FUNDS_BATCH);
		}

		gotoStep(0);

		var reverseColumnMap = Object.keys(batchWizardState.columnMap).reduce(
			function (collector, key) {
				var camelKey = batchWizardState.columnMap[key];

				camelKey = camelKey.split(" ");
				if (camelKey.length > 1) {
					camelKey = camelKey.map(function (val, index) {
						if (index > 0) {
							return val.charAt(0).toUpperCase() + val.slice(1);
						} else {
							return val.toLowerCase();
						}
					});
				}
				camelKey = camelKey.join("");

				collector[camelKey] = key;

				return collector;
			},
			{}
		);

		batchWizardState.currentBatch.columnMap = reverseColumnMap;

		if (batchWizardState.reportData) {
			for (var reportId in batchWizardState.reportData) {
				if (
					!batchWizardState.currentBatch.reportData ||
					batchWizardState.currentBatch.reportData === null
				) {
					batchWizardState.currentBatch.reportData = {};
				}

				if (
					typeof reportId !== "undefined" &&
					batchWizardState.currentBatch.reportData
				) {
					if (
						!batchWizardState.currentBatch.reportData[reportId] ||
						batchWizardState.currentBatch.reportData[reportId] === null
					) {
						batchWizardState.currentBatch.reportData[reportId] = {};
					}
				}

				batchWizardState.currentBatch.reportData[
					reportId
				].filterByFields = window.getReportFilterByFields(reportId, "batch");
			}
		}

		if (batchWizardState.reportParams) {
			for (var reportId in batchWizardState.reportParams) {
				var reverseReportParams = Object.keys(
					batchWizardState.reportParams[reportId]
				).reduce(function (collector, key) {
					var camelKey = batchWizardState.reportParams[reportId][key];

					camelKey = camelKey.split(" ");
					if (camelKey.length > 1) {
						camelKey = camelKey.map(function (val, index) {
							if (index > 0) {
								return val.charAt(0).toUpperCase() + val.slice(1);
							} else {
								return val.toLowerCase();
							}
						});
					}
					camelKey = camelKey.join("");

					collector[camelKey] = key;

					return collector;
				}, {});

				if (
					!batchWizardState.currentBatch.reportParams ||
					batchWizardState.currentBatch.reportParams === null
				) {
					batchWizardState.currentBatch.reportParams = {};
				}

				if (
					typeof reportId !== "undefined" &&
					batchWizardState.currentBatch.reportParams
				) {
					if (
						!batchWizardState.currentBatch.reportParams[reportId] ||
						batchWizardState.currentBatch.reportParams[reportId] === null
					) {
						batchWizardState.currentBatch.reportParams[reportId] = {};
					}
				}

				batchWizardState.currentBatch.reportParams[
					reportId
				] = reverseReportParams;
			}
		}

		batchWizardState.currentBatch.reports = batchWizardState.selectedReports
			? Object.keys(batchWizardState.selectedReports)
			: "";

		// This is being listened for in batch-history.js
		if (batchWizardState.portfolioName)
			batchWizardState.currentBatch.portfolioName =
				batchWizardState.portfolioName;
		$(document).trigger(
			"customEvent.batch.runReports",
			batchWizardState.currentBatch
		);
		resetPortfolio();
	});

	$(document).on("customEvent.batch.batchComplete", function () {
		reset();
		resetPortfolio();
		batchWizardState.currentBatch = null;
	});
});

window.batchUrlBase = "";
window.batchPath = "";

if (
	location.hostname.indexOf("localhost") > -1 ||
	location.hostname.indexOf("whitelabel") > -1 ||
	location.hostname.indexOf("192.168.1.") > -1 ||
	/maprisk[0-9]+.com/g.test(location.hostname)
) {
	window.batchUrlBase +=
		location.protocol + "//" + location.hostname + ":" + location.port;
	window.batchPath += "/batch";
} else {
	window.batchUrlBase += location.protocol + "//" + location.hostname;
	window.batchPath += "/batch";
}

var jwt = utils.getAuthTokenFromQueryParam() || utils.getCookie("token");

var batchHistoryModel = new BatchHistoryModel(
	jwt,
	window.batchUrlBase,
	window.batchPath
);

$(function () {
	$(document).on("customEvent.batch.getBatches", function (e) {
		var success = function (response) {
			var batches = response.data ? response.data.batches : [];

			batches.sort(function (a, b) {
				if (a.created < b.created) return -1;
				else return 1;
			});

			batches.forEach(function (batch) {
				batchHistoryModel.add(batch);
			});
		};

		var error = function (error) {
			console.log(error);
		};

		var batchClient = new BatchClient(window.batchUrlBase, window.batchPath);
		batchClient.getAll(error, success);
	});

	$(document).on("customEvent.batch.runReports", function (err, batch) {
		/*
		 *  when called from index.js:
		 *  batch = batchWizardState.currentBatch
		 */
		console.log(batch);

		var success = function (response) {
			console.log(response);

			if (response.data) batch = response.data.batch;

			batchHistoryModel.add(batch);
		};

		var error = function (error) {
			batch.status = "error";
			batchHistoryModel.updateStatus(batch);
			dialog.openGeneralDialog(
				null,
				new dialog.DialogOptions(
					error.statusText,
					error.responseJSON.error.message
				)
			);
		};

		var batchClient = new BatchClient(window.batchUrlBase, window.batchPath);
		batchClient.setupAndStartById(batch, error, success);
	});

	$(".new-batch-button-container .new-batch-button").on("click", function (e) {
		$(document).trigger("customEvent.batch.newBatchClicked");
	});

	$(".new-batch-button-container .new-portfolio-button").on("click", function (
		e
	) {
		$(document).trigger("customEvent.batch.newPortfolioClicked");
	});

	$(document).on("customEvent.batch.batchDialogOpened", function () {
		if (window.mrState.batchEnabled !== true) {
			return;
		}

		var batchClient = new BatchClient(window.batchUrlBase, window.batchPath);
		batchClient.openAccountSocket(
			function (error) {
				console.error(error);
			},
			function (response) {
				if (!batchHistoryModel.socketManager) {
					batchHistoryModel.socketManager = !window.batchPath
						? new io.Manager(window.batchUrlBase)
						: new io.Manager(window.batchUrlBase, {
								path: window.batchPath + "/socket.io"
						  });
				}

				batchHistoryModel.socket = batchHistoryModel.socketManager.socket(
					response.data.socket.path
				);

				batchHistoryModel.initializeAccountSocket();

				if (!batchHistoryModel.batchPoller) {
					var requestOutstanding = false;

					// poll api for new batches
					batchHistoryModel.batchPoller = setInterval(function () {
						if (!requestOutstanding) {
							requestOutstanding = true;
							batchHistoryModel.socket.emit("updateAll", function (batches) {
								for (var i = 0; i < batches.length; i++) {
									var batchHistoryEntry =
										batchHistoryModel.batches[batches[i].id];

									if (batchHistoryEntry) {
										if (batches[i].status !== batchHistoryEntry.status) {
											batchHistoryModel.batches[batches[i].id] = batches[i];
											batchHistoryModel.updateStatus(
												batchHistoryModel.batches[batches[i].id]
											);
										}
									} else {
										batchHistoryModel.add(batches[i]);
									}
								}

								requestOutstanding = false;
							});
						}
					}, 10000);
				}
			}
		);
	});

	$(document).on("customEvent.batch.batchDialogClosed", function () {
		clearInterval(batchHistoryModel.batchPoller);

		if (batchHistoryModel.socket) {
			batchHistoryModel.socket.disconnect();
			delete batchHistoryModel.socket;
		}

		if (batchHistoryModel.socketManager) {
			batchHistoryModel.socketManager.disconnect();
			delete batchHistoryModel.socketManager;
		}
	});
});

$(function () {
	$(".report-list .chkExecuteReport").on("click", function (e) {
		e.stopPropagation();
		$(document).trigger("customEvent.batch.toggleReportSelection", {
			checked: $(this).is(":checked"),
			report: $(this).val(),
			cost: Number($(this).attr("costperreport"))
		});

		// We have to manually call this here, as the .chkExecuteReport checkboxes are often used as accordion triggers,
		// and e.stopPropagation function keeps the accordion functionality from automatically firing -CA 20180827
		var reportAccordion = new window.accordionController();
		if (
			$(this).hasClass(
				reportAccordion.settings.triggerClosedState.split(".").join("")
			)
		) {
			reportAccordion.open(this);
		} else {
			reportAccordion.close(this);
		}
	});

	$("#run-reports").on("click", function (e) {
		var checkedReports = $(".report-list .chkExecuteReport:checked");

		$(document).trigger("customEvent.batch.onRunReportsSubmit", checkedReports);

		let label = {
			_id: window.id,
			number_of_reports: checkedReports.length,
			request_type: utils.getAuthenticationType()
		};

		eventTracker("user_action", "run_batch", label);
	});
});

window.generateCanopyLayersFromModelInput = function (reportId) {
	let date = $("#model-input-" + reportId + "-convective-date_index").val();
	let buffer = parseInt(
		$("#model-input-" + reportId + "-convective-date-buffer_index").val()
	);

	window.mrState.layerMultiselects[reportId].addLayers(
		date,
		buffer,
		window.reportConfigurationModel[reportId.toLowerCase()].layers
	);
};
